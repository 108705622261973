import { getAccessToken } from 'hrFrontend/state/contexts/GlobalContext'

export const fetchPromise = (
  url: string,
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  bodyPayload: {[key: string]: any},
  catchMessage: string,
  thenHandler?: (res: Response) => void
) => {
  const body = JSON.stringify(bodyPayload, (key: string, value: string) => {
    if (value === undefined || value === '') {
      return null
    }
    return value
  });
  return fetch(url, {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
  .then((res) => {
    if (!getAccessToken()) {
      // @ts-ignore
      return window.location = '/'
    }
    return thenHandler ? thenHandler(res) : res
  })
  .catch((err) => {
    console.error(catchMessage, err)
  })
}