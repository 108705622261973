import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import {LayoutAuthenticated} from "./LayoutAuthenticated";
// import {PageHeaderUnified} from "sharedFrontend/PageHeaderUnified";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {ROUTES} from "../state/routes";
import {
  Button,
  Box,
  Card,
  CardHeader,
  Link as MuiLink,
  Grid,
  Typography,
  CardContent,
  TextField,
} from "@mui/material";
import {Action} from '../state/actions';
import {v4} from 'uuid';
import {styled} from '@mui/material/styles';
import { getDefaultDepartment, getDefaultProfile, getDefaultTeam, IDepartment, IProfile, ITeam } from 'hrFrontend/state/state';
import { Close } from '@mui/icons-material';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { VacanciesTableSortable } from 'hrFrontend/components/VacanciesTable';
import { DepartmentModal, Department } from 'hrFrontend/components/Department';
import { Edit3 } from 'react-feather';
import { Team, TeamModal } from 'hrFrontend/components/Team';
import { Profile, ProfileModal } from 'hrFrontend/components/Profile';
import { CustomLink } from 'sharedFrontend/atoms/Link';
import { ProfilesTableSortable } from 'hrFrontend/components/ProfilesTable';
import { fetchPromise } from 'hrFrontend/utils/fetch';

const MainCardHeader = styled(CardHeader)`
  padding-bottom: 8px;

  .MuiCardHeader-title {
    font-size: 1.4em;
  }
`

interface ISectionCardProps extends PropsWithChildren<any> {
  // title: string;
  // callback: () => void;
  // Icon: (props: any) => JSX.Element;
  // type: 'small' | 'small-horizontal' | 'big' | 'big-horizontal';
  // active?: boolean;
  noPadding?: boolean;
  elevated?: boolean;
}

const SectionCardDimensions = {
  small: {
    elevation: 3,
    minWidth: 90,
    maxWidth: 124,
    iconWidth: 33,
    iconHeight: 33,
  },
  smallHorizontal: {
    elevation: 3,
    minWidth: 90,
    maxWidth: '100%',
    iconWidth: 33,
    iconHeight: 29,
  },
  big: {
    elevation: 3,
    minWidth: 150,
    maxWidth: 233,
    iconWidth: 100,
    iconHeight: 100,
  },
  bigHorizontal: {
    elevation: 3,
    minWidth: 150,
    maxWidth: '100%',
    iconWidth: 90,
    iconHeight: 90,
  }
}

export const SectionCard = (props: ISectionCardProps) => {
  const {noPadding, children, type, elavated} = props;

  let dimensions
  switch (type) {
    case 'small':
      dimensions = SectionCardDimensions.small;
      break;
    case 'small-horizontal':
      dimensions = SectionCardDimensions.smallHorizontal;
      break;
    case 'big':
      dimensions = SectionCardDimensions.big;
      break;
    case 'big-horizontal':
      dimensions = SectionCardDimensions.bigHorizontal;
      break;
    default:
      dimensions = SectionCardDimensions.big;

  }
  
  const {elevation, minWidth, maxWidth, iconWidth, iconHeight} = dimensions;

  return (
    <Card elevation={elavated ? 21 : elevation} style={{width: '100%', height: noPadding ? 'auto' : '100%'}}>
      {/* <CardActionArea onClick={callback} style={{width: '100%', height: '100%'}} className={active ? 'Mui-focusVisible' : ''}> */}
      {noPadding ? (
        <>{children}</>
      ) : (
        <CardContent sx={{ p: 4 }}>
          {children}
          {/* <Typography variant="body2" color="text.secondary">
            Define your own criteria
          </Typography> */}
        </CardContent>
      )}
      {/* </CardActionArea> */}
    </Card>
  )
}

export const ProfilesPage = () => {
  // const {state, dispatch} = React.useContext(RecruitmentContext);
  const {state, dispatch, hasRole} = React.useContext(GlobalContext);
  const {departments, teams, profiles, profilesMap, vacancies} = state;

  console.log('PROFILES STATE: vacancies', vacancies, profiles, profilesMap);
  

  useEffect(() => {
    // 1. ORG: fetch departments/teams/roles - pubsub with socketIO
    fetch(`/api/v1/org/user-org-context`, {method: 'GET'}).then(async (response) => {
      const { departments, teams, profiles, users } = await response.json();
      dispatch({
        type: Action.SET_ORG_CONTEXT,
        payload: {departments, teams, profiles, users}
      })
    }).catch((err) => {
      console.error('Error fetching org context', err)
    });
    // 2. FEATURE: recruitment: fetch user vacancies data
    fetch(`/api/v1/recruitment/vacancies`, {method: 'GET'})
    .then(async (response) => {
      const { vacancies } = await response.json();
      dispatch({
        type: Action.SET_VACANCIES,
        payload: vacancies
      })
      
    }).catch((err) => {
      console.error('Error fetching vacancies', err)
    })
  }, [])

  const isAdmin = hasRole('admin');
  const showWizard = isAdmin && departments.length > 0;

  // const {decisions} = state;
  const navigate = useNavigate();

  const [currentDepartment, setCurrentDepartment] = useState<IDepartment>()

  const [modalCurrentTeamOpen, setModalCurrentTeamOpen] = useState<boolean>(false)

  const [modalCurrentProfileOpen, setModalCurrentProfileOpen] = useState<boolean>(false)
  const [currentProfile, setCurrentProfile] = useState<IProfile>()
  const [filter, setFilter] = useState<string>('')

  const filteredProfiles = profiles.filter((p) => profilesMap[p].name.indexOf(filter) !== -1 )

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        {/* IF features.indexOf('recruitment') !== -1*/}
        <Grid item xs={12}>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item>
              <Typography sx={{ mb: 3 }} variant="h3">Role Profiles</Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    fullWidth
                    size='small'
                    label="Filter by name"
                    style={{marginTop: '-4px'}}
                    name="profilesFilter"
                    onChange={(e) => {
                      setFilter(e.target.value)
                    }}
                    required
                    // focused={true}
                    value={filter}
                    variant='outlined'
                    // variant='standard'
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Button
                      style={{marginTop: '-4px'}}
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        navigate(ROUTES.PROFILE_NEW.path)
                        // setCurrentDepartment(getDefaultDepartment())
                        // setNewDepartment(true)
                        // setModalCurrentDepartmentOpen(true)
                      }}>
                        + New Role Profile
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Card>
            <CardContent> */}
              {/* <SectionCard noPadding={true}> */}
                <ProfilesTableSortable
                  profiles={filteredProfiles}
                  profilesMap={profilesMap}
                  hasRole={hasRole}
                  vacancies={vacancies}
                  departments={departments}
                  teams={teams}
                  archiveProfile={async (id) => {
                    dispatch({
                      type: Action.DELETE_PROFILE,
                      payload: {id}
                    })
                    await fetchPromise(`/api/v1/org/profiles`, 'DELETE', {id}, 'Error during Profile deletion')
                  }}
                  duplicateProfile={(id) => {
                    fetch(`/api/v1/org/profiles/${id}`, {
                      method: 'GET',
                    }).then(async (response) => {
                      const { profile } = await response.json();
                      // const newProfile = JSON.parse(JSON.stringify(profilesMap[id]))
                      profile['id'] = v4()
                      profile['name'] = `${profile['name']} - Copy`
                      dispatch({
                        type: Action.CREATE_PROFILE,
                        payload: profile
                      })
                      fetchPromise(`/api/v1/org/profiles`, 'POST', profile, 'Error during Profile creation')
                      
                    }).catch((err) => {
                      console.error('Error fetching profile', err)
                    })
                  }}
                  />
              {/* </SectionCard> */}
            {/* </CardContent>
          </Card> */}

          <TeamModal modalOpen={modalCurrentTeamOpen} setModalOpen={setModalCurrentTeamOpen}>
            {currentDepartment && (
              <Team
                defaultDepartmentId={currentDepartment.id}
                // setUpdateTeam={setCurrentTeam}
                // saveTeam={saveTeamCallback}
                // deleteTeam={deleteTeamCallback}
                setModalOpen={setModalCurrentTeamOpen}
                />
            )}
          </TeamModal>

          <ProfileModal modalOpen={modalCurrentProfileOpen} setModalOpen={setModalCurrentProfileOpen}>
            <Profile
              departmentId={currentProfile?.departmentId || null}
              setModalOpen={setModalCurrentProfileOpen}
              />
          </ProfileModal>
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
          <Typography sx={{ mb: 4 }} variant="h3">By Department</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ mb: 0, ml: 3 }} variant="h5">
                  <span style={{borderLeft: `3px solid #ccc`, paddingLeft: '8px'}}>Generic Profiles</span>
                </Typography>
                <Grid item sm={4} xs={12}>
                <Grid justifyContent="space-between" container spacing={1} sx={{mt: 2, mb: 2, ml: 5, mr: 5}}>
                  <Grid item xs={6}><strong>name</strong></Grid>
                  <Grid item xs={6} sx={{alignItems: 'left', display: 'flex'}}><strong>updated at</strong></Grid>

                  {profiles
                    .filter((p) => !profilesMap[p].departmentId)
                    .sort((a, b) => new Date(profilesMap[a].updatedAt) > new Date(profilesMap[b].updatedAt) ? -1 : 1)
                    .map((profileId) => {
                    const profile = profilesMap[profileId];

                    return (
                      <>
                        <Grid item xs={6}>
                          <MuiLink href={ROUTES.PROFILE_EDIT.getPath(profileId)}>{profile.name}</MuiLink>
                        </Grid>
                        <Grid item xs={6} sx={{alignItems: 'left', display: 'flex'}}>
                            {new Date(profile.updatedAt).toLocaleDateString('en-US')}
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
                </Grid>
              </Grid>
              {departments.map((department) => {
                const {name, id, color} = department;
                return (
                  <Grid item sm={4} xs={12} >
                    <SectionCard>
                      <Typography sx={{ mb: 3 }} variant="h5" style={{cursor: 'pointer'}} onClick={() => {
                          navigate(ROUTES.DEPARTMENT_EDIT.getPath(id))
                        }}>
                        <span style={{borderLeft: `3px solid ${color ? color : '#ccc'}`, paddingLeft: '8px'}}>{name} </span>
                        {/* <Edit3 opacity={0.6} style={{cursor: 'pointer'}} size={'15px'} onClick={() => {
                          navigate(ROUTES.DEPARTMENT_EDIT.getPath(id))
                        }} /> */}
                      </Typography>
                      <Grid justifyContent="space-between" container spacing={1} sx={{ml: 2, mr: 2}}>
                          <Grid item xs={6}><strong>name</strong></Grid>
                          <Grid item xs={6} sx={{alignItems: 'left', display: 'flex'}}><strong>updated at</strong></Grid>
                        {profiles
                          .filter((p) => profilesMap[p].departmentId === id)
                          .sort((a, b) => new Date(profilesMap[a].updatedAt) > new Date(profilesMap[b].updatedAt) ? -1 : 1)
                          .map((profileId) => {
                          const profile = profilesMap[profileId];

                          return (
                            <>
                              <Grid item xs={6}><MuiLink href={ROUTES.PROFILE_EDIT.getPath(profileId)}>{profile.name}</MuiLink></Grid>
                              <Grid item xs={6} sx={{alignItems: 'left', display: 'flex'}}>
                                  {new Date(profile.updatedAt).toLocaleDateString('en-US')}
                              </Grid>
                            </>
                          )
                        })}
                      </Grid>
                    </SectionCard>
                  </Grid>      
                )
              })}
            </Grid>
        </Grid>
        )}
      </Grid>
    </LayoutAuthenticated>
  )
}
