import React, {FC} from "react";
import {Chip, ListItemButton, ListItemButtonProps, ListItemText, ListItemTextProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import {darken, rgba} from "polished";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

interface ISidebarItemProps extends ListItemButtonProps {
  depth?: number;
  component?: FC
  to?: string;
  activeclassname?: string;
  collapsed?: boolean;
}

export const SidebarMenuItemItem = styled(ListItemButton)`
  padding-top: ${(props: any) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : (props.collapsed ? 3 : 3))};
  padding-bottom: ${(props: any) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : (props.collapsed ? 3 : 3))};
  padding-left: ${(props: any) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : (props.collapsed ? 3 : 8))};
  padding-right: ${(props: any) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : (props.collapsed ? 1 : 7))};
  font-weight: ${(props: any) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props: any) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props: any) => props.theme.sidebar.color};
  }
  &.${(props: any) => props.activeclassname} {
    background-color: ${(props: any) =>
  darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props: any) => props.theme.sidebar.color};
    }
  }
` as FC<ISidebarItemProps>;

interface ISidebarTitleProps extends ListItemTextProps {
  depth?: number;
}

export const SidebarMenuItemTitle = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props: any) =>
  rgba(
    props.theme.sidebar.color,
    props.depth && props.depth > 0 ? 0.7 : 1
  )};
    font-size: ${(props: any) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props: any) => props.theme.spacing(4)};
  }
` as FC<ISidebarTitleProps>

export const SidebarMenuItemBadge = styled(Chip)`
  font-weight: ${(props: any) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props: any) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props: any) => props.theme.sidebar.badge.color};
    padding-left: ${(props: any) => props.theme.spacing(2)};
    padding-right: ${(props: any) => props.theme.spacing(2)};
  }
`;

export const SidebarMenuItemExpandLessIcon = styled(ExpandLess)`
  color: ${(props: any) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const SidebarMenuItemExpandMoreIcon = styled(ExpandMore)`
  color: ${(props: any) => rgba(props.theme.sidebar.color, 0.5)};
`;
