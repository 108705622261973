import * as React from 'react';
import {styled} from '@mui/material/styles';
import { spacing } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Button,
  Container,
  Grid,
  Box,
  Toolbar,
} from '@mui/material';

import {Target} from "react-feather";
import { ROUTES } from 'hrFrontend/state/routes';

// const Button = styled(MuiButton)(spacing);

const Brand = styled('div')`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Target)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.header.logo.color};
  width: 32px;
  height: 32px;

  vertical-align: middle;
  display: inline;
`;

export const LandingTopbar = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <AppBar position='relative' color='transparent' elevation={0}>
        <Toolbar>
          <Container>
            <Grid justifyContent="space-between" container>
              <Grid item md={6} xs={6}>
                <Brand onClick={() => navigate(ROUTES.RECRUITMENT_DASHBOARD.path)} style={{cursor: 'pointer'}}>
                  <BrandIcon />
                  QuantiVision
                </Brand>
              </Grid>
              <Grid item container justifyContent="flex-end" md={6} xs={6} >
                <Box sx={{ display: {md: 'inline-block' } }}>
                  <Button
                    style={{marginLeft: '8px'}}
                    color='inherit'
                    component={Link}
                    to={ROUTES.RECRUITMENT_DASHBOARD.path}
                  >
                    Home
                  </Button>
                  <Button
                    style={{marginLeft: '8px'}}
                    color='inherit'
                    component={Link}
                    to={ROUTES.ABOUT.path}
                  >
                    About
                  </Button>
                  <Button
                    style={{marginLeft: '8px'}}
                    color='inherit'
                    component={Link}
                    to={ROUTES.CONTACT.path}
                  >
                    Contact
                  </Button>
                  <Button
                    style={{marginLeft: '8px'}}
                    color='inherit'
                    component={Link}
                    to={ROUTES.LOGIN.path}
                    // target='_blank'
                  >
                    Login
                  </Button>
                  <Button
                    style={{marginLeft: '8px'}}
                    color='primary'
                    variant='contained'
                    href={ROUTES.REQUEST_ACCOUNT.path}
                    // target='_blank'
                  >
                    Book a demo
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
};
