import React, {useCallback, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import * as Yup from 'yup';

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';
import { Action } from 'hrFrontend/state/actions';

const Alert = styled(MuiAlert)(spacing);


const TextField = styled(MuiTextField)(spacing);

export interface IRegisterValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  key: string;
}

interface IRegisterFormValues extends IRegisterValues {
  passwordRetyped: string;
}

const defaultValues = {
  email: '',
  password: '',
  passwordRetyped: '',
  firstName: '',
  lastName: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

export interface IFinishRegistrationUser {
  email: string,
  key: string,
  firstName: string | null,
  lastName: string | null,
}

interface IFinishRegistration {
  user: IFinishRegistrationUser
}



export const FinishRegistration = (props: IFinishRegistration) => {
  const {user} = props;

  const navigate = useNavigate();
  const {finishUserRegistration, loginFrontend} = useContext(GlobalContext);

  const schema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .max(255)
      .required('Required'),
    passwordRetyped: Yup.string().when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both passwords need to be the same'
      ),
    }),
  })
  const [values, setValues] = useState<IRegisterFormValues>({
    ...defaultValues,
    email: user.email,
    key: user.key,
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : ''
  })
  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEdited] = useState<any>({...defaultEditedValues})

  const {firstName, lastName, email, password, passwordRetyped} = values;

  const validateValues = useCallback(
    getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const handleSubmit = useCallback(
    getFormHandleSubmitHandler(values, validateValues, setEdited, async () => {
      const {status, user, departments, teams, profiles, users} = await finishUserRegistration(values)

      if ((`${status}`[0] === '2' || `${status}`[0] === '3')) {
        loginFrontend(user, departments, teams, profiles, users)
        return navigate(ROUTES.RECRUITMENT_DASHBOARD.path);
      }
      
      switch (status) {
        case 400:
          setErrors({...errors, submit: 'Wrong parameters.'})
          break;
        case 429:
          setErrors({...errors, submit: 'We are sorry but the quota for a new user registration in this month has been exceeded. Please contact us at info@quantivision.com and we will glady help you to register via email.'})
          break;
        case 500:
          setErrors({...errors, submit: 'Server error, please try again later.'})
          break;
        default:
          setErrors({...errors, submit: 'Something went wrong during the registration, please try again later.'})
      }
    }),
    [values, validateValues, setEdited]
  )

  const handleFirstNameChange = useCallback(
    getFormChangeHandler('firstName', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handleLastNameChange = useCallback(
    getFormChangeHandler('lastName', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handlePasswordChange = useCallback(
    getFormChangeHandler('password', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handlePasswordRetypedChange = useCallback(
    getFormChangeHandler('passwordRetyped', values, setValues, validateValues),
    [values, setValues, validateValues]
  )

  return (
    <form noValidate onSubmit={handleSubmit}>
        {/* <Alert mt={2} mb={3} severity="warning">
          The registration is closed in beta stage, please come back later.
        </Alert> */}
      {errors.submit && (
        <Alert mt={2} mb={3} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <TextField
        type='text'
        name='firstName'
        label='First name'
        value={firstName}
        error={Boolean(edited.firstName && errors.firstName)}
        fullWidth
        helperText={edited.firstName && errors.firstName}
        onBlur={() => {
          setEdited({...edited, firstName: true})
        }}
        onChange={handleFirstNameChange}
        style={{marginBottom: '8px', marginTop: '8px'}}
      />
      <TextField
        type='text'
        name='lastName'
        label='Last name'
        value={lastName}
        error={Boolean(edited.lastName && errors.lastName)}
        fullWidth
        helperText={edited.lastName && errors.lastName}
        onBlur={() => {
          setEdited({...edited, lastName: true})
        }}
        onChange={handleLastNameChange}
        style={{marginBottom: '8px'}}
      />
      <TextField
        type='email'
        name='email'
        label='Email address'
        value={email}
        error={Boolean(edited.email && errors.email)}
        fullWidth
        helperText={edited.email && errors.email}
        onBlur={() => {
          setEdited({...edited, email: true})
        }}
        disabled={true}
        style={{marginBottom: '8px'}}
      />
      <TextField
        type='password'
        name='password'
        label='Password'
        value={password}
        error={Boolean(edited.password && errors.password)}
        fullWidth
        helperText={edited.password && errors.password}
        onBlur={() => {
          setEdited({...edited, password: true})
        }}
        onChange={handlePasswordChange}
        style={{marginBottom: '8px'}}
      />
      <TextField
        type='password'
        name='passwordRetyped'
        label='Confirm password'
        value={passwordRetyped}
        error={Boolean(edited.passwordRetyped && errors.passwordRetyped)}
        fullWidth
        helperText={edited.passwordRetyped && errors.passwordRetyped}
        onBlur={() => {
          setEdited({...edited, passwordRetyped: true})
        }}
        onChange={handlePasswordRetypedChange}
        style={{marginBottom: '8px'}}
      />
      <Button type='submit' fullWidth variant='contained' color='primary'>
        Finish Registration
      </Button>
    </form>
  );
}
