import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Chip, Grid, TableCell, TableRow, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {ITableSortableColumn, TableSortable} from "sharedFrontend/organisms/TableSortable";

import {ROUTES} from "../state/routes";
import {DecisionType, IDepartment, IProfilesMap, ITeam, ICandidate} from "../state/state";

import {v4} from "uuid";
import {ContentCopy, Edit as MuiEdit, Group} from "@mui/icons-material";
import {Action} from '../state/actions';
import {Download, Edit3, Edit, List, PlusCircle, Trash2, XCircle} from 'react-feather';
import theme from '../theme';
import {styled} from '@mui/material/styles';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { TRole } from 'shared/types';
import _ from 'lodash';

interface IExpandState {
  show: boolean;
  force: boolean;
}

const RemoveButton = styled(XCircle)`
  color: ${(props) => props.theme.palette.error.main};
  fontSize: small;
`

const DuplicateButton = styled(ContentCopy)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const EditButton = styled(Edit)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const ListButton = styled(List)`
  color: ${(props) => props.theme.palette.secondary.main};
`

interface ICandidatesVacancyTableSortable {
  // candidates?: ICandidate[];
  tableVacancyId: string;
  onDeleteCallback?: (candidateId: string) => void
  onOpenCallback: (candidateId: string) => void;
  // removeVacancy: (id: string) => void;
  // duplicateDecision: (id: string) => void;
  // departments: IDepartment[];
  // teams: ITeam[];
  // profilesMap: IProfilesMap;
  // hasRole: (roleId: TRole) => boolean;
}


export const CandidatesVacancyTableSortable = (props: ICandidatesVacancyTableSortable) => {
  const {tableVacancyId, onDeleteCallback, onOpenCallback} = props;
  const navigate = useNavigate();
  const {state, dispatch, hasRole} = React.useContext(GlobalContext);
  const isAdmin = hasRole('admin');
  const {candidates, vacancies} = state;
  

  // const [vacancyRows, setVacancyRows] = useState<any[]>(vacancies)

  useEffect(() => {
    fetch(`/api/v1/recruitment/candidates`, {
      method: 'GET',
    }).then(async (response) => {
      const { candidates } = await response.json();
      dispatch({
        type: Action.UPSERT_CANDIDATES,
        payload: candidates
      })
      // setCurrentCandidates(candidates)
      // setacancyCandidateIds(candidates.filter((a: ICandidate) => a.vacancyId === vacancy.id).map((a: ICandidate) => a.id))
    }).catch((err) => {console.error('Error fetching candidates in vacancy', err)})
  }, [])

  // const [shownEdit, setShownEdit] = useState(vacancies.map(() => false))

  const columns: ITableSortableColumn[] = [
    {id: 'name', label: 'Name', sortable: true, align: 'left'},
    {id: 'stage', label: 'Stage', sortable: true, align: 'center'},
    // {id: 'vacancy', label: 'Vacancy', sortable: true, align: 'center'}
    // {id: 'department', label: 'Department / Team', align: 'center'},
    // {id: 'evaluations', label: 'Evaluations', sortable: false, align: 'center'},
    {id: 'updatedAt', label: 'Updated', sortable: true, align: 'center'},
    // {id: 'edit', label: 'Edit', align: 'center'},
    {id: 'remove', label: 'Remove', align: 'center'},
  ]

  const emptyTableRow = (
    <TableRow
      key={'emptyTableRow'}
      hover
      tabIndex={-1}
    >
      <TableCell align="center" colSpan={5}>
        Empty
      </TableCell>
    </TableRow>
  )
  
  // TODO: useMemo here does not work for some reason
  const rowsData = candidates.filter((a) => a.vacancyIds.indexOf(tableVacancyId) !== -1).map((candidate, index) => {
  //   const {vacancyId,
  //   vacancyName,
  //   vacancyDepartmentId,
  //   vacancyTeamId,
  //   vacancyUpdatedAt,
  //   departmentId,
  //   profileId,
  //   profileName,
  //   candidatesCount
  // } = vacancy;
    const {id, firstName, lastName, updatedAt, vacancyIds} = candidate;
    
    const name = `${firstName} ${lastName}`
    // const {id, name, updatedAt, candidateIds, settings} = vacancy

    // const profileName = profilesMap[`${profileId}`] ? profilesMap[`${profileId}`].name : '-'

    // const profileName = 'aaa' // fromTemplateID ? profiles.find((t) => t.id === fromTemplateID)?.name : `Custom: ${settings.type === DecisionType.ChoosingOne ? 'Choose the best' : 'Choose proportions'}`
    const out = {
      id: {
        value: id
      },
      name: {
        value: name,
        width: '10%',
        jsx: (
          <Grid
            container
            spacing={0}
            onClick={() => {onOpenCallback(id)}}
            style={{ cursor: 'pointer'}}
          >
            <span>{name}</span>
          </Grid>
        )
      },
      stage: {
        value: '-',
        width: '5%',
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
          >
            <span>-</span>
          </Grid>
        )
      },
      updatedAt: {
        width: '8%',
        value: new Date(updatedAt),
        jsx: (
          <span>
            {new Date(updatedAt).toLocaleDateString('en-US')}
          </span>
        )
      },
      // edit: {
      //   value: id,
      //   width: '8%',
      //   jsx: (
      //     <EditButton
      //       style={{ cursor: 'pointer'}}
      //       onClick={() => {
      //         // navigate(ROUTES.CANDIDATE.getPath(id))
      //       }} />
      //   )
      // },
      remove: {
        width: '8%',
        jsx: (
          <RemoveButton
            style={{ cursor: 'pointer'}}
            onClick={() => {
              if (onDeleteCallback) {
                onDeleteCallback(id)
              }
              dispatch({
                type: Action.UPDATE_CANDIDATE,
                payload: {
                  id,
                  vacancyIds: _.without(vacancyIds, tableVacancyId)
                }
              })
            }} />
        )
      },
    }
    // if (!tableVacancyId) {
    //   // @ts-ignore
    //   out.vacancy = {
    //     value: vacancyId,
    //     jsx: (
    //       <Grid
    //         container
    //         spacing={0}
    //         style={{ cursor: 'pointer'}}
    //         onClick={() => navigate('/')}
    //         justifyContent="space-between"
    //       >
    //         <Chip
    //           style={{cursor: 'pointer'}}
    //           key={vacancyId}
    //           label={vacancies.find((v) => v.id === vacancyId)}
    //           variant={'outlined'}
    //           sx={{mr:1 ,mb: 1, mt: 1}}
    //           />
    //       </Grid>
    //     )
    //   }
    // }

    return out;
  })

  return (
    <TableSortable columns={columns} rowsData={rowsData} defaultOrderBy={'updatedAt'} emptyTableRow={emptyTableRow} />
  )
}

