export interface IAction {
  type: Action;
  payload?: any;
}

export enum Action {
  SET_ORG_CONTEXT = 'SET_ORG_CONTEXT',
  // Departments
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT',
  DELETE_DEPARTMENT = 'DELETE_DEPARTMENT',
  UPSERT_DEPARTMENTS = 'UPSERT_DEPARTMENTS',

  // Teams
  CREATE_TEAM = 'CREATE_TEAM',
  UPSERT_TEAM = 'UPSERT_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  DELETE_TEAM = 'DELETE_TEAM',

  // Profiles
  CREATE_PROFILE = 'CREATE_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPSERT_PROFILE = 'UPSERT_PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',
  DUPLICATE_PROFILE = 'DUPLICATE_PROFILE',
  ARCHIVE_PROFILE = 'ARCHIVE_PROFILE',

  // Vacancies
  SET_VACANCIES = 'SET_VACANCIES',
  UPSERT_VACANCY = 'UPSERT_VACANCY',
  CREATE_VACANCY = 'CREATE_VACANCY',
  UPDATE_VACANCY = 'UPDATE_VACANCY',
  DELETE_VACANCY = 'DELETE_VACANCY',
  DUPLICATE_VACANCY = 'DUPLICATE_VACANCY',

  // Candidates
  UPSERT_CANDIDATES = 'UPSERT_CANDIDATES',
  UPSERT_CANDIDATE = 'UPSERT_CANDIDATE',
  CREATE_CANDIDATE = 'CREATE_CANDIDATE',
  UPDATE_CANDIDATE = 'UPDATE_CANDIDATE',
  DELETE_CANDIDATE = 'DELETE_CANDIDATE',

  UPSERT_CANDIDATE_EVALUATIONS = 'UPSERT_CANDIDATE_EVALUATIONS',
  UPSERT_CANDIDATE_EVALUATION = 'UPSERT_CANDIDATE_EVALUATION',
  // CREATE_VACANCY_MODEL = 'UPDATE_VACANCY_MODEL',
  // UPDATE_VACANCY_MODEL = 'UPDATE_VACANCY_MODEL',
  // CREATE_VACANCY_OPTIONS = 'UPDATE_VACANCY_OPTIONS',
  // UPDATE_VACANCY_OPTIONS = 'UPDATE_VACANCY_OPTIONS',
  // User Context Actions
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REQUEST_ACCOUNT = 'REQUEST_ACCOUNT',
  REGISTER_USER = 'REGISTER_USER',
  UPDATE_USER = 'UPDATE_USER',
  SET_SIDEBAR_COLLAPSED = 'SET_SIDEBAR_COLLAPSED'
}
