import React from 'react';
import {styled} from '@mui/material/styles';

import { Badge, Grid, Avatar, Typography, Menu, MenuItem, IconButton as MuiIconButton } from '@mui/material';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'hrFrontend/state/routes';
// import {RecruitmentContext} from '../../../state/context';

// import useAuth from '../../hooks/useAuth';

const Footer = styled('div')`
  background-color: ${(props: any) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props: any) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props: any) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props: any) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;


export const SidebarFooter = ({ ...rest }) => {
  const navigate = useNavigate();
  const {state, logout} = React.useContext(GlobalContext);
  const {user} = state

  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);

  const toggleMenu = (event: React.MouseEvent<any>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenuEl(null);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Footer {...rest}>
      <Grid
        container
        spacing={2}
        onClick={toggleMenu}
        aria-owns={Boolean(anchorMenuEl) ? 'account-settings-menu' : undefined}
        aria-haspopup='true'
        style={{cursor: 'pointer'}}
        >
      {!user ? (
          <FooterText variant='body2' onClick={()=> {
            navigate(ROUTES.LOGIN.path)
            }}>Please login</FooterText>
        ) : (
          <>
          <Grid item>
            <FooterBadge
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant='dot'>
              {user?.avatar ? (
                <Avatar alt={user.name} src={user?.avatar} />
              ) : (
                <Avatar alt={user.name} />
              )}
            
            </FooterBadge>
          </Grid>
          <Grid item>
            <FooterText variant='body2'>{user.name}</FooterText>
            {/* TODO: role */}
            <FooterSubText variant='caption'>User</FooterSubText>
          </Grid>
          </>
        )
      }
      </Grid>
      <Menu
        id='account-settings-menu'
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Footer>
  );
};
