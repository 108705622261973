import React, {FC, forwardRef, PropsWithChildren, Ref} from "react";
import {NavLink} from "react-router-dom";
import {Collapse} from "@mui/material";
import {styled} from "@mui/material/styles";

import {
  SidebarMenuItemItem,
  SidebarMenuItemTitle,
  SidebarMenuItemBadge,
  SidebarMenuItemExpandMoreIcon,
  SidebarMenuItemExpandLessIcon,
} from './SidebarMenuItem.sc'
import { Link } from 'react-router-dom';
interface ICustomRouterLinkProps extends PropsWithChildren<any> {
  to: string;
  children: any;
}

const SidebarMenuItemCustomRouterLink = forwardRef((props: ICustomRouterLinkProps, ref) => (
  <div ref={ref as Ref<any>}>
    <NavLink {...props} />
  </div>
));

interface ISidebarMenuItemNavListItemProps {
  depth?: number
  title?: string,
  href?: string,
  children?: any,
  icon?: any,
  badge?: any,
  open?: boolean,
  collapsed?: boolean;
}

export const SidebarMenuItem = (props: ISidebarMenuItemNavListItemProps) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    collapsed
  } = props;

  const [open, setOpen] = React.useState<boolean>(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };  

  if (children) {
    return (
      <React.Fragment>
        <SidebarMenuItemItem collapsed={collapsed} depth={depth} onClick={handleToggle}>
          {Icon && <Icon color />}
          <SidebarMenuItemTitle depth={depth}>
            {title}
            {badge && <SidebarMenuItemBadge label={badge} />}
          </SidebarMenuItemTitle>
          {open ? <SidebarMenuItemExpandLessIcon /> : <SidebarMenuItemExpandMoreIcon />}
        </SidebarMenuItemItem>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  // console.log('href', href, depth);

  return (
    <React.Fragment>
      <SidebarMenuItemItem
        depth={depth}
        component={SidebarMenuItemCustomRouterLink}
        to={href}
        collapsed={collapsed}
        title={title}
        activeclassname={depth === 0 ? 'active' : 'active'}
      >
        {Icon && <Icon />}
        {/*@ts-ignore*/}
        {!collapsed ? (
          <SidebarMenuItemTitle depth={depth}>
            {title}
            {badge && <SidebarMenuItemBadge label={badge} />}
          </SidebarMenuItemTitle>
        ) : null}
    </SidebarMenuItemItem>
    {/* ) : (
      <Link to={href as string}><Icon /></Link>
    )} */}
    </React.Fragment>
  );
};
