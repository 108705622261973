import React, { useContext, useEffect, useState } from 'react';
import {styled} from '@mui/material/styles';
// import { Helmet } from 'react-helmet-async';

import { Avatar, Typography } from '@mui/material';

import {LayoutAuthentication} from './LayoutAuthentication';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';


export const VerifyAccountEmailPage = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const {loginFrontend} = useContext(GlobalContext);

  const email = params.get('email')
  const key = params.get('key')

  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    (async () => {
      if (email && key) {
        try {
          const response = await fetch(`/api/v1/auth/verify-account-email?email=${encodeURIComponent(email)}&key=${encodeURIComponent(key)}`)
          if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
            const { tenantRequset } = await response.json();
            if (tenantRequset) {
              setIsVerified(true)

              setTimeout(() => {
                navigate(ROUTES.RECRUITMENT_DASHBOARD.path)
              }, 2500)
            }
          }
        } catch (err) {
          // spammer or mistake
          console.error(err);
        }
      }
    })()
  }, [email, key])

  return (
    <LayoutAuthentication>
      {/*<Helmet title='VerifyEmail' />*/}
      {email && key ? (
        isVerified ? (
          <>
            <Typography component='h1' variant='h4' align='center' gutterBottom>
              Success, we will be in touch you shortly!
            </Typography>
            <Typography component='h2' variant='body1' align='center'>
              Your email have been succesfully verified.
            </Typography>
          </>
        ) : (<>
          <Typography component='h1' variant='h4' align='center' gutterBottom>
            Please wait...
          </Typography>
        </>)
      ) : (
        <>
          <Typography component='h1' variant='h4' align='center' gutterBottom>
            One last step, verify your email please!
          </Typography>
          <Typography component='h2' variant='body1' align='center'>
            As any other online service provider, we are forced to verify your email first. Please open your email and click on the "Verify" button.
          </Typography>
        </>
      )}
    </LayoutAuthentication>
  );
}
