import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Chip, Grid, TableCell, TableRow, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {TableSortable} from "sharedFrontend/organisms/TableSortable";

import {ROUTES} from "../state/routes";
import {DecisionType, IDepartment, IProfile, IProfilesMap, ITeam, IVacancy} from "../state/state";

import {v4} from "uuid";
import {ContentCopy, Edit as MuiEdit, Group} from "@mui/icons-material";
import {Action} from '../state/actions';
import {Download, Edit3, Edit, List, PlusCircle, Trash2} from 'react-feather';
import theme from '../theme';
import {styled} from '@mui/material/styles';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { TRole } from 'shared/types';
import { Team, TeamModal } from './Team';
import { Profile, ProfileModal } from './Profile';

interface IExpandState {
  show: boolean;
  force: boolean;
}

const DeleteButton = styled(Trash2)`
  color: ${(props) => props.theme.palette.error.main};
`

const DuplicateButton = styled(ContentCopy)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const EditButton = styled(Edit)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const ListButton = styled(List)`
  color: ${(props) => props.theme.palette.secondary.main};
`

interface IProfilesTableSortable {
  profiles: string[];
  profilesMap: IProfilesMap;
  vacancies: IVacancy[];
  archiveProfile: (id: string) => void;
  departments: IDepartment[];
  teams: ITeam[];
  hasRole: (roleId: TRole) => boolean;
  duplicateProfile: (id: string) => void;
}


export const ProfilesTableSortable = (props: IProfilesTableSortable) => {
  const {profiles, profilesMap, vacancies, archiveProfile, duplicateProfile, hasRole} = props;
  const navigate = useNavigate();
  const isAdmin = hasRole('admin');


  // const [vacancyRows, setVacancyRows] = useState<any[]>(vacancies)

  // useEffect(() => {
  //   // fetch user vacancies data
  //   fetch(`/api/v1/recruitment/vacancies-table`, {
  //     method: 'GET',
  //   }).then(async (response) => {
  //     const { rows } = await response.json();
  //     console.log('response', response, rows);
  //     // @ts-ignore
  //     setVacancyRows([...rows].filter((v) => !!v.id))
      
  //   }).catch((err) => {
  //     console.error('Error fetching vacancies table', err)
  //   })
  // }, [])

  const [shownEdit, setShownEdit] = useState(vacancies.map(() => false))

  const columns = [
    {id: 'name', label: 'Name', sortable: true},
    {id: 'vacancies', label: 'Vacancies', sortable: true},
    {id: 'updatedAt', label: 'Updated', sortable: true},
    {id: 'duplicate', label: 'Duplicate'},
    {id: 'edit', label: 'Edit'},
    {id: 'archive', label: 'Delete'},
    // {id: 'expand', label: (<><ExpandMoreIcon /></>), sortable: true},
  ]

  const emptyTableRow = (
    <TableRow
      key={'emptyTableRow'}
      hover
      tabIndex={-1}
    >
      <TableCell align="center" colSpan={5}>
        Empty
      </TableCell>
    </TableRow>
  )

  const [modalCurrentTeamOpen, setModalCurrentTeamOpen] = useState<boolean>(false)
  const [editedTeamId, setEditedTeamId] = useState<string | undefined>()

  const [modalCurrentProfileOpen, setModalCurrentProfileOpen] = useState<boolean>(false)
  const [editedProfileId, setEditedProfileId] = useState<string | undefined>()
  
  // TODO: useMemo here does not work for some reason
  const rowsData = profiles.map((profileId, index) => {
  //   const {vacancyId,
  //   vacancyName,
  //   vacancyDepartmentId,
  //   vacancyTeamId,
  //   vacancyUpdatedAt,
  //   departmentId,
  //   profileId,
  //   profileName,
  //   candidatesCount
  // } = vacancy;
  const {id, name, departmentId, updatedAt} = profilesMap[profileId];
    // const {id, name, updatedAt, candidateIds, settings} = vacancy

    const vacanciesArr: IVacancy[] = vacancies
      .filter((v) => v.profileId === id)
      .sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1)
    

    // const profileName = 'aaa' // fromTemplateID ? profiles.find((t) => t.id === fromTemplateID)?.name : `Custom: ${settings.type === DecisionType.ChoosingOne ? 'Choose the best' : 'Choose proportions'}`
    return {
      id: {
        value: id
      },
      name: {
        value: name,
        width: '21%',
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            onClick={() => navigate(ROUTES.PROFILE_EDIT.getPath(id, departmentId))}
            // style={{width: '150px'}}
            // onMouseEnter={() => {
            //   shownEdit[i] = !shownEdit[i]
            //   setShownEdit([...shownEdit])
            // }}
            // onMouseLeave={() => {
            //   shownEdit[i] = !shownEdit[i]
            //   setShownEdit([...shownEdit])
            // }}
          >
            <span>{name}</span>
            {/*<MuiLink component={Link} to={`${ROUTES.DECISION_OMNIVIEW.getPath(id)}`}>{name}</MuiLink>*/}
            {/*<EditIcon style={{verticalAlign: 'bottom', cursor: 'pointer', visibility: shownEdit[i] ? 'visible' : 'hidden'}} />*/}
          </Grid>
        )
      },
      vacancies: {
        width: '15%',
        value: vacanciesArr[0] ? vacanciesArr[0].name : '',
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            // onClick={() => navigate('/')}
            justifyContent="space-between"
          >
            {vacanciesArr.map((vacancy) => {
              return (
                <Chip
                  style={{cursor: 'pointer'}}
                  key={vacancy.id}
                  label={vacancy.name}
                  onClick={() => {
                    navigate(ROUTES.VACANCY_EDIT.getPath(vacancy.id))
                  }}
                  variant={'outlined'}
                  sx={{mr:1 ,mb: 1, mt: 1}}
                  />
              )
            })}
          </Grid>
        )
      },
      updatedAt: {
        width: '15%',
        value: updatedAt,
        jsx: (
          <span>
            {new Date(updatedAt).toLocaleDateString('en-US')}
          </span>
        )
      },
      duplicate: {
        value: id,
        width: '10%',
        jsx: (
          <DuplicateButton
            style={{ cursor: 'pointer'}}
            onClick={() => {
              duplicateProfile(id)
            }} />
        )
      },
      edit: {
        value: id,
        width: '10%',
        jsx: (
          <EditButton
            style={{ cursor: 'pointer'}}
            onClick={() => {
              navigate(ROUTES.PROFILE_EDIT.getPath(id, departmentId))
            }} />
        )
      },
      archive: {
        value: id,
        width: '10%',
        jsx: (
          <DeleteButton
            width={'88%'}
            style={{ cursor: 'pointer'}}
            onClick={() => {
              archiveProfile(id)
            }} />
        )
      }
    }
  })
  // }, [
  //   vacancies,
  //   // dispatch,
  //   v4,
  //   shownEdit,
  //   setShownEdit
  // ])

  return (
    <>
      <TableSortable columns={columns} rowsData={rowsData} defaultOrderBy={'updatedAt'} emptyTableRow={emptyTableRow} minWidth={'auto'} />
      <TeamModal modalOpen={modalCurrentTeamOpen} setModalOpen={setModalCurrentTeamOpen}>
        <Team
          setModalOpen={setModalCurrentTeamOpen}
          editedTeamId={editedTeamId}
          />
      </TeamModal>

      <ProfileModal modalOpen={modalCurrentProfileOpen} setModalOpen={setModalCurrentProfileOpen}>
        <Profile
          editedProfileId={editedProfileId}
          setModalOpen={setModalCurrentProfileOpen}
          />
      </ProfileModal>
    </>
  )
}

