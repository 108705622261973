import React from 'react';
import {styled} from '@mui/material/styles';
// import { Helmet } from 'react-helmet-async';

import { Avatar, Typography } from '@mui/material';

import {Login} from '../components/Login';
import {LayoutAuthentication} from './LayoutAuthentication';

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

export const LoginPage = () => {
  return (
    <LayoutAuthentication>
      {/*<Helmet title='Login' />*/}
      <BigAvatar alt='' /> {/* src='/avatar.jpg' */}

      <Typography component='h1' variant='h4' align='center' gutterBottom>
        Welcome back!
      </Typography>
      <Typography component='h2' variant='body1' align='center'>
        Sign in to your account to continue
      </Typography>

      <Login />
    </LayoutAuthentication>
  );
}
