import React, {useCallback, useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {ValidationError} from 'yup'
import {styled} from '@mui/material/styles';
import {
  Button,
  TextField as MuiTextField,
  Alert as MuiAlert
} from '@mui/material';
import { spacing } from '@mui/system';
import {ROUTES} from '../state/routes';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { Link } from 'react-router-dom';


const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

interface IResetPasswordFormValues {
  email: string;
}

const defaultValues: IResetPasswordFormValues = {
  email: ''
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

export const ResetPassword = () => {
  const navigate = useNavigate();
  const {resetPassword} = useContext(GlobalContext)

  const [values, setValues] = useState<IResetPasswordFormValues>({...defaultValues})
  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEdited] = useState<any>({...defaultEditedValues})

  const schema = Yup.object().shape({
    email: Yup.string()
    .required('Email is required')
    .email('Email has a wrong format')
    .max(255)
  });

  const validateValues = useCallback(
    getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const handleEmailChange = useCallback(
    getFormChangeHandler('email', values, setValues, validateValues),
    [values, setValues, validateValues]
  )

  const handleSubmit = useCallback(
    getFormHandleSubmitHandler(values, validateValues, setEdited, async () => {
      const {email} = values
      const response = await resetPassword(email)
      if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
        return setErrors({...errors, submit: 'Please check your email.'})
      }

      switch (response.status) {
        case 400:
          setErrors({...errors, submit: 'Wrong parameters.'})
          break;
        case 429:
          setErrors({...errors, submit: 'We are sorry but there is a quota for password resets at this moment due to security reasons. Please contact us at info@quantivision.com and we will gladly exceed it for you.'})
          break;
        case 500:
          setErrors({...errors, submit: 'Server error, please try again later.'})
          break;
        default:
          setErrors({...errors, submit: 'Something went wrong during the the password reset, please try again later.'})
      }
    }),
    [values, validateValues, setEdited]
  )

  return (
    <form noValidate onSubmit={handleSubmit}>
      {errors.submit && (
        <Alert mt={2} mb={3} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <TextField
        type='email'
        name='email'
        label='Email Address'
        value={values.email}
        error={Boolean(edited.email && errors.email)}
        fullWidth
        helperText={edited.email && errors.email}
        onBlur={() => {
          setEdited({...edited, email: true})
        }}
        onChange={handleEmailChange}
        style={{marginBottom: '8px', marginTop: '8px'}}
      />
      <Button type='submit' fullWidth  variant='contained' color='primary'>
        Reset password
      </Button>
      <Button
        component={Link}
        to={ROUTES.LOGIN.path}
        fullWidth
        color="primary"
      >
        Back to Login
      </Button>
    </form>
  );
}
