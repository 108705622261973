import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles';
import *  as Yup from 'yup';
import { Box, Button, Card, CardActionArea, CardContent, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material'; 

import { Close, Delete } from '@mui/icons-material';
import { IDepartment, ITeam, getDefaultTeam } from 'hrFrontend/state/state';
import { ProportionsWizardIcon, TheBestWizardIcon } from './wizard/WizardIcons';
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';
import PickerPreselected from './PickerPreselected';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { Action } from 'hrFrontend/state/actions';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';


export const TeamModalComponent = styled(Modal)`
  padding-top: 12px;

  modal:{
  "&:focus":{
    outline: none;
    border: none;
    }
  }
`;


interface ITeamModalProps extends PropsWithChildren<any> {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

export const TeamModal = (props: ITeamModalProps) => {
  const {modalOpen, setModalOpen, children} = props;

  return (
   <div>  {/*style={{outline:'none!important'}}> */}
      <TeamModalComponent
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          // if (onCloseCallback) {onCloseCallback()}
        }}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        style={{overflow: 'scroll'}}
      >
        <Container maxWidth="md" style={{outline:'none'}}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
               {children}
              </CardContent>
            </Card>
          </form>
        </Container>
        
      </TeamModalComponent>
    </div>
  )
}


interface ITeamTypeTileProps {
  title: string;
  callback: () => void;
  Icon: (props: any) => JSX.Element;
  Radio: JSX.Element;
}

const TeamTypeTile = (props: ITeamTypeTileProps) => {

  const {title, callback, Icon, Radio} = props;

  return (
    <Card elevation={4} variant={'outlined'} sx={{ minWidth: '100%', maxWidth: '100%' }} style={{width: '100%', height: '100%'}}>
      <CardActionArea onClick={callback} style={{width: '100%', height: '100%'}}>
        <CardContent sx={{ pt: 1, pb: 4, pl: 4, pr: 4 }}>
          {Radio}
          <Icon width={33} height={33} opacity={'1.0'} />
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              marginTop: '0.8em',
              marginBottom: '0',
              textAlign: 'center',
              fontSize: '1em',
              minHeight: '2em'
            }}>
            {title}
          </Typography>
          
          {/* <Typography variant="body2" color="text.secondary">
            Define your own criteria
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface ITeamRequiredValues {
  name: string;
}

const defaultValues: ITeamRequiredValues = {
  name: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const schema = Yup.object().shape({
  name: Yup.string()
    .required('First name is required')
    .max(255)
})

interface ITeamProps {
  defaultDepartmentId?: string;
  setModalOpen?: (open: boolean) => void;
  editedTeamId?: string;
}

export const Team = (props: ITeamProps) => {
  const {/*setUpdateTeam, saveTeam, deleteTeam,*/ editedTeamId, defaultDepartmentId, setModalOpen} = props;

  const {state, dispatch} = React.useContext(GlobalContext);

  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEditedLocal] = useState<any>({...defaultEditedValues})

  const {departments} = state;

  const [team, setTeam] = useState<ITeam>(editedTeamId ? state.teams.find((t) => t.id === editedTeamId) as ITeam : getDefaultTeam(defaultDepartmentId as string))

  const {id, name, departmentId} = team;
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)

  useEffect(() => {
    if (editedTeamId) {
      fetch(`/api/v1/org/teams/${departmentId}/${editedTeamId}`, {
        method: 'GET',
      }).then(async (response) => {
        const { team } = await response.json();
        console.log('response', response, team);
        dispatch({
          type: Action.UPSERT_TEAM,
          payload: [...team]
        })
        setTeam(team);
      }).catch((err) => {
        console.error('Error fetching team', err)
      })
    }
  }, [])


  const validateValues = useCallback(getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const setEdited = (edited: any) => {
    setEditedLocal(edited)
    validateValues(team)
  }

  const setTeamWrapper = useCallback((team: ITeam) => {
    setTeam(team)
    if (editedTeamId) {
      dispatch({
        type: Action.UPDATE_TEAM,
        payload: {
          ...team
        }
      })
      fetchPromise(`/api/v1/org/teams`, 'PATCH', team, 'Error during Team update');
    }
  }, [editedTeamId, setTeam])

  const handleNameChange = useCallback(
    getFormChangeHandler('name', team, setTeamWrapper, validateValues),
    [team, setTeamWrapper, validateValues]
  )

  const handleCreate = useCallback(
    getFormHandleSubmitHandler(team, validateValues, setEdited, async () => {
      setSaveDisabled(true)
      dispatch({
        type: Action.CREATE_TEAM,
        payload: {
          ...team
        }
      })
      await fetchPromise(`/api/v1/org/teams`, 'POST', team, 'Error during Team creation')
      if (setModalOpen) {
        setModalOpen(false);
      }
    })
    ,[team, validateValues, setEdited])

  return (
    <React.Fragment>
      {setModalOpen && (
        <React.Fragment>
          <Grid container spacing={0} justifyContent='space-between'>
            <Grid item>
              <Typography component={'h3'} sx={{ mb: 1 }}><strong>{editedTeamId ? 'Edit' : 'New'} Team</strong></Typography>
            </Grid>
            <Grid item>
              <Close style={{opacity: '1.0', cursor: 'pointer' }} onClick={() => {if (setModalOpen) { setModalOpen(false);} }} />
            </Grid>
          </Grid>
          <Divider sx={{mb: 4}} />
        </React.Fragment>
      )}
        
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Team Name"
            variant='outlined'
            value={team.name}
            error={Boolean(edited.name && errors.name)}
            helperText={edited.name && errors.name}
            onBlur={() => {setEdited({...edited, name: true})}}
            fullWidth={true}
            onChange={handleNameChange}
            />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
         Team Color:
         <PickerPreselected color={team.color} onUpdateHandler={(color: string) => {
            console.log('color', color);
            setTeam({
              ...team,
              color,
            })
          }} />
        </Grid> */}
        <Grid item md={6} xs={6}>
          <FormControl fullWidth>
            <InputLabel id="departmentLabel">Department</InputLabel>
            <Select
              labelId="departmentLabel"
              id="departmentId"
              disabled={true}
              value={team.departmentId ? team.departmentId : ''}
              label="Select Department"
              onChange={(e: SelectChangeEvent) => {
                setTeam({
                  ...team,
                  departmentId: e.target.value ? e.target.value : ''
                })
              }}
            >
              <MenuItem key={`departmentNull`} value={''}></MenuItem>
              {departments.map((profile, i) => {
                return (
                  <MenuItem key={`department${i}`} value={profile.id}>{profile.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {!editedTeamId && (
            <Button
              color={"secondary"}
              variant={"contained"}
              disabled={saveDisabled}
              onClick={handleCreate}>
                Create
            </Button>
          )}

          {editedTeamId ? (
            <Button
              sx={{ ml: 3}}
              color={"error"}
              variant={"contained"}
              disabled={deleteDisabled}
              onClick={async () => {
                if (confirm('Are you sure you want to delete this team?')) {
                  setDeleteDisabled(true)
                  dispatch({
                    type: Action.DELETE_TEAM,
                    payload: team
                  })
                  await fetchPromise(`/api/v1/org/teams`, 'DELETE', {id: id}, 'Error during Team deletion')
                }
                if (setModalOpen) {
                  setModalOpen(false);
                }
              }}>
                Delete
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
