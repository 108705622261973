import React from 'react';
import ReactDOM from 'react-dom';
// import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import { GlobalProvider } from './state/contexts/GlobalContext';

// const client = new ApolloClient({
//   uri: `${HR_PROD_BASE_URL}/graphql`,
//   cache: new InMemoryCache(),
// });

ReactDOM.hydrate(
  // <ApolloProvider client={client}>
  <BrowserRouter>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </BrowserRouter>,
  // </ApolloProvider>
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
