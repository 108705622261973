import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import {styled} from '@mui/material/styles';
import *  as Yup from 'yup';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, SelectChangeEvent, Slider, Switch, TextField, Typography } from '@mui/material'; 

import { Close, Delete, ExpandMore } from '@mui/icons-material';
import { CriterionTypeEnum, IDepartment, IEvaluationGuideMap, IProfile, IScaledEvaluationGuideMap, getDefaultKoCriterion, getDefaultProfile, getDefaultScaledCriterion, getDefaultScaledCriterionEvaluationMap } from 'hrFrontend/state/state';
import { ProportionsWizardIcon, TheBestWizardIcon } from './wizard/WizardIcons';
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';
import PickerPreselected from './PickerPreselected';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { Action } from 'hrFrontend/state/actions';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { pullAt } from 'lodash';
import { XCircle } from 'sharedFrontend/icons/x-circle';
import { SliderAdjacentTextField, SliderAdjacentTextFieldNegative, SliderHollow } from './SilderStyles';
import { sliderPerformanceLabels, sliderValueTextFn } from './utils/utils-decision';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { useNavigate } from 'react-router';
import { ROUTES } from 'hrFrontend/state/routes';

// export interface IHowToEvaluateGuide {
//   '0': string;
//   '2': string;
//   '4': string;
//   '6': string;
//   '8': string;
//   '10': string;
// }

// const defaultHowToEvaluateGuide: IHowToEvaluateGuide = {
//   '0': '',
//   '2': '',
//   '4': '',
//   '6': '',
//   '8': '',
//   '10': ''
// }

// let howToEvaluateArr: string[] = []
// for (let key in defaultHowToEvaluateGuide) {
//   howToEvaluateArr.push(key)
// }

const getCriterionValueFormatter = (type?: CriterionTypeEnum) => {
  switch (type) {
    case CriterionTypeEnum.Positive:
      return (value: number) => value
      break;
    case CriterionTypeEnum.Negative:
      return (value: number) => value * -1
      break;
    default:
      return (value: number) => value
      break;

  }
}



export const ProfileModalComponent = styled(Modal)`
  padding-top: 12px;

  modal:{
  "&:focus":{
    outline: none;
    border: none;
    }
  }
`;

const getSliderWeightLabel = (roleName: string) => [
  {
    value: 5,
    label: `Relevance for: ${roleName}`,
  },
];

const sliderWeightLabelsOld = [
  {
    value: 1,
    label: 'Irrelevant',
  },
  {
    value: 3,
    label: 'Good To Have',
  },
  {
    value: 5,
    label: 'Important',
  },
  {
    value: 8,
    label: 'Very Important',
  },
  {
    value: 10,
    label: 'Essential',
  }
];


interface IProfileModalProps extends PropsWithChildren<any> {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

export const ProfileModal = (props: IProfileModalProps) => {
  const {modalOpen, setModalOpen, children} = props;

  return (
   <div>  {/*style={{outline:'none!important'}}> */}
      <ProfileModalComponent
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        style={{overflow: 'scroll'}}
      >
        <Container maxWidth="md" style={{outline:'none'}}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
               {children}
              </CardContent>
            </Card>
          </form>
        </Container>
        
      </ProfileModalComponent>
    </div>
  )
}


interface IProfileTypeTileProps {
  title: string;
  callback: () => void;
  Icon: (props: any) => JSX.Element;
  Radio: JSX.Element;
}

const ProfileTypeTile = (props: IProfileTypeTileProps) => {

  const {title, callback, Icon, Radio} = props;

  return (
    <Card elevation={4} variant={'outlined'} sx={{ minWidth: '100%', maxWidth: '100%' }} style={{width: '100%', height: '100%'}}>
      <CardActionArea onClick={callback} style={{width: '100%', height: '100%'}}>
        <CardContent sx={{ pt: 1, pb: 4, pl: 4, pr: 4 }}>
          {Radio}
          <Icon width={33} height={33} opacity={'1.0'} />
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              marginTop: '0.8em',
              marginBottom: '0',
              textAlign: 'center',
              fontSize: '1em',
              minHeight: '2em'
            }}>
            {title}
          </Typography>
          
          {/* <Typography variant="body2" color="text.secondary">
            Define your own criteria
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface IProfileProps {
  departmentId?: string | null;
  setModalOpen?: (open: boolean) => void;
  editedProfileId?: string;
  onSaveCallback?: (profile: IProfile) => void;
}

interface IProfileRequiredValues {
  name: string;
}

const defaultValues: IProfileRequiredValues = {
  name: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const createYupProfileSchema = (profile: IProfile) => {
  const {criteria} = profile;

  const mustHaveCriteriaNames = {}
  criteria.mustHaveCriteria.forEach((c, index) => {
    // @ts-ignore
    mustHaveCriteriaNames[`mustHave-${index}`] = Yup.string().required('Criteria name is required').max(255)
  })

  const scaledCriteriaNames = {}
  criteria.scaledCriteria.forEach((c, index) => {
    // @ts-ignore
    scaledCriteriaNames[`scaled-${index}`] = Yup.string().required('Criteria name is required').max(255)
  })

  return Yup.object().shape({
    name: Yup.string().required('Profile name is required').max(255),
    criteria: Yup.object().shape({
      mustHaveCriteria: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Criteria name is required').max(255),
        })
      ),
      scaledCriteria: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Criteria name is required').max(255),
        })
      )
    })
  })
}

export const Profile = (props: IProfileProps) => {
  const {/*setUpdateProfile, saveProfile, deleteProfile,*/ editedProfileId, departmentId, setModalOpen, onSaveCallback} = props;


  const navigate = useNavigate();
  const {state, dispatch} = React.useContext(GlobalContext);

  const {departments, profilesMap, profiles, settings} = state;

  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEdited] = useState<any>({...defaultEditedValues})

  const [profile, setProfile] = useState<IProfile>(editedProfileId
    ? profilesMap[profiles.find((tId) => tId === editedProfileId) as string]
    : getDefaultProfile(departmentId as string)
  )

  const schema = useMemo(() => {
    return createYupProfileSchema(profile)
  }, [profile])

  const validateValues = useCallback(getFormValidateHandler(
    schema,
    setErrors,
    defaultErrorValues
  ), [schema, setErrors, defaultErrorValues])

  const setProfileWrapper = useCallback((profile: IProfile) => {
    setProfile(profile)
                
    if (editedProfileId) {
      dispatch({
        type: Action.UPDATE_PROFILE,
        payload: {
          ...profile
        }
      })
      fetchPromise(`/api/v1/org/profiles`, 'PATCH', profile, 'Error during Profile update');
    }
  }, [editedProfileId, setProfile, schema, defaultErrorValues])

  const handleNameChange = useCallback(
    getFormChangeHandler('name', profile, setProfileWrapper, validateValues),
    [profile, setProfileWrapper, validateValues]
  )

  const {id, name, criteria} = profile;
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)

  useEffect(() => {
    if (editedProfileId) {
      fetch(`/api/v1/org/profiles/${editedProfileId}`, {
        method: 'GET',
      }).then(async (response) => {
        const { profile } = await response.json();
        console.log('response', response, profile);
        dispatch({
          type: Action.UPSERT_PROFILE,
          payload: profile
        })
        setProfile(profile);
      }).catch((err) => {
        console.error('Error fetching profile', err)
      })
    }

    fetch(`/api/v1/org/departments`, {
      method: 'GET',
    }).then(async (response) => {
      const { departments } = await response.json();
      console.log('response', response, departments);
      dispatch({
        type: Action.UPSERT_DEPARTMENTS,
        payload: departments
      })
    }).catch((err) => {
      console.error('Error fetching departments', err)
    })
  }, [])

  const [showOptionalDescription, setShowOptionalDescription] = useState<boolean[]>([...profile.criteria.scaledCriteria.map(() => false)]);


  const editProfile = (profile: IProfile, frontentOnly = false) => {
    setProfile(profile)
    validateValues(profile)

    if (editedProfileId) {
      dispatch({
        type: Action.UPDATE_PROFILE,
        payload: {
          ...profile
        }
      })
      if (!frontentOnly) {
        fetchPromise(`/api/v1/org/profiles`, 'PATCH', profile, 'Error during Profile update');
      }
    }
  }

  const handleCreate = useCallback(
    getFormHandleSubmitHandler(profile, validateValues, setEdited, async () => {

      setSaveDisabled(true)
      dispatch({
        type: Action.CREATE_PROFILE,
        payload: {
          ...profile
        }
      })
      await fetchPromise(`/api/v1/org/profiles`, 'POST', profile, 'Error during Profile creation')
      if (onSaveCallback) {
        onSaveCallback(profile)
      } else {
        navigate(-1)
      }
      if (setModalOpen) {
        setModalOpen(false)
      }
    })
    ,[profile, validateValues, setEdited]
  )

  const handleDelete = useCallback(async () => {
      if (confirm('Are you sure you want to delete this profile?')) {
        setDeleteDisabled(true)
        dispatch({
          type: Action.DELETE_PROFILE,
          payload: profile
        })
        await fetchPromise(`/api/v1/org/profiles`, 'DELETE', {id: id}, 'Error during Profile deletion')
        if (setModalOpen) {
          setModalOpen(false)
        } else {
          // navigate(-1) does not work in profiles page(state does not propagate)
          // navigate(ROUTES.PROFILES.path)
          navigate(-1)
        }
      }
    }
    ,[profile, dispatch, navigate]
  )

  return (
    <React.Fragment>
      {setModalOpen && (
        <React.Fragment>
          <Grid container spacing={0} justifyContent='space-between'>
            <Grid item>
              <Typography component={'h3'} sx={{ mb: 1 }}><strong>{editedProfileId ? 'Edit' : 'New'} Role Profile</strong></Typography>
            </Grid>
            <Grid item>
              <Close style={{opacity: '1.0', cursor: 'pointer' }} onClick={() => {if (setModalOpen) { setModalOpen(false) } }} />
            </Grid>
          </Grid>
          <Divider sx={{mb: 4}} />
        </React.Fragment>
      )}
        
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Profile Name"
            variant='outlined'
            value={profile.name}
            error={Boolean(edited.name && errors.name)}
            helperText={edited.name && errors.name}
            onBlur={() => {setEdited({...edited, name: true})}}
            fullWidth={true}
            onChange={handleNameChange}
            // onBlur={(e) => {
            //   editProfile({
            //     ...profile,
            //     name: e.target.value
            //   })
            // }}
            />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
         Profile Color:
         <PickerPreselected color={profile.color} onUpdateHandler={(color: string) => {
            console.log('color', color);
            setProfile({
              ...profile,
              color,
            })
          }} />
        </Grid> */}
        <Grid item md={6} xs={6}>
          <FormControl fullWidth>
            <InputLabel id="departmentLabel">Department</InputLabel>
            <Select
              labelId="departmentLabel"
              id="departmentId"
              disabled={!!editedProfileId}
              value={profile.departmentId ? profile.departmentId : ''}
              label="Select Department"
              onChange={(e: SelectChangeEvent) => {
                editProfile({
                  ...profile,
                  departmentId: e.target.value ? e.target.value : ''
                })
              }}
            >
              <MenuItem key={`departmentNull`} value={''}></MenuItem>
              {departments.map((profile, i) => {
                return (
                  <MenuItem key={`department${i}`} value={profile.id}>{profile.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Divider />

          <CardHeader
            title="Must Have Criteria"
            subheader="Simple conditions that each candidate must match in order to be evaluated further"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {criteria?.mustHaveCriteria.map(({name, whyDescription}, index) => {
                const criteriaKey = `criteria.mustHaveCriteria[${index}].name`;
                return (<>
                  <Grid item md={5} xs={10}>
                    <TextField
                      fullWidth
                      label="Must Have Criteria"
                      name={`mustHaveCheck${index}`}
                      onChange={(e) => {
                        criteria.mustHaveCriteria[index].name = e.target.value
                        editProfile({...profile})
                      }}
                      required
                      value={name}
                      error={Boolean(errors[criteriaKey])}
                      helperText={errors[criteriaKey]}
                      onBlur={() => {
                        edited[criteriaKey] = true
                        setEdited({...edited})
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Why"
                      name={`whyMustHave${index}`}
                      onChange={(e) => {
                        criteria.mustHaveCriteria[index].whyDescription = e.target.value
                        editProfile({...profile})
                      }}
                      required
                      value={whyDescription}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={1} xs={2} style={{paddingTop: '32px'}}>
                    <Button
                      component="a"
                      startIcon={<XCircle fontSize="small" color="error" />}
                      disableRipple
                      onClick={() => {
                        pullAt(criteria.mustHaveCriteria, [index])
                        delete errors[criteriaKey]
                        delete edited[criteriaKey]
                        setErrors({...errors})
                        setEdited({...edited})
                        editProfile({...profile})
                      }}
                      // @ts-ignore
                      sx={{
                        borderRadius: 1,
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                          color: 'neutral.400'
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255,255,255, 0.08)'
                        }
                      }}
                    />
                  </Grid>
                </>)
              })}
              <Grid item md={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault()
                    criteria.mustHaveCriteria.push(getDefaultKoCriterion())
                    const mustHaveLength = criteria.mustHaveCriteria.length;
                    errors[`criteria.mustHaveCriteria[${mustHaveLength}]`] = {name: ''}
                    edited[`criteria.mustHaveCriteria[${mustHaveLength}]`] = {name: ''}
                    setErrors({...errors})
                    setEdited({...edited})
                    editProfile({...profile})
                  }}>+ Must Have Criterion</Button>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardHeader
            title="Weighted Criteria"
            subheader="Identify profile criteria and apply corresponding weights"
          />

          {criteria.scaledCriteria.map(({name, weight, minPerformance, type, whatDescription, whyDescription, evaluationGuideMap}, index) => {
          const SliderAdjacentInput = type === CriterionTypeEnum.Negative ? SliderAdjacentTextFieldNegative : SliderAdjacentTextField;

          criteria.scaledCriteria[index].evaluationGuideMap = evaluationGuideMap ? evaluationGuideMap : getDefaultScaledCriterionEvaluationMap()
          const currentEvaluationGuide: IScaledEvaluationGuideMap = criteria.scaledCriteria[index].evaluationGuideMap as IScaledEvaluationGuideMap;

          const criteriaKey = `criteria.scaledCriteria[${index}].name`;
          return (
            <>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {/* DECISION BAKER style */}
                  {/* <Grid container spacing={1}>
                    <Grid item md={10} xs={10} pt={1}>
                      <Typography id="input-slider" gutterBottom variant="subtitle2" mb={0} ml={1} style={{fontSize: '1.05em'}}>
                        Weight
                      </Typography>
                      <SliderHollow
                        min={0}
                        max={max}
                        // size='small'
                        defaultValue={5}
                        color={'secondary'}
                        aria-labelledby='input-slider'
                        valueLabelDisplay='auto'
                        // marks={sliderWeightLabels}
                        scale={getCriterionValueFormatter(type)}
                        getAriaValueText={sliderValueTextFn}
                        value={weight}
                        onChange={(e, newValue) => {
                          // const numberInput = Number(newValue)
                          // if (Number.isNaN(numberInput)) {
                          //   return false;
                          // }
                          // weight = numberInput
                          // setDecisionModel({...decisionModel})
                        }}
                      />
                    </Grid>
                    <Grid item md={2} xs={2} sx={{pb: 1, pt: 0, pl: 2, pr: 2}}>
                      <SliderAdjacentInput
                        fullWidth
                        variant='standard'
                        name='weightInput'
                        value={getCriterionValueFormatter(type)(weight)}
                        size={'small'}
                        onChange={(event) => {
                          // console.log('event.target.value', event.target.value, Number(event.target.value));
                          // const currentCriteria = criteria[index]
                          // let numberInput = Number(event.target.value)

                          // if (currentCriteria.type === CriterionTypeEnum.Negative) {
                          //   if (event.target.value === '-') {
                          //     numberInput = 0
                          //   }

                          //   if (numberInput > 0) {
                          //     numberInput = numberInput * -1;
                          //   }

                          //   if (numberInput < -max) {
                          //     numberInput = -max;
                          //   }
                          // } else {
                          //   if (numberInput < 0) {
                          //     numberInput = 0;
                          //   } else if (numberInput > max) {
                          //     numberInput = max;
                          //   }
                          // }

                          // if (Number.isNaN(numberInput)) {
                          //   event.preventDefault();
                          //   return false;
                          // }

                          // numberInput = getCriterionValueFormatter(currentCriteria.type)(numberInput)
                          // currentCriteria.weight = numberInput
                          // setDecisionModel({...decisionModel})
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={24} style={{paddingTop: '8px'}}>
                      <span style={{fontSize: '95%'}}>Importance: ...</span>
                    </Grid>
                    {showOptionalDescription[index] && (
                      <Grid item md={12} xs={24}>
                        <TextField
                          fullWidth
                          label={`${`Why ${weight ? weight : '0'}/${max} weight?`}`}
                          name={`whyDescription${index}`}
                          onChange={(e) => {
                            // whyDescription = e.target.value
                            // setDecisionModel({...decisionModel})
                          }}
                          required
                          value={whyDescription}
                          variant="standard"
                          size={'small'}
                        />
                      </Grid>
                    )}
                  </Grid> */}
                  
                  {/* HRING STYLE */}
                  <Grid item md={5} xs={10} container justifyContent="flex-end" style={{marginTop: '12px'}}>
                    <TextField
                      fullWidth
                      label="Criterion Name"
                      name={`criterionName${index}`}
                      onChange={(e) => {
                        criteria.scaledCriteria[index].name = e.target.value
                        editProfile({...profile})
                      }}
                      required
                      value={name}
                      error={Boolean(errors[criteriaKey])}
                      helperText={errors[criteriaKey]}
                      onBlur={() => {
                        edited[criteriaKey] = true
                        setEdited({...edited})
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={5} xs={10} style={{paddingTop: '26px'}}>
                    <Slider
                      min={settings.scaleMin}
                      max={settings.scaleMax}
                      step={settings.scaleStep}
                      // size="small"
                      defaultValue={5}
                      color={'secondary'}
                      aria-labelledby="input-slider"
                      valueLabelDisplay="auto"
                      marks={getSliderWeightLabel(profile.name)}
                      getAriaValueText={sliderValueTextFn}
                      value={weight}
                      style={{width: '80%', display: 'block', margin: '0 auto'}}
                      onChange={(e, newValue) => {
                        const numberInput = Number(newValue)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].weight = numberInput
                        editProfile({...profile}, true)
                      }}
                      onChangeCommitted={(e, newValue) => {
                        const numberInput = Number(newValue)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].weight = numberInput
                        editProfile({...profile})
                      }}
                    />
                  </Grid>
                  <Grid item md={1} xs={2} style={{paddingTop: '34px'}}>
                    <Input
                      value={weight}
                      size="small"
                      style={{width: '43px', display: 'block', margin: '0 auto'}}
                      onChange={(event) => {
                        const numberInput = Number(event.target.value)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].weight = numberInput
                        editProfile({...profile})
                      }}
                      onBlur={() => {
                        const currentCriteria = criteria.scaledCriteria[index]

                        if (currentCriteria.weight < settings.scaleMin) {
                          currentCriteria.weight = settings.scaleMin;
                        } else if (currentCriteria.weight > settings.scaleMax) {
                          currentCriteria.weight = settings.scaleMax;
                        }
                        editProfile({...profile}, false)
                      }}
                      inputProps={{
                        step: settings.scaleStep,
                        min: settings.scaleMin,
                        max: settings.scaleMax,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                  <Grid item md={1} xs={2} style={{paddingTop: '32px', display: 'flex', justifyContent: 'space-evenly'}}>
                    <ExpandMore fontSize="small" style={{cursor: 'pointer'}} onClick={() => {
                      showOptionalDescription[index] = !showOptionalDescription[index]
                      setShowOptionalDescription([...showOptionalDescription])
                    }} />
                    <XCircle fontSize="small" color="error" style={{cursor: 'pointer'}} onClick={() => {
                      if (confirm('Are you sure to delete this criteria?')) {
                        pullAt(criteria.scaledCriteria, [index])
                        delete errors[criteriaKey]
                        delete edited[criteriaKey]
                        setErrors({...errors})
                        setEdited({...edited})
                        editProfile({...profile})
                      }
                    }} />
                  </Grid>

                  {showOptionalDescription[index] && (<>
                    <Grid item md={5} xs={10} container justifyContent="flex-end">
                      <TextField
                        fullWidth
                        label={`${name ? `Describe "${name}"` : 'Description'}`}
                        name={`whatDescription${index}`}
                        onChange={(e) => {
                          criteria.scaledCriteria[index].whatDescription = e.target.value
                          editProfile({...profile})
                        }}
                        value={whatDescription}
                        variant="standard"
                        size={'small'}
                      />
                    </Grid>

                    <Grid item md={7} xs={14}>
                      <TextField
                        fullWidth
                        label={`${`Why ${weight ? weight : '1'}/${settings.scaleMax} weight?`}`}
                        name={`whyDescription${index}`}
                        onChange={(e) => {
                          criteria.scaledCriteria[index].whyDescription = e.target.value
                          editProfile({...profile})
                        }}
                        required
                        value={whyDescription}
                        variant="standard"
                        size={'small'}
                      />
                    </Grid>
                    <Grid item md={5} xs={10} container justifyContent="flex-end">
                      <Typography sx={{ mb: 3, textAlign: 'right', mr: 3, pt: '26px' }} variant="subtitle2">Evaluation Guide:</Typography>
                    </Grid>

                    <Grid item md={7} xs={14} container>
                      {Object.keys(currentEvaluationGuide).map((keyScore) => {
                        // @ts-ignore
                        const currentEvaluation = currentEvaluationGuide[keyScore]

                        return (<>
                          <Grid item md={1} xs={2} style={{paddingTop: '27px'}}>{keyScore}-{Number(keyScore)+1}</Grid>
                          <Grid item md={11} xs={22} style={{marginTop: '9px', marginBottom: '12px'}}>
                            <TextField
                              fullWidth
                              label={`Signs of candidate's ${keyScore}-${Number(keyScore)+1} performance`}
                              name={`evaluateGuide${keyScore}`}
                              onChange={(e) => {
                                // @ts-ignore
                                criteria.scaledCriteria[index].evaluationGuideMap[keyScore] = e.target.value;
                                editProfile({...profile})
                                /*@ts-ignore*/}}
                              value={currentEvaluation}
                              variant="standard"
                              size={'small'}
                            />
                          </Grid>
                        </>)

                      })}
                    </Grid>
                  </>)
                  }

                  <Grid item md={5} xs={10} container justifyContent="flex-end">
                    <Typography sx={{ mb: 3, textAlign: 'right', mr: 3, pt: '26px' }} variant="subtitle2">Minimal Candidate Score:</Typography>
                  </Grid>
                  <Grid item md={5} xs={10} style={{paddingTop: '34px', marginBottom: '24px'}}>
                    <Slider
                      min={0}
                      max={settings.scaleMax}
                      step={settings.scaleStep}
                      // size="small"
                      defaultValue={0/* @ts-ignore */}
                      color={'error'}
                      aria-labelledby="input-slider"
                      valueLabelDisplay="auto"
                      marks={sliderPerformanceLabels}
                      getAriaValueText={sliderValueTextFn}
                      value={minPerformance}
                      style={{width: '80%', display: 'block', margin: '0 auto'}}
                      onChange={(e, newValue) => {
                        const numberInput = Number(newValue)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].minPerformance = numberInput
                        editProfile({...profile}, true)
                      }}
                      onChangeCommitted={(e, newValue) => {
                        const numberInput = Number(newValue)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].minPerformance = numberInput
                        editProfile({...profile})
                      }}
                    />
                  </Grid>
                  <Grid item md={1} xs={2} style={{paddingTop: '34px'}}>
                    <Input
                      value={minPerformance}
                      size="small"
                      style={{width: '43px', display: 'block', margin: '0 auto'}}
                      onChange={(event) => {
                        const numberInput = Number(event.target.value)
                        if (Number.isNaN(numberInput)) {
                          return false;
                        }
                        criteria.scaledCriteria[index].minPerformance = numberInput
                        editProfile({...profile})
                      }}
                      onBlur={() => {
                        const currentCriteria = criteria.scaledCriteria[index]

                        if (currentCriteria.minPerformance < settings.scaleMin) {
                          currentCriteria.minPerformance = settings.scaleMin;
                        } else if (currentCriteria.minPerformance > settings.scaleMax) {
                          currentCriteria.minPerformance = settings.scaleMax;
                        }
                        editProfile({...profile})
                      }}
                      inputProps={{
                        step: settings.scaleStep,
                        min: settings.scaleMin,
                        max: settings.scaleMax,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                  <Grid item md={1} xs={2} style={{paddingTop: '32px', display: 'flex', justifyContent: 'space-evenly'}}>

                  </Grid>
                </Grid>
              </CardContent></>
          )
        })}

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault()
                  criteria.scaledCriteria.push({...getDefaultScaledCriterion()})
                  const scaledLength = criteria.scaledCriteria.length;
                  errors[`criteria.scaledCriteria[${scaledLength}]`] = {name: ''}
                  edited[`criteria.scaledCriteria[${scaledLength}]`] = {name: ''}
                  setErrors({...errors})
                  setEdited({...edited})
                  editProfile({...profile})
                  setShowOptionalDescription([...showOptionalDescription, false])
                }}>+ add criterion</Button>
            </Grid>
          </Grid>
        </CardContent>

        </Grid>

        <Grid item xs={12}>
          {!editedProfileId && (
            <Button
              color={"secondary"}
              variant={"contained"}
              disabled={saveDisabled}
              onClick={handleCreate}>
                Create
            </Button>
          )}

          {editedProfileId ? (
            <Button
              sx={{ ml: 3}}
              color={"error"}
              variant={"contained"}
              disabled={deleteDisabled}
              onClick={handleDelete}>
                Delete
            </Button>
          ) : null}
        </Grid>
      </Grid>

      
    </React.Fragment>
  )
}
