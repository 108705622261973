import React from 'react';
import {styled} from '@mui/material/styles';

import {
  Card,
  Container,
  Grid,
} from '@mui/material';
import { spacing } from '@mui/system';

export const LandingAbout = () => {
  return (
    <Container>
      <h2>About</h2>
      <Grid container spacing={6} mb={6} style={{minHeight: '402px'}}>
        <Grid item xs={12}>
          <Grid item xs={12} m={2}>
            <p style={{fontSize: '105%'}}>
              <strong>QuantiVision</strong> is a next generation HR tool designed to make your Hiring and Performance management system work.
            </p>
            {/* <p style={{fontSize: '105%'}}>
            Main strengths:
            <ul style={{
              margin: '0.4em 0 0 0.1em',
              borderLeft: '4px solid gray',
              paddingLeft: '1.8em',
              lineHeight: '1.8em'
            }}>
              <li>Making your decision <strong>simpler</strong>, you will clearly see the best option based on its numerical score from your decision model</li>
              <li>Making a <strong>collaborative and transparent</strong> decision with partners/colleagues so you are all on the same page</li>
              <li>Using an effective system you become <strong>better</strong> and <strong>faster</strong> decision maker saving your valuable time and energy</li>
              <li><i>Understand deeper</i> your problem while creating and your own decision model</li>
              <li>Filter out <i>information noise</i> and <i>harmful emotions</i> that could sway you away from your real goals</li>
              <li>You decisions will be <i>stored</i> for you so you can revise them and learn from them</li>
              <li>Making you <i>feel better</i> knowing that you did the best decision at a particular time</li>
            </ul>
            </p> */}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

