import React from 'react';
// import { Helmet } from 'react-helmet-async';

import {Typography} from '@mui/material';

import {RequestAccount} from '../components/RequestAccount';
import {LayoutAuthentication} from './LayoutAuthentication';

export const RequestAccountPage = () => {
  return (
    <LayoutAuthentication>
      {/*<Helmet title='Register' />*/}
      <Typography component='h1' variant='h4' align='center' gutterBottom>
        Get started
      </Typography>
      <Typography component='h2' variant='body1' align='center'>
        Improve your HR processes with a PRO tool.
      </Typography>

      <RequestAccount />
    </LayoutAuthentication>
  );
}

