import React, { createContext, PropsWithChildren, useEffect, useReducer, Reducer, Dispatch } from 'react';
import JSCookie from 'js-cookie'

import { verify, sign, decode, JwtPayload } from "jsonwebtoken";
import { IGlobalState, initialGlobalState, IUser, initialUserAdditionalSettingsState, IUserAdditionalSettingsState, IDepartment, IProfile, ITeam } from '../state';
import { IAction, Action } from '../actions';
import { IRegisterValues } from 'hrFrontend/components/RequestAccount';
import {useLocation, useNavigate} from "react-router-dom";
import { ROUTES } from '../routes';
import { CONSTS } from 'shared/consts';
import { TRole, IRole } from 'shared/types';
import { pullAt } from 'lodash';
import { globalReducer } from '../globalReducer';

export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }
  const decoded = decode(accessToken) as JwtPayload;
  const currentTime = Date.now() / 1000;

  return decoded && decoded.exp ? decoded.exp > currentTime : false;
};


export const getAccessToken = (): string => {
  return JSCookie.get(`${CONSTS.COOKIE.USER_TOKEN}`) || ''
};

export const getAccessTokenObject = (): JwtPayload => {
  const jwt =  getAccessToken()

  return decode(jwt) as JwtPayload;
};


interface IGlobalContext {
  state: IGlobalState;
  dispatch: Dispatch<IAction>;
  requestAccount: (values: any) => Promise<Response>;
  finishUserRegistration: (values: any) => Promise<{ status: number, user: IUser, departments: IDepartment[], teams: ITeam[], profiles: IProfile[], users: IUser[]}>;
  login: (email: string, password: string) => Promise<{ status: number, user: IUser, departments: IDepartment[], teams: ITeam[], profiles: IProfile[], users: IUser[]}>;
  loginFrontend: (user: IUser, departments: IDepartment[], teams: ITeam[], profiles: IProfile[], users: IUser[]) => void;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<Response>;
  updateUser: (values: IUpdatableUserValues) => void;
  hasRole: (roleId: TRole) => boolean;
  getIsLoggedIn: () => boolean;
}

export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);


interface IGlobalProviderProps {
  passedInitialState?: IGlobalState
}

export interface IUpdatableUserValues {
  firstName: string;
  lastName: string;
  // email: string;
  phone?: string;
}

export const GlobalProvider = ({ passedInitialState, children }: PropsWithChildren<IGlobalProviderProps>) => {
  let localState
  const location = useLocation();
  
  if (BUILD_TARGET === 'node') {
    localState = passedInitialState ? passedInitialState : {}
  } else {
    const __INITIAL_GLOBAL_STATE__ = (window as any).__INITIAL_GLOBAL_STATE__
    const localStorageState = localStorage.getItem('global') ? JSON.parse(localStorage.getItem('global') as string) : {}
    localState = __INITIAL_GLOBAL_STATE__ ? {...localStorageState, ...__INITIAL_GLOBAL_STATE__} : localStorageState;
  }

  const [state, dispatch] = useReducer(globalReducer, {...initialGlobalState, ...localState});
  const navigate = useNavigate()
  const {user} = state;

  useEffect(() => {
    const executeLogoutFlow = () => {
      dispatch({ type: Action.LOGOUT });
      redirectToPublicRoutesWhenLogout();
    }
    const initialize = async () => {
      try {
        const accessToken = getAccessToken()

        if (accessToken && isValidToken(accessToken)) {

          const response = await fetch(`/api/v1/users/refresh-token?token=${accessToken}`, {
            headers: {
              token: accessToken
            }
          })

          if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
            return
            // const { user } = await response.json();
            // return loginFrontend(user)
          }
          
          return executeLogoutFlow()
        } else {
          executeLogoutFlow()
        }
      } catch (err) {
        console.error(err);
        executeLogoutFlow()
      }
    };

    initialize();
  }, []);

  const redirectToPublicRoutesWhenLogout = () => {
    if (location.pathname !== ROUTES.LANDING.path &&
      location.pathname !== ROUTES.LOGIN.path &&
      location.pathname !== ROUTES.REQUEST_ACCOUNT.path &&
      location.pathname !== ROUTES.RESET_PASSWORD.path &&
      location.pathname !== ROUTES.ABOUT.path &&
      location.pathname !== ROUTES.VERIFY_ACCOUNT_EMAIL.path &&
      location.pathname !== ROUTES.FINISH_REGISTRATION_EMAIL.path &&
      location.pathname !== ROUTES.CONTACT.path
    ) {
      navigate(ROUTES.LANDING.path)
    }
  }

  const login = async (email: string, password: string): Promise<{ status: number, user: IUser, departments: IDepartment[], teams: ITeam[], profiles: IProfile[], users: IUser[]}>  => {
    const response = await fetch('/api/v1/auth/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
      })
    })

    const { user, departments, teams, profiles, users } = await response.json();

    return { status: response.status, user, departments, teams, profiles, users };
  };

  const finishUserRegistration = async (
    values: IRegisterValues
  ) => {
    const response = await fetch('/api/v1/auth/finish-user-registration', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...values})
    })
    const { user, departments, teams, profiles, users } = await response.json();
    
    return { status: response.status, user, departments, teams, profiles, users };
  };

  const resetPassword = async (email: string) => {
    const response = await fetch(`/api/v1/auth/reset-password?email=${encodeURIComponent(email)}`)
    
    return response
  };

  const loginFrontend = (user: IUser, departments: IDepartment[], teams: ITeam[], profiles: IProfile[], users: IUser[]) => {
    const jwt = getAccessTokenObject();
    console.log('jwt', jwt, jwt.tenantId);
    

    dispatch({
      type: Action.LOGIN,
      payload: {
        user,
        jwt,
        departments,
        teams,
        profiles,
        users
      },
    });
  }

  const logout = async () => {
    try {
      const response = await fetch(`/api/v1/users/logout`)
      dispatch({ type: Action.LOGOUT });
      navigate(ROUTES.RECRUITMENT_DASHBOARD.path)
    } catch (err) {
      console.error(err);
    }
  };

  const requestAccount = async (
    values: IRegisterValues
  ) => {
    const response = await fetch('/api/v1/auth/request-account', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...values})
    })

    if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
      const { token, id } = await response.json();
      dispatch({
        type: Action.REQUEST_ACCOUNT,
        payload: {...values, id},
      });
    }
    
    return response
  };

  const updateUser = async (updatableUserValues: IUpdatableUserValues) => {
    dispatch({
      type: Action.UPDATE_USER,
      payload: updatableUserValues
    });

    fetch(`/api/v1/users/update-user`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...updatableUserValues})
    }).then(() => {
      if (!getAccessToken()) {
        // @ts-ignore
        window.location = '/'
      }
    })
  };

  const hasRole = (roleId: TRole): boolean => {
    return state.role?.roleId === roleId
  }

  const getIsLoggedIn = (): boolean => {
    if (BUILD_TARGET === 'node') {
      return state.isLoggedIn;
    }

    return !!getAccessToken();
  }

  return (
    <GlobalContext.Provider value={{state, dispatch, requestAccount, finishUserRegistration, login, loginFrontend, logout, resetPassword, updateUser, getIsLoggedIn, hasRole}}>
      {children}
    </GlobalContext.Provider>
  );
}
