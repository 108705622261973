import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {v4} from 'uuid'
import * as Yup from 'yup';
import {useParams} from "react-router";
import {styled} from '@mui/material/styles';
import { spacing } from '@mui/system';
import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid, InputLabel, MenuItem, Select, SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useTheme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

import {EAllocationType, EContractType, EVacancyStatus, ICandidate, IInterviewRound, IProfile, IUser, IVacancy, IVacancyInterviewRound, getDefaultProfile} from "../state/state";
import TextEditor from 'sharedFrontend/molecules/TextEditor';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { Action } from 'hrFrontend/state/actions';
import { getDefaultVacancy } from 'hrFrontend/state/state';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { Profile, ProfileModal } from './Profile';
import { candidates, interviewRounds } from 'hrBackend/db/public/schema';
import { XCircle } from 'sharedFrontend/icons/x-circle';
import { pullAt } from 'lodash';
import { Candidate, CandidateModal } from './Candidate';
import { CandidatesVacancyTableSortable } from './CandidatesVacancyTable';
import { getEntityWithDates } from 'hrFrontend/utils/data';
import { getDefaultErrorValues, getFormValidateHandler } from './utils/form';
import { getFormHandleSubmitHandler } from './utils/form';
import { getDefaultEditedValues } from './utils/form';
import { getFormChangeHandler } from './utils/form';


const Alert = styled(MuiAlert)(spacing);
interface IVacancyProps {
  editedVacancyId?: string
}

const vacancyDescriptionExample = ''

/* TODO: 
- test
- descirption
*/

interface IVacancyRequiredValues {
  name: string;
  profileId: string;
}

const defaultValues: IVacancyRequiredValues = {
  name: '',
  profileId: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(255),
  profileId: Yup.string()
    .required('Profile is required')
    .max(255)
})

export const Vacancy = (props: IVacancyProps) => {
  const {editedVacancyId} = props
  const {state, dispatch} = React.useContext(GlobalContext);
  const {departments, teams, interviewRounds, users, candidates, features} = state; 

  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEditedLocal] = useState<any>({...defaultEditedValues})

  const validateValues = useCallback(
    getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])


  const theme = useTheme();
  const navigate = useNavigate()

  let localDefaultVacancy: IVacancy
  if (editedVacancyId) {
    const editedVacancy = state.vacancies.find((v) => v.id === editedVacancyId);
    localDefaultVacancy = editedVacancy || getDefaultVacancy()
  } else {
    localDefaultVacancy = getDefaultVacancy();
  }


  const [vacancy, setVacancy] = useState<IVacancy>({...localDefaultVacancy})

  const setEdited = (edited: any) => {
    setEditedLocal(edited)
    validateValues(vacancy)
  }

  const {id, name, departmentId, teamId, allocationType, contractType, status, profileId, description, createdAt, updatedAt} = vacancy;
  
  const rounds = vacancy.rounds || []; // <- TODO: this is present only on the detail that is not available when fetching just vacancies overview

  const [newRoundName, setNewRoundName]  = useState<string>('')
  const [inputRoundValue, setInputRoundValue]  = useState<string>('')
  const [inputCandidateValue, setInputCandidateValue] = useState<{label: string, id: string, vacancyIds: string[]} | null>(null)

  const [roleProfiles, setRoleProfiles] = useState<IProfile[]>([]);
  const currentProfile: IProfile | undefined = profileId ? roleProfiles.find((r) => r.id === profileId) : undefined
  const [interviewRoundNames, setInterviewRoundNames] = useState<{name: string}[]>([]);

  const [currentCandidateId, setCurrentCandidateId] = useState<string>();

  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)

  const [roundsEditable, setRoundsEditable] = useState(false)

  const setVacancyWrapper = useCallback((v: IVacancy) => {
    setVacancy(v)
    if (editedVacancyId) {
      fetchPromise(`/api/v1/recruitment/vacancies`, 'PATCH', v, 'Error during Vacancy update');
    }
  }, [editedVacancyId])
  
  useEffect(() => {
    if (editedVacancyId) {
      // fetchPromise
      fetch(`/api/v1/recruitment/vacancies/${editedVacancyId}`, {
        method: 'GET',
      }).then(async (response) => {
        const { vacancy } = await response.json();
        dispatch({
          type: Action.UPSERT_VACANCY,
          payload: vacancy
        })
        setVacancy(getEntityWithDates(vacancy));
      }).catch((err) => {console.error('Error fetching vacancy', err)})
    }
    fetch(`/api/v1/recruitment/candidates`, {
      method: 'GET',
    }).then(async (response) => {
      const { candidates } = await response.json();
      dispatch({
        type: Action.UPSERT_CANDIDATES,
        payload: candidates
      })
    }).catch((err) => {console.error('Error fetching candidates in vacancy', err)})

    fetch(`/api/v1/org/profiles`, {
      method: 'GET',
    }).then(async (response) => {
      const { profiles } = await response.json();
      setRoleProfiles(profiles)
    }).catch((err) => {console.error('Error fetching porfiles in vacancy', err)})

    fetch(`/api/v1/recruitment/interview-round-names`, {
      method: 'GET',
    }).then(async (response) => {
      const res = await response.json();

      setInterviewRoundNames(res.interviewRoundNames);
    }).catch((err) => {console.error('Error fetching interview rounds in vacancy', err)})
  }, [])

  const onVacancyAllocationTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVacancyWrapper({...vacancy, allocationType: Number(e.target.value)})
  }

  const onVacancyOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVacancyWrapper({...vacancy, status: Number(e.target.value)})
  }

  const onVacancyContractChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVacancyWrapper({...vacancy, contractType: Number(e.target.value)})
  }

  const [modalCurrentProfileOpen, setModalCurrentProfileOpen] = useState<boolean>(false)
  const [modalCurrentCandidateOpen, setModalCurrentCandidateOpen] = useState<boolean>(false)


  let allAssesorIds: string[] = [] as string[]
  rounds.forEach((round) => {
    allAssesorIds = [...allAssesorIds, ...round.assesorIds]
  })
  const allPossibleAssesors = users // .filter((u) => allAssesorIds.indexOf(u.id) === -1)

  let allRoundNames: string[] = [] as string[]
  rounds.forEach(({name}) => {
    allRoundNames = [...allRoundNames, name]
  })
  const allPossibleRounds = interviewRoundNames
    .filter((ir) => allRoundNames.indexOf(ir.name) === -1)
    .map((ir) => ir.name)


  const handleCreate = useCallback(
    getFormHandleSubmitHandler(vacancy, validateValues, setEdited, async () => {
      setSaveDisabled(true)

      dispatch({
        type: Action.CREATE_VACANCY,
        payload: {
          vacancy
        }
      })
      const res = await fetchPromise(`/api/v1/recruitment/vacancies`, 'POST', {...vacancy, candidates: candidates.filter((a) => a.vacancyIds.indexOf(id) !== -1)}, 'Error during Vacancy creation')
      navigate(-1)
    })
    , [vacancy, validateValues, setEdited])
    
  const handleNameChange = useCallback(
    getFormChangeHandler('name', vacancy, setVacancyWrapper, validateValues),
    [vacancy, setVacancyWrapper, validateValues]
  )

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        {/* <CardHeader
          title="Vacancy Details"
          // subheader="The information can be edited"
        />
        <Divider /> */}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={9} xs={12}>
              <Grid item mb={4}>
                <TextField
                  fullWidth
                  size='medium'
                  label="Job title"
                  error={Boolean(edited.name && errors.name)}
                  helperText={edited.name && errors.name}
                  onBlur={() => {setEdited({...edited, name: true})}}
                  name="vacancyName"
                  onChange={handleNameChange}
                  required
                  // focused={true}
                  value={name}
                  variant='outlined'
                  // variant='standard'
                />
              </Grid>
              <Grid item mb={4}>
                <Grid container>
                
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth size='medium'>
                      <InputLabel id="roleProfileLabel">Select Role Profile</InputLabel>
                      <Select
                        labelId="roleProfileLabel"
                        id="roleProfileId"
                        value={profileId ? profileId : ''}
                        error={Boolean(edited.profileId && errors.profileId)}
                        onBlur={() => {setEdited({...edited, profileId: true})}}
                        label="Select Role Profile"
                        onChange={(e: SelectChangeEvent) => {
                          setVacancyWrapper({
                            ...vacancy,
                            profileId: e.target.value as string
                          })
                        }}
                      >
                        {/* <MenuItem key={`roleProfileNull`} value={''}></MenuItem> */}
                        {roleProfiles
                          .filter((p) => departmentId ? p.departmentId === null || p.departmentId === departmentId : true)
                          .sort((a, b) => departmentId ? (
                              a.departmentId === departmentId && b.departmentId === departmentId ? 0 : (
                                a.departmentId === departmentId && b.departmentId !== departmentId ? -1 : (
                                  a.departmentId !== departmentId && b.departmentId === departmentId ? 1 : 0
                                )
                              )
                            ) : (
                              a.departmentId === null && b.departmentId === null ? 0 : (
                                a.departmentId === null && b.departmentId !== null ? -1 : (
                                  a.departmentId !== null && b.departmentId === departmentId ? 1 : 0
                                )
                              )
                            )
                          )
                          .map((profile, i) => {
                          return (
                            <MenuItem key={`roleProfile${i}`} value={profile.id}>{profile.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid container md={12} justifyContent={'center'}>
                      <Button
                        style={{margin: '12px 16px 10px 0'}}
                        color={"secondary"}
                        variant={"outlined"}
                        size='medium'
                        onClick={() => {
                          setModalCurrentProfileOpen(true)
                        }}>
                          + New Role Profile
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item mb={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography sx={{ mb: 1 }} variant="h4">Interview Rounds
                    {/* TODO: do this later
                     <small style={{cursor: 'pointer'}} onClick={() => {
                      setRoundsEditable(!roundsEditable)
                    }}>edit</small> */}
                    </Typography>
                  </Grid>
                  {rounds.map((round, index) => {
                    const {id, name, assesorIds} = round;
                    return (
                      <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {index+1}. {name} {roundsEditable && (
                          <XCircle fontSize="small" color="error" style={{cursor: 'pointer', verticalAlign: 'text-bottom'}} onClick={() => {
                            pullAt(rounds, [index])
                            setVacancy({
                              ...vacancy,
                              rounds
                            })
                          }} />)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                          <Autocomplete
                            sx={{ mt: 2}}
                            multiple
                            size='medium'
                            id="add-assesors"
                            options={allPossibleAssesors.filter((u) => assesorIds.indexOf(u.id) === -1).map((user) => {
                              return {
                                id: user.id,
                                label: `${user.firstName} ${user.lastName}`
                              }
                            })}
                            value={users.filter((u) => assesorIds.indexOf(u.id) !== -1).map((user) => {
                              return {
                                id: user.id,
                                label: `${user.firstName} ${user.lastName}`
                              }
                            })}
                            onChange={(e, newValue, reason, details) => {
                              console.log('UUU', users.filter((u) => assesorIds.indexOf(u.id) !== -1));
                              console.log('Change ASS', newValue, reason, details);
                              
                              rounds[index].assesorIds = newValue.map((a) => a.id)
                              setVacancy({
                                ...vacancy,
                                rounds
                              })

                              if (editedVacancyId) {
                                const assPayload = {vacancyId: vacancy.id, interviewRoundId: id, userId: details?.option.id};
                                if (reason === 'selectOption') {
                                  fetchPromise(`/api/v1/recruitment/assesors`, 'POST', assPayload, 'Error during Vacancy>Assesors update');
                                } else if (reason === 'removeOption') {
                                  fetchPromise(`/api/v1/recruitment/assesors`, 'DELETE', assPayload, 'Error during Vacancy>Assesors delete');
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Add Assessor"
                                placeholder="Add Assessor"
                              />
                            )}
                          />
                            {/* <Autocomplete
                              disablePortal
                              id="add-assesor"
                              inputValue={inputRoundAssesorName[index]}
                              onInputChange={(event, newInputValue) => {
                                inputRoundAssesorName[index] = newInputValue
                                setInputRoundAssesorName([...inputRoundAssesorName]);
                              }}
                              value={assesors[index]}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  rounds[index].assesorIds.push(newValue.id)
                                  setRounds([
                                    ...rounds
                                  ])

                                  inputRoundAssesorName[index] = ''
                                  setInputRoundAssesorName([...inputRoundAssesorName]);
                                }
                              }}
                              options={users.map((user) => {
                                return {
                                  id: user.id,
                                  label: `${user.firstName} ${user.lastName}`
                                }
                              })}
                              sx={{ width: 300 }}
                              size='medium'
                              renderInput={(params) => <TextField {...params} label="Add Assessor" />}
                            /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      </>    
                    )
                  })}
                  {roundsEditable && (
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="add-interview-round"
                      freeSolo
                      sx={{mt:2}}
                      size='medium'
                      blurOnSelect
                      inputValue={inputRoundValue}
                      onInputChange={(event, newInputValue) => {
                        setInputRoundValue(newInputValue);
                      }}
                      value={newRoundName}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const now = new Date()
                          rounds.push({
                            id: v4(),
                            name: newValue || '',
                            index: rounds.length,
                            assesorIds: [],
                            createdAt: now,
                            updatedAt: now,
                          })
                          setVacancy({
                            ...vacancy,
                            rounds
                          })
                          setInputRoundValue('')
                        }
                      }}
                      options={allPossibleRounds}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderInput={(params) => <TextField {...params} label="New Interview Round" />}
                    />
                </Grid>
                )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid item xs={12}>
                    <Typography sx={{ mb: 3, mt: 2 }} variant="h4">Candidates</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CandidatesVacancyTableSortable
                      tableVacancyId={id}
                      onOpenCallback={(aId: string) => {
                        setCurrentCandidateId(aId)
                        setModalCurrentCandidateOpen(true)
                      }}
                      onDeleteCallback={(candidateId: string) => {
                      const candidatePayload = {vacancyId: vacancy.id, candidateId};
                       if (editedVacancyId) {
                        fetchPromise(`/api/v1/recruitment/vacancy-candidates`, 'DELETE', candidatePayload, 'Error during Vacancy>Candidates delete');
                      }
                    }} />
                  </Grid>
                  <Grid container>
                    <Grid item md={6} xs={12}>
                    <Autocomplete
                      sx={{ mt: 2}}
                      size='medium'
                      id="select-add-candidate"
                      options={candidates.filter((a) => a.vacancyIds.indexOf(vacancy.id) === -1).map((a) => { return {label: `${a.firstName} ${a.lastName}`, id: a.id, vacancyIds: a.vacancyIds}})} //  vacancyId: a.vacancyId
                      value={inputCandidateValue}
                      blurOnSelect
                      onChange={(e, newValue, reason, details) => {
                        if (newValue) {
                          dispatch({
                            type: Action.UPDATE_CANDIDATE,
                            payload: {
                              id: newValue.id,
                              vacancyIds: [...newValue.vacancyIds, id]
                            }
                          })
                          setInputCandidateValue(null)

                          if (editedVacancyId) {
                            const candidatePayload = {vacancyId: vacancy.id, candidateId: details?.option.id};
                            if (reason === 'selectOption') {
                              fetchPromise(`/api/v1/recruitment/vacancy-candidates`, 'POST', candidatePayload, 'Error during Vacancy>Candidates update');
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select to Add Candidate"
                          placeholder="Select to Add Candidate"
                        />
                      )}
                    />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container md={12} justifyContent={'center'}>
                        <Button
                          sx={{ml: 2}}
                          color={"secondary"}
                          variant={"outlined"}
                          style={{margin: '12px 16px 10px 0'}}
                          size='medium'
                          onClick={() => {
                            setCurrentCandidateId(undefined)
                            setModalCurrentCandidateOpen(true)
                          }}>
                            + Create a New Candidate
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={3} xs={5}>
            <Grid item mb={4}>
                <FormControl fullWidth size='medium'>
                  <InputLabel id="departmentLabel">Select Department</InputLabel>
                  <Select
                    labelId="departmentLabel"
                    id="departmentId"
                    value={departmentId ? departmentId : ''}
                    label="Select Department"
                    onChange={(e: SelectChangeEvent) => {
                      setVacancyWrapper({
                        ...vacancy,
                        departmentId: e.target.value ? e.target.value : null,
                        teamId: null,
                        profileId: currentProfile ? (
                          !!currentProfile.departmentId ? (
                            !e.target.value ? currentProfile.id : (
                                currentProfile.departmentId === e.target.value ? currentProfile.id : null
                              )
                          ) : (
                            currentProfile.id
                          )
                        ) : null
                      })
                    }}
                  >
                    <MenuItem key={`department-null`} value={''}>--- No Department ---</MenuItem>
                    {departments.map((profile, i) => {
                      return (
                        <MenuItem key={`department${i}`} value={profile.id}>{profile.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item mb={4}>
                <FormControl fullWidth size='medium'>
                  <InputLabel id="teamLabel">Select Team</InputLabel>
                  <Select
                    labelId="teamLabel"
                    id="teamId"
                    value={teamId ? teamId : ''}
                    label="Select Team"
                    onChange={(e: SelectChangeEvent) => {
                      setVacancyWrapper({
                        ...vacancy,
                        teamId: e.target.value as string
                      })
                    }}
                  >
                    {/* <MenuItem key={`teamNull`} value={''}></MenuItem> */}
                    {teams.filter((t) => departmentId ? t.departmentId === departmentId : true).map((team, i) => {
                      return (
                        <MenuItem key={`team${i}`} value={team.id}>{team.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {features.indexOf('ats') !== -1 && (
                <>
                  <Grid item mb={4}>
                    <TextField
                      fullWidth
                      size='medium'
                      label="Allocation Type"
                      name="type"
                      onChange={onVacancyAllocationTypeChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={allocationType}
                      variant="outlined"
                    >
                      <option key={EAllocationType.FULL_TIME} value={EAllocationType.FULL_TIME}>Full-time</option>
                      <option key={EAllocationType.HALF_TIME} value={EAllocationType.HALF_TIME}>Half-time</option>
                      <option key={EAllocationType.HOURLY_RATE} value={EAllocationType.HOURLY_RATE}>Hourly-rate</option>
                    </TextField>
                  </Grid>
                  <Grid item mb={4}>
                    <TextField
                      fullWidth
                      size='medium'
                      label="Contract Type"
                      name="open"
                      onChange={onVacancyContractChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={contractType}
                      variant="outlined"
                    >
                      <option key={EContractType.EMPLOYEE} value={EContractType.EMPLOYEE}>Employee</option>
                      <option key={EContractType.CONTRACTOR} value={EContractType.CONTRACTOR}>Contractor</option>
                    </TextField>
                  </Grid>
                </>
              )}
              
              {/* <Grid item mb={4}>
                <TextField
                  fullWidth
                  label="Open"
                  name="open"
                  onChange={onVacancyOpenChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={status}
                  variant="outlined"
                >
                  <option key={EVacancyStatus.OPEN} value={EVacancyStatus.OPEN}>Yes</option>
                  <option key={EVacancyStatus.CLOSED} value={EVacancyStatus.CLOSED}>No</option>
                </TextField>
              </Grid> */}
              <Grid item mb={4}>
              </Grid>
              {editedVacancyId && (
                <>
                  <Grid item mb={4}>Created At: <strong>{createdAt.toLocaleDateString('en-US')}</strong></Grid>
                  <Grid item mb={4}>Updated At: <strong>{updatedAt.toLocaleDateString('en-US')}</strong></Grid>
                </>
              )}
            </Grid>
            {/* <Grid item lg={12} md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <TextEditor defaultValue={description} theme={theme} onTextChange={(e) => {
                  setVacancy({...vacancy, description: e.target.value})
                }} />
              </Grid>
            </Grid> */}
          </Grid>
        </CardContent>
        {/* <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography sx={{ mb: 3 }} variant="h6">Interview Stages</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth size='medium'>
                <InputLabel id="interviewStagesLabel">Select Interview Stages</InputLabel>
                <Select
                  labelId="interviewStagesLabel"
                  id="interviewStagesBoardId"
                  value={interviewStagesBoardId}
                  label="Select Interview Stages"
                  onChange={(e: SelectChangeEvent) => {
                    setVacancy({
                      ...vacancy,
                      interviewStagesBoardId: e.target.value as string
                    })
                  }}
                >
                  <MenuItem key={`interviewStagesNull`} value={''}></MenuItem>
                  {currentInterviewStages.map((stages, i) => {
                    return (
                      <MenuItem  key={`candidateBoardStages${i}`} value={stages.id}>{stages.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

            </Grid>
          </Grid>
        </CardContent> */}

        <Divider />
        {errors.submit && (
          <Alert mt={2} mb={3} severity="warning">
            {errors.submit}
          </Alert>
        )}
        <Box sx={{display: 'flex', /*justifyContent: 'flex-end',*/ p: 4}}>
          {!editedVacancyId && (
          <Button color="secondary" variant="contained" onClick={handleCreate}>Create</Button>
          )}
          {editedVacancyId ? (
          <Button color="error" variant="contained" style={{marginLeft: '21px'}} onClick={async (e) => {
            setDeleteDisabled(true)
            dispatch({
              type: Action.DELETE_VACANCY,
              payload: vacancy
            })
            await fetchPromise(`/api/v1/recruitment/vacancies`, 'DELETE', {id: id}, 'Error during Vacancy deletion')
            navigate(-1)
          }}>Delete</Button>) : null}
        </Box>

        <ProfileModal modalOpen={modalCurrentProfileOpen} setModalOpen={setModalCurrentProfileOpen}>
          <Profile
            departmentId={departmentId}
            setModalOpen={setModalCurrentProfileOpen}
            onSaveCallback={(profile: IProfile) => {
              setRoleProfiles([...roleProfiles, profile])
              setVacancyWrapper({
                ...vacancy,
                profileId: profile.id
              })
            }}
            />
        </ProfileModal>

        <CandidateModal modalOpen={modalCurrentCandidateOpen} setModalOpen={setModalCurrentCandidateOpen}>
          <Candidate
            vacancyId={vacancy.id}
            setModalOpen={setModalCurrentCandidateOpen}
            editedCandidateId={currentCandidateId}
            frontendOnly={!editedVacancyId ? true : false}
            />
        </CandidateModal>
      </Card>
    </form>
  )
}
