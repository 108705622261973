import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Chip, Grid, TableCell, TableRow, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {ITableSortableColumn, TableSortable} from "sharedFrontend/organisms/TableSortable";

import {ROUTES} from "../state/routes";
import {DecisionType, IDepartment, IProfilesMap, ITeam, ICandidate, IVacancy} from "../state/state";

import {v4} from "uuid";
import {ContentCopy, Edit as MuiEdit, Group} from "@mui/icons-material";
import {Action} from '../state/actions';
import {Download, Edit3, Edit, List, PlusCircle, Trash2, XCircle} from 'react-feather';
import theme from '../theme';
import {styled} from '@mui/material/styles';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { TRole } from 'shared/types';
import _ from 'lodash';

interface IExpandState {
  show: boolean;
  force: boolean;
}

const RemoveButton = styled(XCircle)`
  color: ${(props) => props.theme.palette.error.main};
  fontSize: small;
`

const DuplicateButton = styled(ContentCopy)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const EditButton = styled(Edit)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const ListButton = styled(List)`
  color: ${(props) => props.theme.palette.secondary.main};
`

interface ICandidatesTable {
  // candidates?: ICandidate[];
  // tableVacancyId: string;
  candidates: ICandidate[],
  vacancies: IVacancy[],
  onOpenCallback: (candidateId: string) => void;
  onDeleteCallback?: (candidateId: string) => void;
  // removeVacancy: (id: string) => void;
  // duplicateDecision: (id: string) => void;
  // departments: IDepartment[];
  // teams: ITeam[];
  // profilesMap: IProfilesMap;
  // hasRole: (roleId: TRole) => boolean;
}


export const CandidatesTable = (props: ICandidatesTable) => {
  const {candidates, vacancies, onDeleteCallback, onOpenCallback} = props;
  const navigate = useNavigate();


  const columns: ITableSortableColumn[] = [
    {id: 'name', label: 'Name', sortable: true, align: 'left'},
    {id: 'vacancy', label: 'Vacancy', sortable: true, align: 'center'},
    {id: 'stage', label: 'Stage', sortable: true, align: 'center'},
    // {id: 'department', label: 'Department / Team', align: 'center'},
    // {id: 'evaluations', label: 'Evaluations', sortable: false, align: 'center'},
    {id: 'updatedAt', label: 'Updated', sortable: true, align: 'center'},
    // {id: 'edit', label: 'Edit', align: 'center'},
    {id: 'remove', label: 'Remove', align: 'center'},
  ]

  const emptyTableRow = (
    <TableRow
      key={'emptyTableRow'}
      hover
      tabIndex={-1}
    >
      <TableCell align="center" colSpan={5}>
        Empty
      </TableCell>
    </TableRow>
  )
  
  console.log('APPL', candidates);
  
  // TODO: useMemo here does not work for some reason
  const rowsData = candidates.map((candidate, index) => {
    const {id, firstName, lastName, updatedAt, vacancyIds} = candidate;
    
    const name = `${firstName} ${lastName}`
    const vacancyName = vacancies.find((v) => v.id === vacancyIds[0])?.name || '';
    return {
      id: {
        value: id
      },
      name: {
        value: name,
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            onClick={() => {onOpenCallback(id)}}
          >
            <span>{name}</span>
          </Grid>
        )
      },
      vacancy: {
        value: vacancyName,
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            // justifyContent="space-between"
          >
            {vacancyIds.map((vacancyId) => {
              return (
                <Chip
                  style={{cursor: 'pointer'}}
                  onClick={() => navigate(ROUTES.VACANCY_EDIT.getPath(vacancyId))}
                  key={`index-${vacancyName}`}
                  label={vacancies.find((v) => v.id === vacancyId)?.name}
                  variant={'outlined'}
                  sx={{mr:1 ,mb: 1, mt: 1}}
                  />
              )
            })}
          </Grid>
        )
      },
      stage: {
        value: '-',
        width: '5%',
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
          >
            <span>-</span>
          </Grid>
        )
      },
      updatedAt: {
        width: '8%',
        value: new Date(updatedAt),
        jsx: (
          <span>
            {new Date(updatedAt).toLocaleDateString('en-US')}
          </span>
        )
      },
      // edit: {
      //   value: id,
      //   width: '8%',
      //   jsx: (
      //     <EditButton
      //       style={{ cursor: 'pointer'}}
      //       onClick={() => {
      //         // navigate(ROUTES.CANDIDATE.getPath(id))
      //       }} />
      //   )
      // },
      remove: {
        width: '8%',
        jsx: (
          <RemoveButton
            style={{ cursor: 'pointer'}}
            onClick={() => {
              if (onDeleteCallback) {
                onDeleteCallback(id)
              }
            }} />
        )
      },
    }
  })

  console.log('rowsData', rowsData);
  

  return (
    <TableSortable columns={columns} rowsData={rowsData} defaultOrderBy={'updatedAt'} emptyTableRow={emptyTableRow} />
  )
}

