import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {v4} from 'uuid'
import {pullAt} from 'lodash'

import {CriterionTypeEnum, IDecisionSettings, defaultCriterionNegative, defaultCriterionPositive} from "../../state/state";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, IconButton,
  Input, MenuItem,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { XCircle as XCircleIcon } from 'sharedFrontend/icons/x-circle';
import {ContentCopy, Delete, ExpandMore, FilterList, FormatListBulleted, Sort, Tab, UnfoldMore} from "@mui/icons-material";
import {MoreHorizontal} from 'react-feather';
import {PopupMenu} from 'sharedFrontend/navigation/PopupMenu';
import CustomTabs from 'sharedFrontend/molecules/Tabs';
import { styled } from '@mui/system';
import { SliderHollow } from './EvaluationCandidate';
import { MenuButton } from 'sharedFrontend/molecules/MenuButton';
import { IProfile } from 'hrFrontend/state/state';
import { sliderValueTextFn } from '../utils/utils-decision';

// const sliderWeightLabels = [
//   {
//     value: 10,
//     label: 'Negligible',
//   },

//   {
//     value: 50,
//     label: 'Important',
//   },

//   {
//     value: 90,
//     label: 'Essential',
//   }
// ];

const sliderWeightLabels = [
  {
    value: 50,
    label: 'Importantance',
  }
];

// const defaultHowToEvaluateGuide: IHowToEvaluateGuide = {
//   '25': '',
//   '50': '',
//   '75': '',
//   '100': ''
// }

export const ModelTextField = styled(TextField)`
  input {
    font-size: 1.2em;
    font-weight: 500;
  }
`;

export const SliderAdjacentTextField = styled(TextField)`
  margin: 15px 10px 8px 10px;

  input {
    padding: 8px 9px;
  }
`;

export const SliderAdjacentTextFieldNegative = styled(TextField)`
  margin: 15px 10px 8px 10px;

  input {
    padding: 8px 7px;
  }
`;

// const howToEvaluateArr: string[] = []
// for (const key in defaultHowToEvaluateGuide) {
//   howToEvaluateArr.push(key)
// }

const getCriterionValueFormatter = (type?: CriterionTypeEnum) => {
  switch (type) {
    case CriterionTypeEnum.Positive:
      return (value: number) => value
      break;
    case CriterionTypeEnum.Negative:
      return (value: number) => value * -1
      break;
    default:
      return (value: number) => value
      break;

  }
}

const defaultEvaluationProfileTemplate = {
  id: '',
  name: '',
  criteria: []
}

export const weightScoreFormatter = (val: number) => {
  // return val / 10
  return val
}

interface IEvaluationProfileProps {
  profile: IProfile;
  setProfile: (profile: IProfile) => void;
  setModelAsTabs: (asTabs: boolean) => void;
  enableMinimalPerformance: boolean;
  max: number;
  modelAsTabs?: boolean;
}

export const EvaluationProfile = (props: IEvaluationProfileProps) => {
  const {profile, setProfile, modelAsTabs, setModelAsTabs, enableMinimalPerformance, max} = props;

  const [showOptionalDescription, setShowOptionalDescription] = useState<boolean[]>([...profile.criteria.scaledCriteria.map(() => false)]);

  const scaledCriteria = profile.criteria.scaledCriteria;
  const mustHaveCriteria = profile.criteria.mustHaveCriteria;

  const [anchorElements, setAnchorElements] = React.useState<null[] | HTMLElement[]>([...profile.criteria.scaledCriteria.map(() => null)]);
  const open = anchorElements.map((e) => !!e);
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    anchorElements[index] = event.currentTarget
    //@ts-ignore
    setAnchorElements([...anchorElements]);
  };
  const handleClose = (index: number) => {
    anchorElements[index] = null
    //@ts-ignore
    setAnchorElements([...anchorElements]);
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  // const model = criteria.sort((a, b) => b.weight - a.weight).

  const sortByWeight = () => {
    setProfile({
      ...profile,
      criteria: {
        mustHaveCriteria,
        scaledCriteria: scaledCriteria.sort((a, b) => b.weight - a.weight),
      }
    })
  }

  const model = scaledCriteria.map(({id, name, weight, minPerformance, whatDescription, whyDescription/*, evaluationGuideMap*/}, index) => {

    const SliderAdjacentInput = scaledCriteria[index].type === CriterionTypeEnum.Negative ? SliderAdjacentTextFieldNegative : SliderAdjacentTextField;
    return {
      name,
      component: (
        <React.Fragment key={id}>
          {/* <CardContent style={{paddingBottom: '12px'}}> */}
          <Grid container spacing={0} sx={{mb: 2}}>
            <Grid container spacing={0} sx={{mt: 4, mb: 4, ml: 1, mr: 1}}>
              <Grid item md={11} xs={11}>
                <Typography variant="h6">{name}</Typography>
              </Grid>
              <Grid item md={1} xs={1}>
                <IconButton
                  aria-label="more"
                  style={{ marginTop: '0px', padding: '0px' /* remove when placeholder */}}
                  id="more-button"
                  aria-controls={open[index] ? 'more-menu' : undefined}
                  aria-expanded={open[index] ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    handleClick(e, index)
                  }}
                >
                  <MoreHorizontal/>
                </IconButton>

                <PopupMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorElements[index]}
                  open={open[index]}
                  onClose={() => {
                    handleClose(index)
                  }}
                >
                  <MenuItem onClick={() => {
                    showOptionalDescription[index] = !showOptionalDescription[index]
                    setShowOptionalDescription([...showOptionalDescription])
                    handleClose(index)
                  }}>
                    <UnfoldMore/>
                    Show Descriptions
                  </MenuItem>
                  {/* <Divider sx={{my: 0.5}}/>
                  <MenuItem onClick={() => {
                    if (confirm('Are you sure to delete this criteria?')) {
                      if (modelAsTabs) {
                        if (tabIndex === scaledCriteria.length - 1) {
                          setTabIndex(tabIndex - 1)
                        }
                      }
                      pullAt(scaledCriteria, [index])
                      setProfile({...profile})

                    }
                    handleClose(index)
                  }}>
                    <Delete/>
                    Delete Criterion
                  </MenuItem> */}
                </PopupMenu>
              </Grid>
              {showOptionalDescription[index] && (
                <Grid item md={12} xs={24}>
                  <TextField
                    fullWidth
                    label={`${name ? `Describe "${name}"` : 'Description'}`}
                    name={`whatDescription${index}`}
                    onChange={(e) => {
                      scaledCriteria[index].whatDescription = e.target.value
                      setProfile({...profile})
                    }}
                    value={whatDescription}
                    disabled={true}
                    variant="standard"
                    size={'small'}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={10} xs={10} pt={1}>
                <Typography id="input-slider" gutterBottom variant="subtitle2" mb={0} ml={1} style={{fontSize: '1.05em'}}>
                  Weight
                </Typography>
                <SliderHollow
                  min={0}
                  max={max}
                  // size='small'
                  defaultValue={0}
                  color={'secondary'}
                  aria-labelledby={`perf-${index}`}
                  valueLabelDisplay='auto'
                  // marks={sliderWeightLabels}
                  // scale={getCriterionValueFormatter(scaledCriteria[index].type)}
                  getAriaValueText={sliderValueTextFn}
                  value={weight}
                  // ------not editable --->
                  step={null} 
                  marks={[{value: weight}]}
                  // <-----not editable ----
                  onChange={(e, newValue) => {
                    const numberInput = Number(newValue)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }
                    scaledCriteria[index].weight = numberInput
                    setProfile({...profile})
                  }}
                />
              </Grid>
              <Grid item md={2} xs={2} sx={{pb: 1, pt: 0, pl: 2, pr: 2}}>
                <SliderAdjacentInput
                  fullWidth
                  variant='standard'
                  name='weightInput'
                  value={getCriterionValueFormatter(scaledCriteria[index].type)(weight)}
                  size={'small'}
                  onChange={(event) => {
                    console.log('event.target.value', event.target.value, Number(event.target.value));
                    const currentCriteria = scaledCriteria[index]
                    let numberInput = Number(event.target.value)

                    if (currentCriteria.type === CriterionTypeEnum.Negative) {
                      if (event.target.value === '-') {
                        numberInput = 0
                      }

                      if (numberInput > 0) {
                        numberInput = numberInput * -1;
                      }

                      if (numberInput < -max) {
                        numberInput = -max;
                      }
                    } else {
                      if (numberInput < 0) {
                        numberInput = 0;
                      } else if (numberInput > max) {
                        numberInput = max;
                      }
                    }

                    if (Number.isNaN(numberInput)) {
                      event.preventDefault();
                      return false;
                    }

                    numberInput = getCriterionValueFormatter(currentCriteria.type)(numberInput)
                    currentCriteria.weight = numberInput
                    setProfile({...profile})
                  }}
                />
              </Grid>
              {/* <Grid item md={12} xs={24} style={{paddingTop: '8px'}}>
                <span style={{fontSize: '95%'}}>Importance: {getImportance(weight, importanceScale?)}</span>
              </Grid> */}
              {showOptionalDescription[index] && (
                <Grid item md={12} xs={24}>
                  <TextField
                    fullWidth
                    label={`${`Why ${weight ? weight : '0'}/100 weight?`}`}
                    name={`whyDescription${index}`}
                    onChange={(e) => {
                      scaledCriteria[index].whyDescription = e.target.value
                      setProfile({...profile})
                    }}
                    required
                    value={whyDescription}
                    disabled={true}
                    variant="standard"
                    size={'small'}
                  />
                </Grid>
              )}
            </Grid>

            {/*<Grid item md={5} xs={10} container justifyContent="flex-end">*/}
            {/*  <Typography sx={{ mb: 3, textAlign: 'right', mr: 3, pt: '26px' }} variant="subtitle2">Evaluation Guide:</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item md={7} xs={14} container>*/}
            {/*    {howToEvaluateArr.map((keyPercentage) => {*/}
            {/*      return (<>*/}
            {/*        <Grid item md={1} xs={2} style={{paddingTop: '27px'}}>{keyPercentage} %</Grid>*/}
            {/*        <Grid item md={11} xs={22} style={{marginTop: '9px', marginBottom: '12px'}}>*/}
            {/*          <TextField*/}
            {/*            fullWidth*/}
            {/*            label={`What does ${keyPercentage} % mean?`}*/}
            {/*            name={`evaluateGuide${keyPercentage}`}*/}
            {/*            onChange={(e) => {*/}
            {/*              if (!scaledCriteria[index].evaluationGuideMap) {*/}
            {/*                scaledCriteria[index].evaluationGuideMap = {...defaultHowToEvaluateGuide}*/}
            {/*              }*/}
            {/*              // @ts-ignore*/}
            {/*              scaledCriteria[index].evaluationGuideMap![`${keyPercentage}`] = e.target.value*/}
            {/*              setProfile({...profile})*/}
            {/*              /*@ts-ignore*!/}*/}
            {/*            value={evaluationGuideMap ? evaluationGuideMap[`${keyPercentage}`] : ''}*/}
            {/*            disabled={true} */}
            {/*            variant="standard"*/}
            {/*            size={'small'}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      </>)*/}

            {enableMinimalPerformance && scaledCriteria[index].type !== CriterionTypeEnum.Negative ? (
            <Grid container spacing={1}>
              <Grid item md={10} xs={10} style={{paddingTop: '8px'}}>
                <Typography id="input-slider" gutterBottom variant="subtitle2" mb={0} ml={1} style={{fontSize: '1.05em'}}>
                  Minimal Performance
                </Typography>
                <SliderHollow
                  min={0}
                  max={max}
                  // size='small'
                  defaultValue={0/* @ts-ignore */}
                  color={'error'}
                  aria-labelledby={`min-perf-${index}`}
                  valueLabelDisplay='auto'
                  // marks={sliderPerformanceLabels}
                  getAriaValueText={sliderValueTextFn}
                  value={minPerformance}
                  // ------not editable --->
                  step={null} 
                  marks={[{value: minPerformance}]}
                  // <-----not editable ----
                  onChange={(e, newValue) => {
                    const numberInput = Number(newValue)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }
                    scaledCriteria[index].minPerformance = numberInput
                    setProfile({...profile})
                  }}
                />
              </Grid>
              <Grid item md={2} xs={2} sx={{pb: 1, pt: 0, pl: 2, pr: 2}}>
                <SliderAdjacentTextField
                  fullWidth
                  variant='standard'
                  value={weightScoreFormatter(minPerformance)}
                  size='small'
                  onChange={(event) => {
                    let numberInput = Number(event.target.value)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }

                    if (numberInput < 0) {
                      numberInput = 0;
                    } else if (numberInput > max) {
                      numberInput = max;
                    }

                    scaledCriteria[index].minPerformance = numberInput
                    setProfile({...profile})
                  }}
                />
              </Grid>
            </Grid>
            ) : null}
          {/* </CardContent> */}
          </Grid>
        </React.Fragment>
      )
    }
  })

  return (
    <form autoComplete="off" noValidate>
      <>{model.map((m, index) => {
        return (
          <React.Fragment key={`list-item-${index}`}>
            {index === 0 ? null : (<Divider />)}
            {m.component}
          </React.Fragment>
        )
      })}</>
    </form>
  )
}
