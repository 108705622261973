import React from 'react';
// import { Helmet } from 'react-helmet-async';
import {Typography } from '@mui/material';

import { LayoutLanding } from './LayoutLanding';
import { LandingTopbar } from './Landing/LaningTopbar';
import { LandingAbout } from './Landing/LandingAbout';
import { LandingFooter } from './Landing/LandingFooter';


export const AboutPage = () => {
  return (
    <LayoutLanding>
      {/*<Helmet title='QuantiVision' />*/}
      <LandingTopbar />
      <LandingAbout />
      <LandingFooter />
    </LayoutLanding>
  );
}
