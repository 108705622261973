import React, {PropsWithChildren, useEffect, useState} from 'react'

import {styled} from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

import {Target} from 'react-feather';

const Brand = styled(Target)`
  //fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

import {LayoutAuthenticationPublic} from 'sharedFrontend/layouts/LayoutAuthenticationPublic';

export const LayoutAuthentication = ({children}: PropsWithChildren<any>) => {
  return (
    <LayoutAuthenticationPublic>
      <Brand />
      <Wrapper>
        {children}
      </Wrapper>
    </LayoutAuthenticationPublic>
  )
}
