import React, { useState } from "react";
import {styled} from "@mui/material/styles";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
import { green } from "@mui/material/colors";

import {
  Box as MuiBox,
  Chip,
  Grid,
  Drawer as MuiDrawer,
  ListItemButton,
  IconButton as MuiIconButton,
  useMediaQuery,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import {DrawerProps} from '@mui/material/Drawer'

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import {SidebarMenu} from "./SidebarMenu";
import {IMenuSection} from "../LayoutAuthenticatedDashboard";
import { ChevronsLeft, ChevronsRight } from 'react-feather';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
  .MuiPaper-root {
    background-color: ${(props: any) => props.theme.sidebar.header.background};
  }
` as typeof MuiDrawer

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props: any) => props.theme.sidebar.header.color};
  background-color: ${(props: any) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props: any) => props.theme.sidebar.header.background};
  }
` as typeof ListItemButton;

// const BrandIcon = styled(Logo)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 32px;
//   height: 32px;
// `;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

interface ISidebarProps {
  menuSections: IMenuSection[];
  toggleSidebar: any;
  sidebarCollapsed: boolean;
  isMDDown: boolean;
  showFooter?: boolean;
  PaperProps?: any;
  open?: boolean;
  handleDrawerToggle?: () => void;
  onClose?: () => void;
  variant?: DrawerProps['variant'];
  SidebarFooter?: (props: any) => JSX.Element
}

const SidebarToggleWrapper = styled('div')`
  padding: 20px 12px 0 12px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Sidebar = ({ menuSections, showFooter = true, SidebarFooter, isMDDown, toggleSidebar, sidebarCollapsed, ...rest }: ISidebarProps) => {

  
  const showFullSidebar = isMDDown ? true :
  (sidebarCollapsed ?  false : true);
  // console.log('isMDDown', isMDDown);
  
  return (
    <Drawer variant="permanent" {...rest}>
      {/* <Grid item sx={{ display: { xs: "block", md: "block" } }}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => {}}
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </Grid> */}
      <Grid justifyContent="space-between" container spacing={0}>
        {showFullSidebar && (<Grid item><Brand component={NavLink} to="/">
          {/*<BrandIcon />{" "}*/}
          <Box ml={1}>
            QuantiVision <BrandChip label="PRO" />
          </Box>
        </Brand>
        </Grid>)}
        <Grid item>{/* sx={{ display: { xs: "none", lg: "block" } }}> */}
          <SidebarToggleWrapper>
            {/* <MenuIcon /> */}
            {sidebarCollapsed ? (
              <ChevronsRight color='#eeeeee' onClick={() => {
                toggleSidebar()
              }} />
            ) : (
              <ChevronsLeft color='#eeeeee' onClick={() => {
                toggleSidebar()
              }} />
            )}
          </SidebarToggleWrapper>
        </Grid>
      </Grid>
      {showFullSidebar ? (
        <>
          <SidebarMenu menuSections={menuSections} />
          {SidebarFooter ? (<SidebarFooter />) : <Footer />}
        </>
      ) : (
        <SidebarMenu collapsed={true} menuSections={menuSections} />
      )}
    </Drawer>
  );
};

export default Sidebar;
