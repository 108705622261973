import React from 'react';
import {ValidationError} from 'yup'

// React.Dispatch.<React.SetStateAction<G>>
export const getFormHandleSubmitHandler = (values: Object, validateValues: (values: any) => Object, setEdited: (values: any) => void, callback?: () => void) => {
    // e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>
    return (e: any) => {
      e.preventDefault();
      const editedObj: any = {}
      // TODO: does not work for the nested values thingy - related to Profile.tsx
      for (let key in values) {
        editedObj[`${key}`] = true
      }
      
      setEdited(editedObj)
  
      const errs = validateValues(values)
      for (let key in errs) {
        // @ts-ignore
        if (errs[`${key}`]) {
          return false;
        }
      }

      return callback ? callback() : null;
    }
  }
  
  export const getFormValidateHandler = (schema: any, setErrors: (errors: any) => void, defaultErrorValues: Object): any  => {
    return (values: any) => {
      const newErrorValues: any = {...defaultErrorValues};
      try {
        schema.validateSync(values, {abortEarly: false})
      } catch(e) {
        console.log('ERRORS, e', e);
        
        (e as ValidationError).inner.forEach((error: ValidationError) => {
          newErrorValues[`${error.path}`] = error.errors[0]
        })
      }
      setErrors(newErrorValues)
      return newErrorValues;
    }
  }
  
  export const getFormChangeHandler = (
    key: string,
    values: Object,
    setValues: (vals: any) => void,
    validateValues: (vals: Object) => Object
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = {...values}
      // @ts-ignore
      newValues[`${key}`] = e.target.value

      setValues(newValues)
      validateValues(newValues)
    }
  }

  export const getDefaultErrorValues = (values: Object) => {
    const output = {}
    for (let key in values) {
        // @ts-ignore
        output[`${key}`] = ''
    }
    return output;
  }

  export const getDefaultEditedValues = (values: Object) => {
    const output = {}
    for (let key in values) {
        // @ts-ignore
        output[`${key}`] = false
    }
    return output;
  }