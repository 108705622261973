
export const sliderValueTextFn = (value: number) => `${value}`
export const sliderPerformanceLabels = [
  {
    value: 5,
    label: 'Minimal Performance',
  }
];

export const sliderOptionPerformanceLabels = [
  {
    value: 5,
    label: `Option's Performance`,
  }
];

export const getSliderPerformanceLabel = (name: string) => {
  return [
    {
      value: 5,
      label: name,
    }
  ];
}


export const getDuplicateObject = (obj: any) => {
  return structuredClone ? structuredClone(obj) :  JSON.parse(JSON.stringify(obj))
}
