import React, {useState, useCallback, useContext} from 'react';
import * as Yup from 'yup';

import { useNavigate, Link } from 'react-router-dom';
import {styled} from '@mui/material/styles';

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';
import { Action } from 'hrFrontend/state/actions';

const TextField = styled(MuiTextField)(spacing);

const Alert = styled(MuiAlert)(spacing);
interface ILoginFormValues {
  email: string;
  password: string;
}

const defaultValues: ILoginFormValues = {
  email: '',
  password: ''
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required')
    .max(255),
  password: Yup.string()
    .max(255)
    .required('Password is required')
    .min(8),
})

export const Login = () => {
  const {login, loginFrontend} = useContext(GlobalContext)
  const navigate = useNavigate();

  const [values, setValues] = useState<ILoginFormValues>({...defaultValues})
  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEdited] = useState<any>({...defaultEditedValues})

  const validateValues = useCallback(
    getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const handleSubmit = useCallback(
    getFormHandleSubmitHandler(values, validateValues, setEdited, async () => {
      const {email, password} = values
      try {
        const res = await login(email, password)
        const {status, user, departments, teams, profiles, users} = res;

        if ((`${status}`[0] === '2' || `${status}`[0] === '3')) {
          loginFrontend(user, departments, teams, profiles, users)
          return navigate(ROUTES.RECRUITMENT_DASHBOARD.path);
        }
        
        switch (status) {
          case 403:
            setErrors({...errors, submit: 'Wrong email or password.'})
            break;
          case 500:
            setErrors({...errors, submit: 'Server error, please try again later.'})
            break;
          default:
            setErrors({...errors, submit: 'Something went wrong during the login, please try again later.'})
        }
      } catch (err) {
        console.error(err);
        setErrors({...errors, submit: 'Something went wrong during the login, please try again later.'})
      }
    }),
    [values, validateValues, setEdited]
  )
  const handlePasswordChange = useCallback(
    getFormChangeHandler('password', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handleEmailChange = useCallback(
    getFormChangeHandler('email', values, setValues, validateValues),
    [values, setValues, validateValues]
  )


  return (
    <form noValidate onSubmit={handleSubmit}>
      {errors.submit && (
        <Alert mt={2} mb={3} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <TextField
        type='email'
        name='email'
        label='Email Address'
        value={values.email}
        error={Boolean(edited.email && errors.email)}
        fullWidth
        helperText={edited.email && errors.email}
        onBlur={() => {
          setEdited({...edited, email: true})
        }}
        onChange={handleEmailChange}
        style={{marginBottom: '8px', marginTop: '8px'}}
      />
      <TextField
        type='password'
        name='password'
        label='Password'
        value={values.password}
        error={Boolean(edited.password && errors.password)}
        fullWidth
        helperText={edited.password && errors.password}
        onBlur={() => {
          setEdited({...edited, password: true})
        }}
        onChange={handlePasswordChange}
        style={{marginBottom: '8px'}}
      />
      {/* <FormControlLabel
        control={<Checkbox value='remember' color='primary' />}
        label='Remember me'
      /> */}
      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'>
        Login
      </Button>
      <Button
        component={Link}
        to={ROUTES.RESET_PASSWORD.path}
        fullWidth
        color="primary"
      >
        Forgot password
      </Button>
    </form>
  );
}
