import React, { useContext } from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import {ROUTES} from "./state/routes";
import {RecruitmentDashboardPage} from './pages/RecruitmentDashboardPage';
import {LoginPage} from './pages/LoginPage';
import {ResetPasswordPage} from './pages/ResetPasswordPage';
import {RequestAccountPage} from './pages/RequestAccountPage';
import { VerifyAccountEmailPage } from './pages/VerifyAccountEmailPage';
import { LandingPage } from './pages/LandingPage';
import { GlobalContext } from './state/contexts/GlobalContext';
import { SettingsPage } from './pages/UserSettingsPage';
import { AboutPage } from './pages/AboutPage';
import { ContactPage } from './pages/ContactPage';
import { FinishRegistrationPage } from './pages/FinishRegistrationPage';
import { VacancyPage } from './pages/VacancyPage';
import { ProfilePage } from './pages/ProfilePage';
import { DepartmentPage } from './pages/DepartmentPage';
import { CandidatesPage } from './pages/CandidatesPage';
import { VacancyEvaluationPage } from './pages/VacancyEvaluationPage';
import { ProfilesPage } from './pages/ProfilesPage';
import { VacanciesPage } from './pages/VacanciesPage';


const Router: React.FC = () => {

  const {getIsLoggedIn} = useContext(GlobalContext)
  
  return (
    <Routes>
      <Route path={`${ROUTES.REQUEST_ACCOUNT.path}`} element={<RequestAccountPage />} />
      <Route path={`${ROUTES.VERIFY_ACCOUNT_EMAIL.path}`} element={<VerifyAccountEmailPage />} />
      <Route path={`${ROUTES.FINISH_REGISTRATION_EMAIL.path}`} element={<FinishRegistrationPage />} />
      <Route path={`${ROUTES.LOGIN.path}`} element={<LoginPage />} />
      <Route path={`${ROUTES.RESET_PASSWORD.path}`} element={<ResetPasswordPage />} />
      <Route path={`${ROUTES.ABOUT.path}`} element={<AboutPage />} />
      <Route path={`${ROUTES.CONTACT.path}`} element={<ContactPage />} />
      {/* AUTH PATHS */}
      <Route path={`${ROUTES.RECRUITMENT_DASHBOARD.path}`} element={getIsLoggedIn() ? <RecruitmentDashboardPage /> : <LandingPage />} />
      <Route path={`${ROUTES.SETTINGS.path}`} element={getIsLoggedIn() ? <SettingsPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.DEPARTMENT_NEW.path}`} element={getIsLoggedIn() ? <DepartmentPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.DEPARTMENT_EDIT.path}`} element={getIsLoggedIn() ? <DepartmentPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.VACANCY_NEW.path}`} element={getIsLoggedIn() ? <VacancyPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.VACANCY_EDIT.path}`} element={getIsLoggedIn() ? <VacancyPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.VACANCY_EVALUATION.path}`} element={getIsLoggedIn() ? <VacancyEvaluationPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.VACANCY_RESULTS.path}`} element={getIsLoggedIn() ? <VacancyPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.VACANCIES.path}`} element={getIsLoggedIn() ? <VacanciesPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.PROFILE_NEW.path}`} element={getIsLoggedIn() ? <ProfilePage /> : <LandingPage /> } />
      <Route path={`${ROUTES.PROFILE_EDIT.path}`} element={getIsLoggedIn() ? <ProfilePage /> : <LandingPage /> } />
      <Route path={`${ROUTES.PROFILES.path}`} element={getIsLoggedIn() ? <ProfilesPage /> : <LandingPage /> } />
      <Route path={`${ROUTES.CANDIDATES.path}`} element={getIsLoggedIn() ? <CandidatesPage /> : <LandingPage /> } />

      <Route path={ROUTES.LANDING.path} element={<LandingPage />} />
      {/* <Route path={`/abc`}>
        <Route path={``} element={<AbcPage />} />
        <Route path={`def`} element={<DefPage />} />
      </Route> */}
      {/* <Route path={`${ROUTES.DECISION_NEW.path}`} element={getIsLoggedIn() ? <DecisionPage newDecision={true} /> : <LandingPage /> } />
      <Route path={`${ROUTES.DECISION_OMNIVIEW.path}`} element={getIsLoggedIn() ? <DecisionPage /> : <LandingPage /> } /> */}
    </Routes>
  )
}

export default Router;
