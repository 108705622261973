import React, {useEffect, useState} from 'react'

import {Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from "@mui/material";

import {useNavigate, useParams} from "react-router";
// import { Department } from 'hrFrontend/components/Department';
import { LayoutAuthenticated } from './LayoutAuthenticated';
import { Department } from 'hrFrontend/components/Department';


export function DepartmentPage() {
  const {id} = useParams<{id: string}>();

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h3">{id ? 'Edit Department' : 'New Department'}</Typography>

          <Department editedDepartmentId={id} />
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
