import React, {useEffect, useState} from 'react'

import {Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, TextField, Typography} from "@mui/material";

import {useParams} from "react-router";
import { LayoutAuthenticated } from './LayoutAuthenticated';
import { CandidatesTable } from 'hrFrontend/components/CandidatesTable';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { Candidate, CandidateModal } from 'hrFrontend/components/Candidate';
import { Action } from 'hrFrontend/state/actions';
import { fetchPromise } from 'hrFrontend/utils/fetch';


export function CandidatesPage() {

  const {state, dispatch, hasRole} = React.useContext(GlobalContext);
  const isAdmin = hasRole('admin');
  const {candidates, vacancies} = state;

  

  // const [vacancyRows, setVacancyRows] = useState<any[]>(vacancies)


  useEffect(() => {
    fetch(`/api/v1/recruitment/vacancies`, {method: 'GET'})
    .then(async (response) => {
      const { vacancies } = await response.json();
      console.log('RECRUITMENT_DASHBOARD response', response, vacancies);
      dispatch({
        type: Action.SET_VACANCIES,
        payload: vacancies
      })
      
      fetch(`/api/v1/recruitment/candidates`, {
        method: 'GET',
      }).then(async (response) => {
        const { candidates } = await response.json();
        dispatch({
          type: Action.UPSERT_CANDIDATES,
          payload: candidates
        })
      }).catch((err) => {console.error('Error fetching candidates in vacancy', err)})

    }).catch((err) => {
      console.error('Error fetching vacancies', err)
    })
  }, [])

  const [currentCandidateId, setCurrentCandidateId] = useState<string>();
  const [modalCurrentCandidateOpen, setModalCurrentCandidateOpen] = useState<boolean>(false)

  const onOpenCallback = (aId: string) => {
    setCurrentCandidateId(aId)
    setModalCurrentCandidateOpen(true)
  }

  const [filter, setFilter] = useState<string>('')

  const filteredCandidates = candidates.filter((candidate) => `${candidate.firstName} ${candidate.lastName}`.indexOf(filter) !== -1 )

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
        <Grid justifyContent="space-between" container spacing={0}>
            <Grid item>
              <Typography sx={{ mb: 3 }} variant="h3">Candidates</Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    fullWidth
                    size='small'
                    label="Filter by name"
                    style={{marginTop: '-4px'}}
                    name="candidatesFilter"
                    onChange={(e) => {
                      setFilter(e.target.value)
                    }}
                    required
                    // focused={true}
                    value={filter}
                    variant='outlined'
                    // variant='standard'
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Button
                      style={{marginTop: '-4px'}}
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        setModalCurrentCandidateOpen(true)
                      }}>
                        + New Candidate
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <CandidatesTable
            candidates={filteredCandidates}
            vacancies={vacancies}
            onOpenCallback={onOpenCallback}
            onDeleteCallback={async (candidateId: string) => {
                fetchPromise(
                  `/api/v1/recruitment/candidates`,
                  'DELETE',
                  {id: candidateId},
                  'Error during Vacancy>Candidates delete',
                  (res) => {
                    if (`${res.status}`[0] === '2' || `${res.status}`[0] === '3') {
                      dispatch({
                        type: Action.DELETE_CANDIDATE,
                        payload: {
                          id: candidateId
                        }
                      })
                    } else {
                      // TODO: notification
                    }
                  })
            }}
           />

          <CandidateModal modalOpen={modalCurrentCandidateOpen} setModalOpen={setModalCurrentCandidateOpen} onModalClose={() => {setCurrentCandidateId(undefined)}}>
            <Candidate
              setModalOpen={setModalCurrentCandidateOpen}
              editedCandidateId={currentCandidateId}
              />
          </CandidateModal>
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
