import { verify, sign, decode, JwtPayload } from "jsonwebtoken";
import { IGlobalState, initialGlobalState, IUser, initialUserAdditionalSettingsState, IUserAdditionalSettingsState, IDepartment, IVacancy, IProfile, ICandidate, IEvaluationBackend } from './state';
import { IAction, Action } from './actions';
import { pullAt, uniq } from 'lodash';
import JSCookie from 'js-cookie'
import { CONSTS } from 'shared/consts';
import { getAccessToken } from './contexts/GlobalContext';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { ROUTES } from './routes';
import { getEntityWithDates } from 'hrFrontend/utils/data';


const clearAccessToken = (): void => {
  const hostname = window.location.hostname
  const domain = hostname === 'localhost' ? hostname : `.${hostname}`
  JSCookie.remove(`${CONSTS.COOKIE.USER_TOKEN}`, {secure: true, path: '/', domain})
};

const clearLocalState = (): void => {
  localStorage.removeItem('state');
};

const setUserAdditionalSettingsCookie = (additionalSettings: IUserAdditionalSettingsState) => {
  JSCookie.set(`${CONSTS.COOKIE.USER_SETTINGS}`, JSON.stringify({
    ...initialUserAdditionalSettingsState,
    ...additionalSettings
  }),
  { expires: 36525, path: '/' })
}

export const globalReducer = (state: IGlobalState, action: IAction): IGlobalState => {
  const {payload} = action;
  
  let mutationIndex: number;
  switch (action.type) {
    case Action.LOGIN:
      const {tenantId, features, role} = payload.jwt as JwtPayload
      state = {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        departments: payload.departments,
        teams: payload.teams,
        profiles: payload.profiles,
        users: payload.users,
        role,
        tenantId,
        features
      };
      break;
    case Action.LOGOUT:
      clearAccessToken();
      clearLocalState()
      state =  {
        ...state,
        isLoggedIn: false,
        user: undefined,
      };
      break;

    case Action.UPDATE_USER:
      state =  {
        ...state,
        user: {
          ...state.user as IUser,
          ...payload
        },
      };
      break;

    case Action.REQUEST_ACCOUNT:
      // state = {
      //   ...state,
      //   isLoggedIn: true,
      //   user: payload.user,
      // };
      break;

    case Action.REGISTER_USER:
    state = {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
      break;

    case Action.SET_SIDEBAR_COLLAPSED:
      state.sidebarCollapsed = payload as boolean;
      setUserAdditionalSettingsCookie({
        theme: state.theme,
        lang: state.lang,
        sidebarCollapsed: payload,
      })
      break;

    // ORGANIZATION
    case Action.SET_ORG_CONTEXT:
      const {departments, teams, profiles, users} = payload;
      state.departments = departments
      state.teams = teams
      const freshProfiles: string[] = []
      profiles.forEach((profile: IProfile) => {
        freshProfiles.push(profile.id)
        state.profilesMap[profile.id] = {...profile}
      })
      state.profiles = freshProfiles;
      state.users = users;
      break;
    case Action.CREATE_DEPARTMENT:
      state.departments.push({
        ...payload,
        // createdAt: new Date(),
        // updatedAt: new Date()
      })
      break;
    case Action.UPSERT_DEPARTMENTS:
      state.departments = payload
      break;
    case Action.CREATE_DEPARTMENT:
      state.departments.push({
        ...payload,
        // createdAt: new Date(),
        // updatedAt: new Date()
      })
      break;
    case Action.UPDATE_DEPARTMENT:
      mutationIndex = state.departments.findIndex((el, index) => {return el.id === payload.id})
      state.departments[mutationIndex] = {...payload, updatedAt: new Date()}
      break;
    case Action.DELETE_DEPARTMENT:
      mutationIndex = state.departments.findIndex((el, index) => {
        return el.id === payload.id
      })
      pullAt(state.departments, [mutationIndex])
      break;
    case Action.CREATE_TEAM:
      state.teams.push({
        ...payload,
        // createdAt: new Date(),
        // updatedAt: new Date()
      })
      break;
    case Action.UPDATE_TEAM:
      mutationIndex = state.teams.findIndex((el, index) => {return el.id === payload.id})
      state.teams[mutationIndex] = {...payload, updatedAt: new Date()}
      break;
    case Action.UPSERT_TEAM:
      mutationIndex = state.teams.findIndex((el, index) => {return el.id === payload.id})
      if (mutationIndex !== -1) {
        state.teams[mutationIndex] = {...payload, updatedAt: new Date()}
      } else {
        state.teams.push({...payload})
      }
      break;
    case Action.DELETE_TEAM:
      mutationIndex = state.teams.findIndex((el, index) => {
        return el.id === payload.id
      })
      pullAt(state.teams, [mutationIndex])
      break;
    case Action.CREATE_PROFILE:
        state.profiles.push(payload.id)
        state.profilesMap[payload.id] = {...payload}
        break;
    case Action.UPDATE_PROFILE:
      state.profilesMap[payload.id] = {...payload, updatedAt: new Date()}
      break;
    case Action.UPSERT_PROFILE:
      mutationIndex = state.profiles.findIndex((id, index) => {return id === payload.id})
      if (mutationIndex !== -1) {
        state.profilesMap[payload.id] = {...payload}
      } else {
        state.profiles.push(payload.id)
        state.profilesMap[payload.id] = {...payload}
      }
      break;
    case Action.DELETE_PROFILE:
      mutationIndex = state.profiles.findIndex((id, index) => {return id === payload.id})
      pullAt(state.profiles, [mutationIndex])
      delete state.profilesMap[payload.id]
      state.vacancies = state.vacancies.map((v) => {
        if (v.profileId !== payload.id) {
          return v
        }
        return {
          ...v,
          profileId: null
        }
      })
      break;
    // RECRUITMENT
    case Action.SET_VACANCIES:
      state.vacancies = [...(payload as IVacancy[]).map((vacancy) => {
        return {
          ...getEntityWithDates(vacancy),
        }
      })]
      break;
    case Action.CREATE_VACANCY:
      const {vacancy, rounds} = payload;

      state.vacancies.push({
        ...getEntityWithDates(vacancy)
      })

      // TODO:
      // state.rounds

      break;
    case Action.UPSERT_VACANCY:
      mutationIndex = state.vacancies.findIndex((v, index) => {return v.id === payload.id})
      const newVacancy = getEntityWithDates(payload);
      if (mutationIndex !== -1) {
        state.vacancies[mutationIndex] = {
          ...state.vacancies[mutationIndex], // because of candidatesCount for the table
          ...newVacancy,
          candidatesCount: Number(state.vacancies[mutationIndex].candidatesCount)
        }
      } else {
        state.vacancies.push(newVacancy)
      }
      break;
    case Action.UPDATE_VACANCY:
      mutationIndex = state.vacancies.findIndex((el, index) => {return el.id === payload.id})
      state.vacancies[mutationIndex] = {...payload, updatedAt: new Date()}
      break;
    case Action.DELETE_VACANCY:
      const {id} = payload;
      mutationIndex = state.vacancies.findIndex((el, index) => {
        return el.id === id
      })
      break
    case Action.UPSERT_CANDIDATES:
      const candidatesMap: any = {};
      payload.forEach((a: {id: string, vacancyId: string}, index: number) => {
        const {id, vacancyId} = a;
        if (candidatesMap[id]) {
          candidatesMap[id].vacancyIds.push(vacancyId)
        } else {
          candidatesMap[id] = {
            index,
            vacancyIds: [vacancyId]
          }
        }
      })
      console.log(candidatesMap);
      

      const allCandidates: ICandidate[] = []
      for (let key in candidatesMap) {
        const o = candidatesMap[key];
        allCandidates.push({
          ...payload[o.index],
          vacancyIds: o.vacancyIds
        })
      }
      console.log(allCandidates)

      state.candidates = allCandidates.map((candidate) => {
        return {
          ...candidate,
          // TODO: dates or do them everywhere as Date or as string
          createdAt: new Date(candidate.createdAt),
          updatedAt: new Date(candidate.updatedAt)
        }
      })
      break
    case Action.UPSERT_CANDIDATE:
      mutationIndex = state.candidates.findIndex(({id}, index) => {return id === payload.id})
      if (mutationIndex !== -1) {
        state.candidates[mutationIndex] = {
          ...state.candidates[mutationIndex],
          ...payload
        }
      } else {
        state.candidates.push(payload)
      }
      break
    case Action.CREATE_CANDIDATE:
      state.candidates.push(payload)
      break
    case Action.UPDATE_CANDIDATE:
      mutationIndex = state.candidates.findIndex((el, index) => {return el.id === payload.id})
      state.candidates[mutationIndex] = {
        ...state.candidates[mutationIndex],
        ...payload,
        updatedAt: new Date()
      }
      break;
    case Action.DELETE_CANDIDATE:
      mutationIndex = state.candidates.findIndex((el, index) => {return el.id === payload.id})
      pullAt(state.candidates, [mutationIndex])
      break;
    case Action.UPSERT_CANDIDATE_EVALUATIONS:
      const {candidateEvaluations} = payload;
      mutationIndex = state.vacancies.findIndex((el, index) => {return el.id === payload.vacancyId});

      candidateEvaluations.forEach((evaluation: IEvaluationBackend) => {
        if (!state.vacancies[mutationIndex].evaluationsMap[evaluation.userId]) {
          state.vacancies[mutationIndex].evaluationsMap[evaluation.userId] = {}
        }
        state.vacancies[mutationIndex].evaluationsMap[evaluation.userId][evaluation.candidateId] = evaluation.evaluation
      })
      break;
    case Action.UPSERT_CANDIDATE_EVALUATION:
      const {vacancyId, userId, candidateId, evaluatedCriteria} = payload
      mutationIndex = state.vacancies.findIndex((el, index) => {return el.id === vacancyId})

      const v = state.vacancies[mutationIndex];
      if (!v.evaluationsMap[userId]) {
        v.evaluationsMap[userId] = {}
      }
      v.evaluationsMap[userId][candidateId] = evaluatedCriteria;
      state.vacancies[mutationIndex] = {
        ...v,
        updatedAt: new Date()
      }
      break;
  }

  window.localStorage.setItem('global', JSON.stringify(state))

  return {
    ...state
  }
};