import React from 'react';
// import { Helmet } from 'react-helmet-async';
import {Typography } from '@mui/material';

import { LayoutLanding } from './LayoutLanding';
import { LandingTopbar } from './Landing/LaningTopbar';
import { LandingReel } from './Landing/LandingReel';
import { LandingFooter } from './Landing/LandingFooter';


export const LandingPage = () => {
  return (
    <LayoutLanding>
      {/*<Helmet title='QuantiVision' />*/}
      <LandingTopbar />
      <LandingReel />
      <LandingFooter />
    </LayoutLanding>
  );
}
