import React from 'react';
import {styled} from '@mui/material/styles';

import {
  Container,
  Grid,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@mui/system';

const Typography = styled(MuiTypography)(spacing);

export const LandingContact = () => {
  return (
    <Container>
      <h2>Contact</h2>
      <Grid container spacing={6} mb={6} style={{minHeight: '402px'}}>
        <Grid item xs={12}>
          <Grid item xs={12} m={2}>
            <p style={{fontSize: '105%'}}>
              In case of any questions or feedback, please contact us via email: <a href="mailto:info@quantivision.com">info@quantivision.com</a> 
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

