import {Shadows} from "@mui/material/styles/shadows";

function createShadow() {
  return `0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;
}

export const shadows = [
  "none",
  createShadow(),
  `0px 1px 2px 0px rgba(0, 0, 0, 0.05);`,
  `0px 1px 3px 0px rgba(0, 0, 0, 0.10);`,
  `0px 2px 4px 0px rgba(0, 0, 0, 0.15);`,
  `0px 2px 5px 0px rgba(0, 0, 0, 0.20);`,
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
] as Shadows;
