import React from 'react';
import {styled} from '@mui/material/styles';

import {
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { ROUTES } from 'hrFrontend/state/routes';

const Spacer = styled('div')(spacing);

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled('div')`
  ${spacing};
  text-align: center;
  position: relative;
  background: #181d2d;
  color: ${(props) => props.theme.palette.common.white};
  height: 100%;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;
`;

export const LandingFooter = () => {
  return (
    <Wrapper pt={16} pb={16}>
      <Container>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography variant='h3' gutterBottom>
              Start using HR tool designed for the future
            </Typography>
            <Subtitle variant='h5' gutterBottom>
              Stop wasting time with docs and spreadsheets all over the place and use a PRO hiring and performance management tool.
            </Subtitle>
            <Spacer mb={4} />
            <Button
              href={ROUTES.REQUEST_ACCOUNT.path}
              variant='contained'
              color='primary'
              size='large'
              // target='_blank'
            >
              Book a demo
            </Button>
            <Spacer mb={4} />
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'}>
          &copy; {(new Date()).getUTCFullYear()} Quantivision, All Rights Reserved
        </Grid>
      </Container>
    </Wrapper>
  );
}
