import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles';
import *  as Yup from 'yup';
import { Box, Button, Card, CardActionArea, CardContent, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Modal, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'; 

import { Close, Delete } from '@mui/icons-material';
import { IDepartment, IProfile, IProfilesMap, ITeam, getDefaultDepartment, getDefaultTeam } from 'hrFrontend/state/state';
import { ProportionsWizardIcon, TheBestWizardIcon } from './wizard/WizardIcons';
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';
import PickerPreselected from './PickerPreselected';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';
import { Action } from 'hrFrontend/state/actions';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { useNavigate } from 'react-router';
import { SectionCard } from 'hrFrontend/pages/RecruitmentDashboardPage';
import { Team, TeamModal } from './Team';
import { Profile, ProfileModal } from './Profile';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';


export const DepartmentModalComponent = styled(Modal)`
  padding-top: 12px;

  modal:{
  "&:focus":{
    outline: none;
    border: none;
    }
  }
`;

interface IDepartmentRequiredValues {
  name: string;
}

const defaultValues: IDepartmentRequiredValues = {
  name: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const schema = Yup.object().shape({
  name: Yup.string()
    .required('First name is required')
    .max(255)
})

interface IDepartmentModalProps extends PropsWithChildren<any> {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

export const DepartmentModal = (props: IDepartmentModalProps) => {
  const {modalOpen, setModalOpen, children} = props;

  return (
   <div>  {/*style={{outline:'none!important'}}> */}
      <DepartmentModalComponent
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        style={{overflow: 'scroll'}}
      >
        <Container maxWidth="md" style={{outline:'none'}}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
               {children}
              </CardContent>
            </Card>
          </form>
        </Container>
        
      </DepartmentModalComponent>
    </div>
  )
}


interface IDepartmentTypeTileProps {
  title: string;
  callback: () => void;
  Icon: (props: any) => JSX.Element;
  Radio: JSX.Element;
}

const DepartmentTypeTile = (props: IDepartmentTypeTileProps) => {

  const {title, callback, Icon, Radio} = props;

  return (
    <Card elevation={4} variant={'outlined'} sx={{ minWidth: '100%', maxWidth: '100%' }} style={{width: '100%', height: '100%'}}>
      <CardActionArea onClick={callback} style={{width: '100%', height: '100%'}}>
        <CardContent sx={{ pt: 1, pb: 4, pl: 4, pr: 4 }}>
          {Radio}
          <Icon width={33} height={33} opacity={'1.0'} />
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              marginTop: '0.8em',
              marginBottom: '0',
              textAlign: 'center',
              fontSize: '1em',
              minHeight: '2em'
            }}>
            {title}
          </Typography>
          
          {/* <Typography variant="body2" color="text.secondary">
            Define your own criteria
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface IDepartmentProps {
  editedDepartmentId?: string;
  // department: IDepartment;
  // setUpdateDepartment: (department: IDepartment) => void;
  setModalOpen?: (open: boolean) => void;
  // isNewDepartment?: boolean;
}

export const Department = (props: IDepartmentProps) => {
  const {state, dispatch} = React.useContext(GlobalContext);
  const {departments, teams, profiles, profilesMap, vacancies} = state;
  const navigate = useNavigate();

  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEditedLocal] = useState<any>({...defaultEditedValues})
  

  const {editedDepartmentId, setModalOpen} = props;

  const isNewDepartment = !editedDepartmentId;
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)


  const [department, setDepartment] = useState<IDepartment>(isNewDepartment ? getDefaultDepartment() : departments.find((d) => d.id === editedDepartmentId) as IDepartment)

  useEffect(() => {
    if (isNewDepartment) {
      // fetch dep

    } else {
      // fetch teams

      // fetch profiles

      // fetch vacancies
      fetch(`/api/v1/recruitment/vacancies`, {method: 'GET'})
      .then(async (response) => {
        const { vacancies } = await response.json();
        console.log('RECRUITMENT_DASHBOARD response', response, vacancies);
        dispatch({
          type: Action.SET_VACANCIES,
          payload: vacancies
        })
        
      }).catch((err) => {
        console.error('Error fetching vacancies', err)
      })
    }
  }, [])

  const deleteDepartment = async (department: IDepartment) => {
    dispatch({
      type: Action.DELETE_DEPARTMENT,
      payload: department
    })

    await fetchPromise(`/api/v1/org/departments`, 'DELETE', department, 'Error during Deparment deletion')
    navigate(ROUTES.RECRUITMENT_DASHBOARD.path)
  }

  const validateValues = useCallback(getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const setEdited = (edited: any) => {
    setEditedLocal(edited)
    validateValues(department)
  }

  const setDepartmentWrapper = useCallback((department: IDepartment) => {
    setDepartment(department)
    if (editedDepartmentId) {
      dispatch({
        type: Action.UPDATE_DEPARTMENT,
        payload: department
      })
      fetchPromise(`/api/v1/org/departments`, 'PATCH', department, 'Error during Deparment update')
    }
  }, [editedDepartmentId, setDepartment])

  const handleNameChange = useCallback(
    getFormChangeHandler('name', department, setDepartmentWrapper, validateValues),
    [department, setDepartmentWrapper, validateValues]
  )

  const handleCreate = useCallback(
    getFormHandleSubmitHandler(department, validateValues, setEdited, async () => {
      setSaveDisabled(true)
      dispatch({
        type: Action.CREATE_DEPARTMENT,
        payload: department
      })
      await fetchPromise(`/api/v1/org/departments`, 'POST', department, 'Error during Deparment creation')
      navigate(ROUTES.RECRUITMENT_DASHBOARD.path)
    })
    ,[department, validateValues, setEdited]
  )

  const [modalCurrentTeamOpen, setModalCurrentTeamOpen] = useState<boolean>(false)
  const [currentTeam, setCurrentTeam] = useState<ITeam>()

  const [modalCurrentProfileOpen, setModalCurrentProfileOpen] = useState<boolean>(false)
  const [currentProfile, setCurrentProfile] = useState<IProfile>()

  return (
    <>
    <Card>
        {/* <CardHeader
          title="Vacancy Details"
          // subheader="The information can be edited"
          />*/}
      <CardContent>
        {/* <Grid container spacing={0} justifyContent='space-between'>
          <Grid item>
            <Typography component={'h3'} sx={{ mb: 1 }}><strong>{isNewDepartment ? 'New' : 'Edit'} Department</strong></Typography>
          </Grid>
          <Grid item>
            <Close style={{opacity: '1.0', cursor: 'pointer' }} onClick={() => {if (setModalOpen) { setModalOpen(false) } }} />
          </Grid>
        </Grid>
        <Divider sx={{mb: 4}} /> */}
        
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Department Name"
            variant='outlined'
            value={department.name}
            error={Boolean(edited.name && errors.name)}
            helperText={edited.name && errors.name}
            onBlur={() => {setEdited({...edited, name: true})}}
            fullWidth={true}
            onChange={handleNameChange}
            />
        </Grid>
        <Grid item sm={6} xs={12}>
         Department Color:
         <PickerPreselected color={department.color} onUpdateHandler={(color: string) => {
            console.log('color', color);
            setDepartment({
              ...department,
              color,
            })
          }} />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{mt: 2}}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h5">Teams</Typography>
          <Grid container spacing={3}>
          {teams.filter((t) => t.departmentId === department.id).map((team) => {
            const {name, id} = team;
            return (
              <Grid item sm={3} xs={12} >
                <SectionCard elevated={true}>
                  <Typography sx={{ mb: 2 }} variant="h5" style={{cursor: 'pointer'}} onClick={() => {
                      setCurrentTeam(team)
                      setModalCurrentTeamOpen(true)
                    }}>
                    {name}
                    {/* <Edit3 opacity={0.6} style={{cursor: 'pointer'}} size={'15px'} onClick={() => {
                      navigate(ROUTES.DEPARTMENT_EDIT.getPath(id))
                    }} /> */}
                  </Typography>
                </SectionCard>
              </Grid>
            )
          })}
          </Grid>

          <Grid item xs={12}>
            <Button
              style={{margin: '12px 16px 10px 0'}}
              color={"secondary"}
              variant={"contained"}
              disabled={!editedDepartmentId}
              onClick={() => {
                setCurrentTeam(undefined)
                setModalCurrentTeamOpen(true)
              }}>
                + New Team
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{mt: 2}}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h5">Role Profiles</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ul>
                {profiles.map((profileId) => {
                  const profile = profilesMap[profileId]
                  const {name, id, departmentId} = profile;

                  if (departmentId !== department.id) {
                    return;
                  }

                  
                  return (
                    <li key={id}>
                      <Typography sx={{ mb: 2 }} variant="h6" style={{cursor: 'pointer'}} onClick={() => {
                          setCurrentProfile(profile)
                          setModalCurrentProfileOpen(true)
                        }}>
                        {name}
                        {/* <Edit3 opacity={0.6} style={{cursor: 'pointer'}} size={'15px'} onClick={() => {
                          navigate(ROUTES.DEPARTMENT_EDIT.getPath(id))
                        }} /> */}
                      </Typography>
                    </li>
                  )
                })}
              </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              style={{margin: '12px 16px 10px 0'}}
              color={"secondary"}
              variant={"contained"}
              disabled={!editedDepartmentId}
              onClick={() => {
                setCurrentProfile(undefined)
                setModalCurrentProfileOpen(true)
              }}>
                + New Role Profile
            </Button>
          </Grid>
        </Grid>
      </Grid>
      </CardContent>
      <Divider />
      <Box sx={{display: 'flex', /*justifyContent: 'flex-end',*/ p: 4}}>
        {isNewDepartment ? (<Button
            color={"secondary"}
            variant={"contained"}
            disabled={saveDisabled}
            onClick={handleCreate}>
              Create
            </Button>) : (
              <Button
              sx={{ ml: 3}}
              color={"error"}
              variant={"contained"}
              disabled={deleteDisabled}
              onClick={() => {
                if (confirm('Are you sure you want to delete this department?')) {
                  setDeleteDisabled(true)
                  deleteDepartment(department)
                }
              }}>
                Delete
            </Button>
          )}
        </Box>


        <TeamModal modalOpen={modalCurrentTeamOpen} setModalOpen={setModalCurrentTeamOpen}>
          <Team
            defaultDepartmentId={department.id}
            editedTeamId={currentTeam?.id}
            setModalOpen={setModalCurrentTeamOpen}
            />
        </TeamModal>

        <ProfileModal modalOpen={modalCurrentProfileOpen} setModalOpen={setModalCurrentProfileOpen}>
          <Profile
            departmentId={department.id}
            editedProfileId={currentProfile?.id}
            setModalOpen={setModalCurrentProfileOpen}
            />
        </ProfileModal>

      </Card>
    </>
  )
}
