import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import {styled} from '@mui/material/styles';
import {Briefcase, Home, Settings, Sliders, Sunrise, Trash, TrendingUp, UserCheck, Users} from 'react-feather'

import {IMenuItem, IMenuSection, LayoutAuthenticatedDashboard} from 'sharedFrontend/layouts/LayoutAuthenticatedDashboard';

import {MAIN_CONTENT_HORIZONTAL_PADDING, MAIN_CONTENT_VERTICAL_PADDING} from '../theme/sizes';
import {ROUTES} from '../state/routes';
import { SidebarFooter } from 'hrFrontend/components/Footer';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { matchPath, useMatch } from 'react-router';
import { Action } from 'hrFrontend/state/actions';
import { DecisionsIconsSVG, WizardIconsSVG } from 'hrFrontend/components/wizard/WizardIcons';
import { BusinessRounded } from '@mui/icons-material';
import { TFeature } from 'shared/types';

const recruitmentMenuItems: IMenuItem<TFeature>[] = [
  {
    href: `${ROUTES.RECRUITMENT_DASHBOARD.path}`,
    title: 'Dashboard',
    icon: Home,
    feature: 'recruitment',
  },
  {
    href: `${ROUTES.VACANCIES.path}`,
    title: 'Vacancies',
    icon: Briefcase,
    feature: 'recruitment'
  },
  {
    href: `${ROUTES.PROFILES.path}`,
    title: 'Role Profiles',
    icon: UserCheck,
    feature: 'recruitment'
  },
  {
    href: `${ROUTES.CANDIDATES.path}`,
    title: 'Candidates',
    icon: Users,
    feature: 'recruitment'
  },
  // {
  //   href: `${ROUTES.ORGANIZATION.path}`,
  //   title: 'Organization',
  //   icon: BusinessRounded
  // },
  // // nested
  // {
  //   href: `${ROUTES.STAFF.path}`,
  //   title: 'Staff',
  //   icon: Users
  // },
// TODO:
  // // nested
  // {
  //   href: `${ROUTES.GROWTH.path}`,
  //   title: 'Growth',
  //   icon: Sunrise, // TrendingUp
  //   feature: 'growth'
  // },
  // {
  //   href: `${ROUTES.TRASH.path}`,
  //   title: 'Trash',
  //   icon: Trash,
  // },
]

const menuSections: IMenuSection<TFeature>[] = [
  {
    title: 'RECRUITMENT',
    items: recruitmentMenuItems
  },
  {
    title: 'ORGANIZATION',
    items: [
      {
        href: `${ROUTES.ORGANIZATION.path}`,
        title: 'Organization Settings',
        icon: BusinessRounded
      },
    ]
  },
  {
    title: 'USER',
    items: [
      {
        href: `${ROUTES.SETTINGS.path}`,
        title: 'User Settings',
        icon: Settings
      },
    ]
  }
];

export const LayoutAuthenticated = ({children}: PropsWithChildren<any>) => {
  const {state, dispatch, logout} = React.useContext(GlobalContext);
  const {features} = state;

  const {sidebarCollapsed} = state;

  const menuSectionsRedacted = useMemo(() => {
    return menuSections.map((ms) => {
      return {
        title: ms.title,
        items: ms.items.filter((item) => {

          if (item.feature) {
            if (features.indexOf(item.feature) === -1) {
              return false
            }
          }
    
          return true
        })
      }
    })
  }, [features])


  const setSidebarCollapsed = (newCollapsedValue: boolean) => {
    dispatch({
      type: Action.SET_SIDEBAR_COLLAPSED,
      payload: newCollapsedValue
    })
  }

  const match = false // !!(useMatch(ROUTES.VACANCY_EVALUATION.path) || useMatch(ROUTES.VACANCY_RESULTS.path))
  const collapseSidebarOnInit = useCallback(() => {
    return match
  }, [match])
  
  return (
    <LayoutAuthenticatedDashboard
      mainContentHorizontalP={MAIN_CONTENT_HORIZONTAL_PADDING}
      mainContentVerticalP={MAIN_CONTENT_VERTICAL_PADDING}
      menuSections={menuSectionsRedacted}
      footer={SidebarFooter}
      logout={logout}
      sidebarCollapsed={sidebarCollapsed}
      setSidebarCollapsed={setSidebarCollapsed}
      collapseSidebarOnInit={collapseSidebarOnInit}
    >
      <WizardIconsSVG />
      <DecisionsIconsSVG />
      {children}
    </LayoutAuthenticatedDashboard>
  )
}
