import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import {GlobalStylesWrapper} from 'sharedFrontend/layouts/GlobalStylesWrapper';


const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LayoutLanding = ({ children }: PropsWithChildren<any>) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStylesWrapper />
      <AppContent>
        {children}
        <Outlet />
      </AppContent>
    </Root>
  );
};

