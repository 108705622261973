import React from 'react';
// import { Helmet } from 'react-helmet-async';
import {Typography } from '@mui/material';

import {ResetPassword} from '../components/ResetPassword';
import {LayoutAuthentication} from './LayoutAuthentication';


export const ResetPasswordPage = () => {
  return (
    <LayoutAuthentication>
      {/*<Helmet title='Reset Password' />*/}
      <Typography component='h1' variant='h4' align='center' gutterBottom>
        Reset Password
      </Typography>
      <Typography component='h2' variant='body1' align='center'>
        Enter your email to reset your password
      </Typography>

      <ResetPassword />
    </LayoutAuthentication>
  );
}
