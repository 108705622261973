import { IEntity } from 'hrFrontend/state/state';

export const getEntityWithDates = <T extends IEntity>(entity: T): T => {

  return {
    ...entity,
    createdAt: new Date(entity.createdAt),
    updatedAt: new Date(entity.updatedAt)
  }

}