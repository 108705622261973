import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import {css, List} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

import {SidebarMenuItem} from "./SidebarMenuItem";
import {IMenuItem, IMenuSection} from "../LayoutAuthenticatedDashboard";
import {SidebarMenuItems, SidebarMenuPerfectScrollbar, SidebarMenuScrollbar, SidebarMenuSectionTitle} from "./SidebarMenu.sc";

// import "../../vendor/perfect-scrollbar.css";

interface ISidebarNavSection {
  items: IMenuItem[];
  title: string;
  component: string;
  className?: string;
  collapsed?: boolean;
}

const SidebarMenuSection = (props: ISidebarNavSection) => {
  const {
    title,
    items,
    className,
    component: Component = "nav",
    collapsed,
    ...rest
  } = props;

  // @ts-ignore
  return (<Component {...rest}>
    {title && !collapsed && <SidebarMenuSectionTitle variant="subtitle2">{title}</SidebarMenuSectionTitle>}
    <SidebarMenuList collapsed={collapsed} items={items} depth={0} />
  </Component>);
};


const reduceChildRoutes = (
  reducedItems: JSX.Element[],
  currentItem: IMenuItem,
  currentRoute?: any,
  depth?: any,
  collapsed?: boolean
) => {
  if (currentItem.childs) {
    const open = currentItem.href
      ? !!matchPath(
        {
          path: currentItem.href,
          end: false,
        },
        currentRoute
      )
      : false;

    reducedItems.push(
      <SidebarMenuItem
        depth={depth}
        icon={currentItem.icon}
        key={currentItem.title}
        badge={currentItem.badge}
        open={!!open}
        title={currentItem.title}
        href={currentItem.href}
        collapsed={collapsed}
      >
        {collapsed ? null : <SidebarMenuList depth={depth + 1} items={currentItem.childs} />}
      </SidebarMenuItem>
    );
  } else {
    reducedItems.push(
      <SidebarMenuItem
        depth={depth}
        href={currentItem.href}
        icon={currentItem.icon}
        key={currentItem.title}
        badge={currentItem.badge}
        title={currentItem.title}
        collapsed={collapsed}
      />
    );
  }

  return reducedItems;
};

interface ISidebarMenuListProps {
  items: IMenuItem[];
  depth?: number;
  collapsed?: boolean;
}

const SidebarMenuList = (props: ISidebarMenuListProps) => {
  const { items, depth, collapsed } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = items.reduce((reducedItems: JSX.Element[], currentItem: IMenuItem) => reduceChildRoutes(reducedItems, currentItem, currentRoute, depth, collapsed), []);

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

interface ISidebarNavProps {
  menuSections: IMenuSection[];
  collapsed?: boolean;
}

export const SidebarMenu = ({ menuSections, collapsed }: ISidebarNavProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? SidebarMenuPerfectScrollbar : SidebarMenuScrollbar;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <SidebarMenuItems>
          {menuSections &&
            menuSections.map((menuSection) => (
              <SidebarMenuSection
                component="div"
                key={menuSection.title}
                items={menuSection.items}
                title={menuSection.title}
                collapsed={collapsed}
              />
            ))}
        </SidebarMenuItems>
      </List>
    </ScrollbarComponent>
  );
};

