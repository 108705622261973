import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, SelectChangeEvent, Slider, Switch, TextField, Typography } from '@mui/material'; 
import * as Yup from 'yup';

import { Close, Delete, ExpandMore } from '@mui/icons-material';
import { CriterionTypeEnum, IDepartment, ICandidate, getDefaultKoCriterion, getDefaultScaledCriterion } from 'hrFrontend/state/state';
import { ProportionsWizardIcon, TheBestWizardIcon } from './wizard/WizardIcons';
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';
import PickerPreselected from './PickerPreselected';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { Action } from 'hrFrontend/state/actions';
import { fetchPromise } from 'hrFrontend/utils/fetch';
import { pullAt } from 'lodash';
import { XCircle } from 'sharedFrontend/icons/x-circle';
import { SliderAdjacentTextField, SliderAdjacentTextFieldNegative, SliderHollow } from './SilderStyles';
import { sliderPerformanceLabels, sliderValueTextFn } from './utils/utils-decision';
import { v4 } from 'uuid';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { Alert } from 'sharedFrontend/atoms/Alert';

export const CandidateModalComponent = styled(Modal)`
  padding-top: 12px;

  modal:{
  "&:focus":{
    outline: none;
    border: none;
    }
  }
`;

const getSliderWeightLabel = (roleName: string) => [
  {
    value: 5,
    label: `Relevance for: ${roleName}`,
  },
];

const sliderWeightLabelsOld = [
  {
    value: 1,
    label: 'Irrelevant',
  },
  {
    value: 3,
    label: 'Good To Have',
  },
  {
    value: 5,
    label: 'Important',
  },
  {
    value: 8,
    label: 'Very Important',
  },
  {
    value: 10,
    label: 'Essential',
  }
];


interface ICandidateModalProps extends PropsWithChildren<any> {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  onModalClose?: () => void;
}

export const CandidateModal = (props: ICandidateModalProps) => {
  const {modalOpen, onModalClose, setModalOpen, children} = props;

  return (
   <div>  {/*style={{outline:'none!important'}}> */}
      <CandidateModalComponent
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          if (onModalClose) {
            onModalClose()
          }
        }}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        style={{overflow: 'scroll'}}
      >
        <Container maxWidth="md" style={{outline:'none'}}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
               {children}
              </CardContent>
            </Card>
          </form>
        </Container>
        
      </CandidateModalComponent>
    </div>
  )
}


interface ICandidateTypeTileProps {
  title: string;
  callback: () => void;
  Icon: (props: any) => JSX.Element;
  Radio: JSX.Element;
}

const CandidateTypeTile = (props: ICandidateTypeTileProps) => {

  const {title, callback, Icon, Radio} = props;

  return (
    <Card elevation={4} variant={'outlined'} sx={{ minWidth: '100%', maxWidth: '100%' }} style={{width: '100%', height: '100%'}}>
      <CardActionArea onClick={callback} style={{width: '100%', height: '100%'}}>
        <CardContent sx={{ pt: 1, pb: 4, pl: 4, pr: 4 }}>
          {Radio}
          <Icon width={33} height={33} opacity={'1.0'} />
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              marginTop: '0.8em',
              marginBottom: '0',
              textAlign: 'center',
              fontSize: '1em',
              minHeight: '2em'
            }}>
            {title}
          </Typography>
          
          {/* <Typography variant="body2" color="text.secondary">
            Define your own criteria
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface ICandidateRequiredValues {
  firstName: string;
  lastName: string;
  email: string;
}

const defaultValues: ICandidateRequiredValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required')
    .max(255),
  firstName: Yup.string()
    .required('First name is required')
    .max(255),
  lastName: Yup.string()
    .required('Last name is required')
    .max(255)
})

interface ICandidateProps {
  vacancyId?: string;
  setModalOpen?: (open: boolean) => void;
  editedCandidateId?: string;
  onSaveCallback?: (candidate: ICandidate) => void;
  departmentId?: string | null;
  frontendOnly?: boolean;
}

export const Candidate = (props: ICandidateProps) => {
  const {editedCandidateId, departmentId, setModalOpen, onSaveCallback, vacancyId, frontendOnly} = props;

  const {state, dispatch} = React.useContext(GlobalContext);

  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEditedLocal] = useState<any>({...defaultEditedValues})

  const validateValues = useCallback(getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])
  const {departments, candidates} = state;

  const now = new Date();
  const [candidate, setCandidate] = useState<ICandidate>(editedCandidateId
    ? candidates.find((a) => a.id === editedCandidateId) as ICandidate
    : {
      id: v4(),
      createdAt: now,
      updatedAt: now,
      email: '',
      firstName: '',
      lastName: '',
      vacancyIds: vacancyId ? [vacancyId] : [],
      // rest
      phone: undefined,
      linkedIn: undefined,
      github: undefined,
    }
  )
  const setEdited = (edited: any) => {
    setEditedLocal(edited)
    validateValues(candidate)
  }

  // const {id, name, criteria} = candidate;
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)

  useEffect(() => {
    if (editedCandidateId && !frontendOnly) {
      fetch(`/api/v1/recruitment/candidates/${editedCandidateId}`, {
        method: 'GET',
      }).then(async (response) => {
        const { candidate } = await response.json();
        console.log('response', response, candidate);
        if (candidate) {
          dispatch({
            type: Action.UPSERT_CANDIDATE,
            payload: {...candidate}
          })
          setCandidate(candidate)
        }
      }).catch((err) => {
        console.error('Error fetching candidate', err)
      })
    }
  }, [])

  const setCandidateWrapper = useCallback((candidate: ICandidate) => {
    setCandidate(candidate)
    if (editedCandidateId) {
      dispatch({
        type: Action.UPDATE_CANDIDATE,
        payload: {
          ...candidate
        }
      })
      fetchPromise(`/api/v1/recruitment/candidates`, 'PATCH', candidate, 'Error during Candidate update');
    }
  }, [editedCandidateId, setCandidate])

  const handleFirstNameChange = useCallback(
    getFormChangeHandler('firstName', candidate, setCandidateWrapper, validateValues),
    [candidate, setCandidateWrapper, validateValues]
  )

  const handleLastNameChange = useCallback(
    getFormChangeHandler('lastName', candidate, setCandidateWrapper, validateValues),
    [candidate, setCandidateWrapper, validateValues]
  )

  const handleEmailChange = useCallback(
    getFormChangeHandler('email', candidate, setCandidateWrapper, validateValues),
    [candidate, setCandidateWrapper, validateValues]
  )

  const handlePhoneChange = useCallback(
    getFormChangeHandler('phone', candidate, setCandidateWrapper, validateValues),
    [candidate, setCandidateWrapper, validateValues]
  )

  // const handleEdit = async (a: ICandidate, init = false) => {
  //   setCandidate(a)
  //   if (editedCandidateId) {
  //     dispatch({
  //       type: Action.UPDATE_CANDIDATE,
  //       payload: {
  //         ...a
  //       }
  //     })
  //     if (init) {
  //       await fetchPromise(`/api/v1/recruitment/candidates`, 'PATCH', a, 'Error during Candidate update');
  //     }
  //   }
  // }
  const handleEdit = useCallback(
    getFormHandleSubmitHandler(candidate, validateValues, setEdited, async () => {
      setCandidate(candidate)
      
    })
    , [candidate, validateValues, setEdited])

  const handleCreate = useCallback(
    getFormHandleSubmitHandler(candidate, validateValues, setEdited, async () => {
      setSaveDisabled(true)
      dispatch({
        type: Action.CREATE_CANDIDATE,
        payload: {
          ...candidate
        }
      })
      if (!frontendOnly) {
        await fetchPromise(`/api/v1/recruitment/candidates`, 'POST', candidate, 'Error during Candidate creation')
      }
      if (onSaveCallback) {
        onSaveCallback(candidate)
      }
      if (setModalOpen) {
        setModalOpen(false)
      }
    })
    , [candidate, validateValues, setEdited])

  return (
    <React.Fragment>
      {setModalOpen && (
        <React.Fragment>
          <Grid container spacing={0} justifyContent='space-between'>
            <Grid item>
              <Typography component={'h3'} sx={{ mb: 1 }}><strong>{editedCandidateId ? 'Edit' : 'New'} Candidate</strong></Typography>
            </Grid>
            <Grid item>
              <Close style={{opacity: '1.0', cursor: 'pointer' }} onClick={() => {if (setModalOpen) { setModalOpen(false) } }} />
            </Grid>
          </Grid>
          <Divider sx={{mb: 4}} />
        </React.Fragment>
      )}
        
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TextField
            label="First Name"
            variant='outlined'
            value={candidate.firstName}
            error={Boolean(edited.firstName && errors.firstName)}
            helperText={edited.firstName && errors.firstName}
            onBlur={() => {setEdited({...edited, firstName: true})}}
            fullWidth={true}
            onChange={handleFirstNameChange}
            />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Last Name"
            variant='outlined'
            value={candidate.lastName}
            error={Boolean(edited.lastName && errors.lastName)}
            helperText={edited.lastName && errors.lastName}
            onBlur={() => {setEdited({...edited, lastName: true})}}
            fullWidth={true}
            onChange={handleLastNameChange}
            />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Email"
            variant='outlined'
            value={candidate.email}
            error={Boolean(edited.email && errors.email)}
            helperText={edited.email && errors.email}
            onBlur={() => {setEdited({...edited, email: true})}}
            fullWidth={true}
            onChange={handleEmailChange}
            />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Phone"
            variant='outlined'
            value={candidate.phone}
            fullWidth={true}
            onChange={handlePhoneChange}
            />
        </Grid>

        {/* <Divider />
        {errors.submit && (
          <Alert mt={2} mb={3} severity="warning">
            {errors.submit}
          </Alert>
        )} */}

        <Grid item xs={12}>
          {!editedCandidateId && (
          <Button
            color={"secondary"}
            variant={"contained"}
            disabled={saveDisabled}
            onClick={handleCreate}>
              Create
          </Button>
          )}

          {editedCandidateId ? (
            <Button
              sx={{ ml: 3}}
              color={"error"}
              variant={"contained"}
              disabled={deleteDisabled}
              onClick={async () => {
                if (confirm('Are you sure you want to delete this candidate?')) {
                  setDeleteDisabled(true)
                  dispatch({
                    type: Action.DELETE_CANDIDATE,
                    payload: candidate
                  })
                  await fetchPromise(`/api/v1/recruitment/candidates`, 'DELETE', {id: candidate.id}, 'Error during Candidate deletion')
                  if (setModalOpen) {
                    setModalOpen(false)
                  }
                }
              }}>
                Delete
            </Button>
          ) : null}
        </Grid>
      </Grid>

      
    </React.Fragment>
  )
}
