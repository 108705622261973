import React, { useContext, useEffect, useState } from 'react';
import {styled} from '@mui/material/styles';
// import { Helmet } from 'react-helmet-async';

import { Avatar, Typography } from '@mui/material';

import {LayoutAuthentication} from './LayoutAuthentication';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';
import { FinishRegistration, IFinishRegistrationUser } from 'hrFrontend/components/FinishRegistration';


export const FinishRegistrationPage = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const {loginFrontend} = useContext(GlobalContext);

  const email = params.get('email')
  const key = params.get('key')
  const firstName = params.get('firstName')
  const lastName = params.get('lastName')

  return (
    <LayoutAuthentication>
      {/*<Helmet title='Register' />*/}
      <Typography component='h1' variant='h4' align='center' gutterBottom>
        Finish your registration
      </Typography>
      <Typography component='h2' variant='body1' align='center'>
        You have been invited by your team member.
      </Typography>
        <FinishRegistration user={{
          email: email as string,
          key: key as string,
          firstName: firstName,
          lastName: lastName,
        }} />

    </LayoutAuthentication>
  );
}
