import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';
import reactCSS from 'reactcss'
import './PickerPreselected.css'

interface IPickerPreselectedProps {
  onUpdateHandler: (color: string) => void;
  color?: string;
}

class PickerPreselected extends React.Component<IPickerPreselectedProps> {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };
  // @ts-ignore
  handleChange = (color) => {
    const {onUpdateHandler} = this.props;
    onUpdateHandler(color.hex);
  };

  render() {
    const {color} = this.props;
    
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${color}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        {/* @ts-ignore */}
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          {/* @ts-ignore */}
          <div style={ styles.cover } onClick={ this.handleClose }/>
          {/* @ts-ignore */}
          {/* <SketchPicker color={ color } onChange={ this.handleChange } /> */}
          {/* @ts-ignore */}
          <GithubPicker color={ color }
            className='preselectedpicker'
            onChange={ this.handleChange }
            colors={[ '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3',
            '#8bc34a', '#ABB8C3', '#333333', '#EB144C', '#F78DA7', '#9900EF']}
            />
        </div> : null }

      </div>
    )
  }
}

export default PickerPreselected