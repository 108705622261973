import React from 'react';
import {styled} from '@mui/material/styles';
import { rgba } from 'polished';

import {
  Box,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { ROUTES } from 'hrFrontend/state/routes';

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled('div')`
  padding-top: 0rem;
  position: relative;
  text-align: center;
  height: 100%;
`;

const Content = styled('div')`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const ImageWrapper = styled('div')`
  &:before {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
    bottom: 0;
    left: 0;
    position: absolute;
    content: ' ';
    z-index: 1;
    display: block;
    width: 100%;
    height: 75px;
    pointer-events: none;
  }
`;

const Image = styled('img')`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 6px 18px 0 rgba(18, 38, 63, 0.075);
  border-radius: 5px;
  transform: perspective(2000px) rotateX(25deg);
  z-index: 0;
  position: relative;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
  margin-top: -35px;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: -50px;
  }
`;


const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

const BrandIcon = styled('img')`
  vertical-align: middle;
  margin-right: ${(props) => props.theme.spacing(3)};
  height: auto;
`;

const Visibility = styled(VisibilityIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Version = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  background: ${(props) => rgba(props.theme.palette.primary.main, 0.1)};
  box-shadow: 0 1px 1px
    ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
  padding: 3px 8px;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing(3)};
  display: inline-block;
`;

export const LandingReel = () => {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems='center' justifyContent='center' spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              {/* <Version variant='body2'>beta</Version> */}
              <Title variant='h1' gutterBottom>
                Making <span>Your HR System</span> work{' '}
                {/* Where <span>Agile</span> meets <span>HR</span>{' '} */}
                {/* Let us help you make <span>Your HR</span> system work{' '} */}
                {/* Let us help you make <span>Your HR</span> system work{' '} */}
              </Title>
              <Grid container justifyContent='center' spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color='textSecondary'>
                  QuantiVision is an agile HR System designed from the ground for maximum flexibility, in order to satisfy the needs of progressive companies.
                  </Subtitle>
                </Grid>
              </Grid>
              <Box my={6}>
                <Button
                  href={ROUTES.REQUEST_ACCOUNT.path}
                  variant='contained'
                  color='secondary'
                  size='large'
                >
                  <Visibility />
                  Book a demo
                  <ArrowForward />
                </Button>
              </Box>
            </Content>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justifyContent='center' spacing={4}>
          <Grid item xs={12} sm={10} md={9} lg={9}>
            <ImageWrapper>
              {/* <NavLink to='/' target='_blank'> */}
                <Image
                  alt='QuantiVision - screen'
                  src={`/static/screen-large.png`}
                />
              {/* </NavLink> */}
            </ImageWrapper>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

