import React, {PropsWithChildren} from 'react';
import { Outlet } from 'react-router-dom';
import {styled} from '@mui/material/styles';

import { CssBaseline } from '@mui/material';

import {GlobalStylesWrapper} from 'sharedFrontend/layouts/GlobalStylesWrapper';

const Root = styled('div')`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const LayoutAuthenticationPublic = ({ children }: PropsWithChildren<any>) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStylesWrapper />
      {children}
      <Outlet />
      {/*<Settings />*/}
    </Root>
  );
};
