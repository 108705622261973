import { TEvaluationTypes, TFeatures } from './types';

interface IConsts {
  COOKIE: {
    USER_TOKEN: string;
    USER_SETTINGS: string;
  }
  FEATURES: TFeatures,
  EVALUATION_TYPES: TEvaluationTypes
}

export const CONSTS: IConsts = {
  COOKIE: {
    USER_TOKEN: 'qv-hr-token',
    USER_SETTINGS: 'qv-hr-user_settings'
  },
  FEATURES: ['recruitment', 'ats', 'growth'],
  EVALUATION_TYPES: ['scaled', 'mustHave', 'composed']
}
