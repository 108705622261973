import React, {PropsWithChildren, useState, FunctionComponent, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import {Box, CssBaseline, Divider, Grid, Paper as MuiPaper} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import {Briefcase, CheckSquare, Sliders, Users, Columns, User, Settings} from 'react-feather';

// import {ROUTES} from '../../state/routes';
import Sidebar from './sidebar/Sidebar';
import {GlobalStylesWrapper} from 'sharedFrontend/layouts/GlobalStylesWrapper';
import Topbar from './topbar/Topbar';


export interface IMenuItem<GFeature = string> {
  href: string;
  title: string;
  feature?: GFeature;
  icon?: JSX.Element | FunctionComponent;
  badge?: string;
  childs?: IMenuItem[];
}

export interface IMenuSection<GFeature = string> {
  title: string;
  items: IMenuItem<GFeature>[]
}


const Root = styled('div')`
  display: flex;
  min-height: 100vh;
`;

interface IDrawer {
  width: number
}

const Drawer = styled('div')<IDrawer>`
  ${({theme}) => theme.breakpoints.up('md')} {
    width: ${(props) => props.width}px;
    flex-shrink: 0;
  }
`;

// interface IAppContent {
//   width: number;
// }

// const AppContent = styled('div')<IAppContent>`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   width: 100%;

//   ${(props) => props.theme.breakpoints.up('md')} {
//     width: calc(100% - ${(props) => props.width}px);
//   }
// `;


const AppContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  // .MuiPaper-root .MuiPaper-root {
  //   box-shadow: none;
  // }
`;

const SIDEBAR_WIDTH_FULLS = {
  NORMAL: 258,
  COLLAPSED: 46
}


interface ILayoutAuthDashboardProps {
  menuSections: IMenuSection[];
  mainContentHorizontalP: number;
  mainContentVerticalP?: number;
  children: any;
  footer: (props: any) => JSX.Element;
  logout: () => Promise<void>;
  collapseSidebarOnInit?: () => boolean;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (val: boolean) => void;
}
// : PropsWithChildren<any>
export const LayoutAuthenticatedDashboard = (props: ILayoutAuthDashboardProps) => {
  const {children, mainContentHorizontalP, mainContentVerticalP, menuSections, footer, logout, collapseSidebarOnInit, sidebarCollapsed, setSidebarCollapsed} = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (collapseSidebarOnInit && collapseSidebarOnInit()) {
      setSidebarCollapsed(true)
    }
  }, [])

  const theme = useTheme();
  const isLGUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMDUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMDDown = useMediaQuery(theme.breakpoints.down('md'));
  
  const sidebarWidth = isMDDown ? SIDEBAR_WIDTH_FULLS.NORMAL :
    (sidebarCollapsed ?  SIDEBAR_WIDTH_FULLS.COLLAPSED : SIDEBAR_WIDTH_FULLS.NORMAL);

  return (
    <Root>
      <CssBaseline />
      <GlobalStylesWrapper />
      <Drawer width={sidebarWidth}>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: sidebarWidth } }}
            menuSections={menuSections}
            open={mobileOpen}
            toggleSidebar={toggleSidebar}
            isMDDown={isMDDown}
            sidebarCollapsed={sidebarCollapsed}
            onClose={handleDrawerToggle}
            SidebarFooter={footer}
            variant='temporary'
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: sidebarWidth } }}
            menuSections={menuSections}
            SidebarFooter={footer}
            toggleSidebar={toggleSidebar}
            isMDDown={isMDDown}
            sidebarCollapsed={sidebarCollapsed}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Topbar onDrawerToggle={handleDrawerToggle} logout={logout} sx={{ display: { xs: 'block', lg: 'none' } }} />
        <MainContent p={isLGUp ? 12 : 5} style={isLGUp ? {padding: `${mainContentVerticalP || '30' }px ${mainContentHorizontalP}px`} : {}}>
          {children}
          <Outlet />
        </MainContent>
        {/*<Footer />*/}
      </AppContent>
      {/*<Settings />*/}
    </Root>
  );
};

