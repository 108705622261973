import React from "react";
import {useTheme} from "@mui/material/styles";

export const GlobalStylesWrapper = (props: any) => {
  const theme = useTheme();

  return (
    <>
      <style>
        {`
          html,
          body,
          #root {
            height: 100%;
            overflow-x: hidden;
          }
          body {
            background: ${theme.palette.background.default};
            margin: 0;
          }
          .MuiCardHeader-action .MuiIconButton-root {
            padding: 4px;
            width: 28px;
            height: 28px;
          }
        `}
      </style>
    </>
  );
}
