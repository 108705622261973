import React, {useEffect, useState} from 'react'

import {Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from "@mui/material";

import {useNavigate, useParams} from "react-router";

import { LayoutAuthenticated } from './LayoutAuthenticated';
import { EvaluationBoard } from 'hrFrontend/components/evaluation/EvaluationBoard';


export function VacancyEvaluationPage() {
  const {id} = useParams<{id: string}>();

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {id && <EvaluationBoard vacancyId={id} />}
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
