import React from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Input, MenuItem,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';

import {ICandidate, IProfile, IEvaluatedCriteria, IVacancy, ICriteria, getDefaultEvaluatedCriteria, mutatePatchEvaluatedCriteria, ITenantSettings} from '../../state/state';

import {MoreHorizontal, Trash2} from 'react-feather';
import {ContentCopy, Delete, FileCopy} from '@mui/icons-material';
import {PopupMenu} from 'sharedFrontend/navigation/PopupMenu';
import { ModelTextField, SliderAdjacentTextField, weightScoreFormatter } from './EvaluationProfile';
import { sliderValueTextFn } from '../utils/utils-decision';

export const color18 = [
  '#36BFDF', // blue
  '#E96B54', // red / pink
  '#EF9D21', // orange
  '#A96CC2', // violet
  '#F1C40F', // yellow
  '#27AE60', // green

  '#4C65E1', // 2 blue
  '#E679CD', // 2 pink
  '#7F34BE', // 2 violet
  '#1ABC9C', // 2 green
  '#F39C12', // 2 yellow
  '#D13086', // 2 red

  '#885FE3', // violet
  '#F18221', // orange
  '#3498DB', // blue
  '#EDEF1B', // yellow
  '#2ECC71', // green
  '#E5503F', // red
]

export const SliderHollow = styled(Slider)({
  width: '96%',
  display: 'block',
  margin: '0 auto',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  }
});


interface IEvaluationCandidateProps {
  candidate: ICandidate;
  profile: IProfile;
  vacancy: IVacancy;
  userId: string;
  setCandidateEvaluation: (evaluatedCriteria: IEvaluatedCriteria, frontentOnly?: boolean) => void;
  settings: ITenantSettings;
  color?: string;
}

export const EvaluationCandidate = (props: IEvaluationCandidateProps) => {
  const {candidate, profile, vacancy, setCandidateEvaluation, userId, color, settings} = props;
  const {criteria} = profile;
  const {mustHaveCriteria, scaledCriteria} = criteria;

  let evaluatedCriteria: IEvaluatedCriteria
  if (vacancy.evaluationsMap[userId] && vacancy.evaluationsMap[userId][candidate.id]) {
    // TODO: fe-patch
    evaluatedCriteria = vacancy.evaluationsMap[userId][candidate.id]
    mutatePatchEvaluatedCriteria(evaluatedCriteria, profile)
  } else {
    evaluatedCriteria = getDefaultEvaluatedCriteria(profile);
  }
  const evaluatedScaledCriteria = evaluatedCriteria.scaledCriteria

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sliderStyles: React.CSSProperties = {}
  if (color) {
    sliderStyles.color = color;
  }

  return (
    <React.Fragment>
      <Grid container spacing={0} style={{marginBottom: '2px'}}>
        <Grid item md={11} xs={11}>
          <Typography variant="h6">{candidate.firstName} {candidate.lastName}</Typography>
        </Grid>
        <Grid item md={1} xs={1}>
          <IconButton
            aria-label='more'
            style={{ marginTop: '0px' /* remove when placeholder */}}
            id='more-button'
            aria-controls={open ? 'more-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
          >
            <MoreHorizontal />
          </IconButton>
          <PopupMenu
            id='demo-customized-menu'
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={() => {
              setDuplicateOption(candidate)
              handleClose()
            }}>
              <ContentCopy />
              Duplicate
            </MenuItem>
            <Divider sx={{ my: 0.5 }} /> */}
            <MenuItem onClick={() => {
              handleClose()
            }}>
              <Delete />
              {/* TODO */}
              Show/Hide Descriptions
            </MenuItem>
          </PopupMenu>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {scaledCriteria.map(({id, name, evaluationGuideMap}, index) => {
          const currentCriterion = evaluatedScaledCriteria[id]
          const currentValue = currentCriterion.current.value
          const evalKey = currentValue % 2 === 1 ? currentValue : currentValue -1
          
          return (
            <React.Fragment key={id}>
              {index === 0 ? null : (<Grid sx={{m:0, mb: 3}} item xs={12}><Divider /></Grid>)}
              <Grid item md={10} xs={10}>
              <Typography id="input-slider" gutterBottom variant="subtitle2" mb={1} ml={1} style={{fontSize: '1.05em'}}>
                  {name ? name : `Criterion ${index + 1}`}
                </Typography>
                <SliderHollow
                  min={settings.scaleMin}
                  max={settings.scaleMax}
                  step={settings.scaleStep}
                  defaultValue={settings.scaleMin}
                  color={'secondary'}
                  // size='small'
                  aria-labelledby='input-slider'
                  valueLabelDisplay='auto'
                  // marks={getSliderPerformanceLabel(name)}
                  style={sliderStyles}
                  getAriaValueText={sliderValueTextFn}
                  value={currentValue}
                  onChange={(e, newValue) => {
                    const numberInput = Number(newValue)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }
                    evaluatedScaledCriteria[id].history.push(evaluatedScaledCriteria[id].current)
                    evaluatedScaledCriteria[id].current = {
                      value: numberInput,
                      updatedAt: new Date()
                    }
                    setCandidateEvaluation(evaluatedCriteria)
                  }}
                  onChangeCommitted={(e, newValue) => {
                    const numberInput = Number(newValue)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }
                    evaluatedScaledCriteria[id].history.push(evaluatedScaledCriteria[id].current)
                    evaluatedScaledCriteria[id].current = {
                      value: numberInput,
                      updatedAt: new Date()
                    }
                    setCandidateEvaluation(evaluatedCriteria, false)
                  }}
                />
              </Grid>
              <Grid item md={2} xs={2} sx={{pb: 1, pt: 0, pl: 2, pr: 2}}>
                <SliderAdjacentTextField
                  fullWidth
                  variant='standard'
                  value={weightScoreFormatter(currentValue)}
                  size='small'
                  onChange={(event) => {
                    const numberInput = Number(event.target.value)
                    if (Number.isNaN(numberInput)) {
                      return false;
                    }
                    evaluatedScaledCriteria[id].history.push(evaluatedScaledCriteria[id].current)
                    evaluatedScaledCriteria[id].current = {
                      value: numberInput,
                      updatedAt: new Date()
                    }
                    setCandidateEvaluation(evaluatedCriteria)
                  }}
                  onBlur={() => {
                    const currentCriteria = evaluatedScaledCriteria[id]

                    evaluatedScaledCriteria[id].history.push(evaluatedScaledCriteria[id].current)
                    if (currentValue < settings.scaleMin) {
                      evaluatedScaledCriteria[id].current = {
                        value: settings.scaleMin,
                        updatedAt: new Date()
                      }
                    } else if (currentValue > settings.scaleMax) {
                      evaluatedScaledCriteria[id].current = {
                        value: settings.scaleMax,
                        updatedAt: new Date()
                      }
                    }
                    
                    setCandidateEvaluation(evaluatedCriteria, false)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{m:0, mb: 2, ml: 1}}>
                  Guide: {
                    // @ts-ignore
                    evaluationGuideMap && evaluationGuideMap[evalKey] ? evaluationGuideMap[evalKey] : '-'
                    }
                </Box>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </React.Fragment>
  )
}
