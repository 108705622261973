import React, {useEffect, useState} from 'react'

import {Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from "@mui/material";

import {useParams} from "react-router";
import { Vacancy } from 'hrFrontend/components/Vacancy';
import { LayoutAuthenticated } from './LayoutAuthenticated';


export function VacancyPage() {
  const {id} = useParams<{id: string}>();

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h3">{id ? 'Edit Vacancy' : 'New Vacancy'}</Typography>
          <Vacancy editedVacancyId={id} />
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
