import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import {LayoutAuthenticated} from "./LayoutAuthenticated";
import {
  Link as MuiLink,
  Grid,
  Typography
} from "@mui/material";
import {Action} from '../state/actions';
import { UserSettings } from './Settings/UserSettings';

export const SettingsPage = () => {
  const navigate = useNavigate();

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h3">User Settings</Typography>

          <UserSettings />
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
