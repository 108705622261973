import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {grey} from "@mui/material/colors";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // @ts-ignore
  if (b[orderBy].value < a[orderBy].value) {
    return -1;
  }
  // @ts-ignore
  if (b[orderBy].value > a[orderBy].value) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    // @ts-ignore
    return a[1].value - b[1].value;
  });
  return stabilizedThis.map((el) => el[0]);
}

interface TableSortableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  columns: ITableSortableColumn[];
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showSelectRows?: boolean;
}

function TableSortableHead(props: TableSortableHeadProps) {
  const { onSelectAllClick, columns, order, orderBy, numSelected, rowCount, onRequestSort, showSelectRows } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const renderNumSelected = numSelected ? numSelected : 0

  return (
    <TableHead>
      <TableRow>
        {showSelectRows ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={renderNumSelected > 0 && renderNumSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        ) : null}
        {columns.map((column) => {
          const {id, label, numeric, sortable, disablePadding, align} = column
          return (
            <TableCell
              key={id}
              align={align ? align : 'center'}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === id ? order : false}
              sx={{backgroundColor: grey[200], fontWeight: 'bold'}}
            >
              {sortable ? (
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'asc'}
                  onClick={createSortHandler(id)}
                >
                  {label}
                  {orderBy === id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{label}</>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};


type TCellAlign = 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;

export interface ITableSortableColumn {
  id: string;
  label: string | JSX.Element;
  sortable?: boolean;
  disablePadding?: boolean;
  numeric?: boolean;
  align?: TCellAlign;
}

interface ITableSortableCellData {
  value?: any;
  jsx?: JSX.Element;
  width?: string;
  onClick?: () => void;
  onMouseEnterLeave?: Function;
}

type RowData = {[key in string]: ITableSortableCellData}

interface ITableSortableProps {
  columns: ITableSortableColumn[];
  rowsData: RowData[];
  defaultOrderBy: string;
  showToolbar?: boolean;
  showSelectRows?: boolean;
  emptyTableRow?: JSX.Element;
  minWidth?: string | number;
}

interface ICellProps {
  width?: string;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  onClick?: Function;
  style?: any;
}

export const TableSortable = (props: ITableSortableProps) => {
  const {columns, rowsData, showToolbar, defaultOrderBy, showSelectRows, emptyTableRow, minWidth} = props
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<any>(defaultOrderBy);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  // ------------ selected functionality --------------
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowsData.map((n) => n.id.value);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <div>
      <Paper>
        {/* <div sx={{ width: '100%' }}>*/}
        {/*   <Paper sx={{ width: '100%', mb: 2 }}>*/}
        {showToolbar ? (<EnhancedTableToolbar numSelected={selected.length} />) : null}
        <TableContainer>
          <Table
            sx={{ minWidth: minWidth ? minWidth : 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableSortableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              columns={columns}
              rowCount={rowsData.length}
              showSelectRows={showSelectRows}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rowsData.slice().sort(getComparator(order, orderBy)) */}
              {rowsData.length === 0 ? emptyTableRow ? emptyTableRow : <></> : null}
              {/*@ts-ignore*/}
              {stableSort<RowData>(rowsData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id.value);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        tabIndex={-1}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        {showSelectRows ? (
                          <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id.value)}>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                        ) : null}
                        {columns.map((c) => {
                          const {align} = c;
                          const currentCell = row[c.id] as ITableSortableCellData;
                          const ToRender = currentCell.jsx ? currentCell.jsx : currentCell.value
                          const cellProps: ICellProps = {}
                          if (currentCell.width) {
                            cellProps['width'] = currentCell.width
                          }
                          if (currentCell.onMouseEnterLeave) {
                            cellProps['onMouseEnter'] = currentCell.onMouseEnterLeave
                            cellProps['onMouseLeave'] = currentCell.onMouseEnterLeave
                          }
                          if (currentCell.onClick) {
                            cellProps['onClick'] = currentCell.onClick
                            cellProps['style'] = { cursor: 'pointer'}
                          }
                          // @ts-ignore
                          return (<TableCell key={c.id}  align={align ? align : 'center'} {...cellProps} >{ToRender}</TableCell>)
                        })}
                      </TableRow>
                      {row.secondRow?.jsx ? (
                        <>
                          {row.secondRow.jsx}
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rowsData.length > 10 ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      {/*</Box>*/}
    </div>
  );
}
