import React, {useCallback, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import * as Yup from 'yup';

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { getDefaultEditedValues, getDefaultErrorValues, getFormChangeHandler, getFormHandleSubmitHandler, getFormValidateHandler } from './utils/form';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { ROUTES } from 'hrFrontend/state/routes';

const Alert = styled(MuiAlert)(spacing);


const TextField = styled(MuiTextField)(spacing);

export interface IRegisterValues {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}

interface IRegisterFormValues extends IRegisterValues {}

const defaultValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
}

const defaultErrorValues = getDefaultErrorValues(defaultValues)
const defaultEditedValues = getDefaultEditedValues(defaultValues)

export const RequestAccount = () => {
  const navigate = useNavigate();
  const {requestAccount} = useContext(GlobalContext)

  const schema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Business email is required'),
    phone: Yup.string().required('Contact phone is required')
  })
  const [values, setValues] = useState<IRegisterFormValues>({...defaultValues})
  const [errors, setErrors] = useState<any>({...defaultErrorValues})
  const [edited, setEdited] = useState<any>({...defaultEditedValues})

  const {firstName, lastName, email, phone} = values;

  const validateValues = useCallback(
    getFormValidateHandler(schema, setErrors, defaultErrorValues), [schema, setErrors])

  const handleSubmit = useCallback(
    getFormHandleSubmitHandler(values, validateValues, setEdited, async () => {
      const response = await requestAccount(values)
      if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
        return navigate(ROUTES.VERIFY_ACCOUNT_EMAIL.path)
      }
      
      switch (response.status) {
        case 400:
          setErrors({...errors, submit: 'Wrong parameters.'})
          break;
        case 403:
          setErrors({...errors, submit: 'Please register your work email, public emails are not accepted.'})
          break;
        case 409:
          setErrors({...errors, submit: 'Email you entered is already registered.'})
          break;
        case 425:
          setErrors({...errors, submit: 'You have exceeded the limit for your email subaddress registrations, if you need an exception, please can contact us at info@quantivision.com.'})
          break;
        case 429:
          setErrors({...errors, submit: 'We are sorry but the quota for a new user registration in this month has been exceeded. Please contact us at info@quantivision.com and we will glady help you to register via email.'})
          break;
        case 500:
          setErrors({...errors, submit: 'Server error, please try again later.'})
          break;
        default:
          setErrors({...errors, submit: 'Something went wrong during the registration, please try again later.'})
      }
    }),
    [values, validateValues, setEdited]
  )

  const handleFirstNameChange = useCallback(
    getFormChangeHandler('firstName', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handleLastNameChange = useCallback(
    getFormChangeHandler('lastName', values, setValues, validateValues),
    [values, setValues, validateValues]
  )
  const handleEmailChange = useCallback(
    getFormChangeHandler('email', values, setValues, validateValues),
    [values, setValues, validateValues]
  )

  const handlePhoneChange = useCallback(
    getFormChangeHandler('phone', values, setValues, validateValues),
    [values, setValues, validateValues]
  )

  return (
    <form noValidate onSubmit={handleSubmit}>
        {/* <Alert mt={2} mb={3} severity="warning">
          The registration is closed in beta stage, please come back later.
        </Alert> */}
      {errors.submit && (
        <Alert mt={2} mb={3} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <TextField
        type='text'
        name='firstName'
        label='First name'
        value={firstName}
        error={Boolean(edited.firstName && errors.firstName)}
        fullWidth
        helperText={edited.firstName && errors.firstName}
        onBlur={() => {
          setEdited({...edited, firstName: true})
        }}
        onChange={handleFirstNameChange}
        style={{marginBottom: '8px', marginTop: '8px'}}
      />
      <TextField
        type='text'
        name='lastName'
        label='Last name'
        value={lastName}
        error={Boolean(edited.lastName && errors.lastName)}
        fullWidth
        helperText={edited.lastName && errors.lastName}
        onBlur={() => {
          setEdited({...edited, lastName: true})
        }}
        onChange={handleLastNameChange}
        style={{marginBottom: '8px'}}
      />
      <TextField
        type='email'
        name='email'
        label='Business email'
        value={email}
        error={Boolean(edited.email && errors.email)}
        fullWidth
        helperText={edited.email && errors.email}
        onBlur={() => {
          setEdited({...edited, email: true})
        }}
        onChange={handleEmailChange}
        style={{marginBottom: '8px'}}
      />
      <TextField
        type='phone'
        name='phone'
        label='Contact phone'
        value={phone}
        error={Boolean(edited.phone && errors.phone)}
        fullWidth
        helperText={edited.phone && errors.phone}
        onBlur={() => {
          setEdited({...edited, phone: true})
        }}
        onChange={handlePhoneChange}
        style={{marginBottom: '8px'}}
      />
      <Button type='submit' fullWidth variant='contained' color='primary'>
        Register
      </Button>
    </form>
  );
}
