import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import './App.css'
import Router from './Router';
// import {useTheme} from "./theme/useTheme";
import createTheme from "./theme";
import { GlobalContext } from './state/contexts/GlobalContext';


const App = () => {
  const {state, dispatch} = React.useContext(GlobalContext);

  return (
    <ThemeProvider theme={createTheme(state.theme)}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;

