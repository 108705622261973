import React, {useEffect, useState} from 'react'

import {Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from "@mui/material";

import {useParams} from "react-router";
import { LayoutAuthenticated } from './LayoutAuthenticated';
import { Profile } from 'hrFrontend/components/Profile';


export function ProfilePage() {
  const {id, departmentId} = useParams<{id: string, departmentId: string}>();  

  return (
    <LayoutAuthenticated>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 3 }} variant="h4">{id ? 'Edit Role' : 'New Role'}</Typography>
          <Profile
            departmentId={departmentId && departmentId !== 'null' ? departmentId : null}
            editedProfileId={id}
            />
        </Grid>
      </Grid>
    </LayoutAuthenticated>
  )
}
