import React, { useContext, useState } from 'react';
import {styled} from '@mui/material/styles';
import {
  Alert as MuiAlert,
  Avatar,
  Box,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { spacing } from '@mui/system';
import { getAccessToken, IUpdatableUserValues, GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { IUser } from 'hrFrontend/state/state';

const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)(spacing);

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

export const UserSettings = (props: any) => {
  const {state, updateUser, logout} = useContext(GlobalContext);

  const user = state.user as IUser;
  const {firstName, lastName, email, phone} = user;

  const handleChange = (event: any) => {
    const values = {firstName, lastName, phone}

    values[event.target.name as keyof IUpdatableUserValues] = event.target.value
    updateUser(values)
  };

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRetyped, setNewPasswordRetyped] = useState('')
  const [changePwdError, setChangePwdError] = useState<string | null>(null)
  const [passwordChanged, setPasswordChanged] = useState(false)


  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value)
  }
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }
  const handleNewPasswordRetypedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordRetyped(e.target.value)
  }


  const handleChangePassword = async () => {
    if (!oldPassword) {
      return setChangePwdError('Please type the old password')
    }

    if (newPassword !== newPasswordRetyped) {
      return setChangePwdError('Both passwords need to be the same')
    }

    if (!newPassword || newPassword.length < 8) {
      return setChangePwdError('New password must be at least 8 characters')
    }

    setChangePwdError(null)

    const pwdJson = JSON.stringify({oldPassword, newPassword})
    const response = await fetch(`${HR_PROD_BASE_URL}/api/v1/users/change-password`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: pwdJson
    })

    if (!getAccessToken()) {
      // @ts-ignore
      window.location = '/'
    }

    if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
      setOldPassword('')
      setNewPassword('')
      setNewPasswordRetyped('')
      setPasswordChanged(true)
      setTimeout(() => {
        setPasswordChanged(false)
      }, 5000)
      return
    }

    switch (response.status) {
      case 400:
        setChangePwdError('Wrong parameters.')
        break;
      case 403:
        setChangePwdError('Wrong Old Password.')
        break;
      case 500:
        setChangePwdError('Server error, please try again later.')
        break;
      default:
        setChangePwdError('Something went wrong during the password change, please try again later.')
    }
  }


  const [deleteUserPassword, setDeleteUserPassword] = useState('')
  const [deleteUserError, setDeleteUserError] = useState<string | null>(null)
  const [userDeleted, setUserDeleted] = useState(false)

  const handleDeleteUserPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteUserPassword(e.target.value)
  }

  const handleDeleteUser = async () => {
    if (!deleteUserPassword) {
      return setDeleteUserError('Please type your password')
    }

    if (deleteUserPassword.length < 8) {
      return setDeleteUserError('Password has to be at least 8 characters long.')
    }

    setDeleteUserError(null)

    if (confirm('Are you sure you want to delete your account?')) {
      const pwdJson = JSON.stringify({password: deleteUserPassword})
      const response = await fetch(`${HR_PROD_BASE_URL}/api/v1/users/delete-user`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: pwdJson
      })

      if (`${response.status}`[0] === '2' || `${response.status}`[0] === '3') {
        setDeleteUserPassword('')
        setUserDeleted(true)
        setTimeout(() => {
          logout();
        }, 3000)
        return
      }

      switch (response.status) {
        case 400:
          setDeleteUserError('Wrong parameters.')
          break;
        case 403:
          setDeleteUserError('Wrong password.')
          break;
        case 500:
          setDeleteUserError('Server error, please try again later.')
          break;
        default:
          setDeleteUserError('Something went wrong during the deletion of the account, please try again later.')
      }
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <Card {...props}>
          <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Avatar
                // src={avatar}
                sx={{
                  height: 64,
                  mb: 2,
                  width: 64
                }}
              />
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                {firstName} {lastName}
              </Typography>
              {/* <Typography
                color="textSecondary"
                variant="body2"
              >
                {`${city} ${country}`}
              </Typography> */}
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {Intl.DateTimeFormat().resolvedOptions().timeZone}
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              fullWidth
              variant="text"
              disabled={true}
            >
              Upload picture
            </Button>
          </CardActions>
        </Card>
      </Grid>
      
      <Grid item lg={8} md={6} xs={12}>
        <Card>
          <CardHeader
            title="User Details"
            // subheader="The information can be edited"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  value={firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  required
                  value={lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  disabled
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={phone ? phone : ''}
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  onChange={handleChange}
                  required
                  value={country}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select State"
                  name="state"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={state}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
        <Card mt={3}>
          <CardHeader
            title="Password Change"
          />
          <CardContent>
            <Grid container spacing={3}>
            {changePwdError && (
              <Grid item md={12} xs={12}>
                <Alert mt={2} mb={3} severity="warning">
                  {changePwdError}
                </Alert>
              </Grid>
            )}
            {passwordChanged && (
              <Grid item md={12} xs={12}>
                <Alert mt={2} mb={3} severity="info">
                  Password Changed
                </Alert>
              </Grid>
            )}
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Old Password"
                  name="oldPassword"
                  onChange={handleOldPasswordChange}
                  required
                  type={'password'}
                  value={oldPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  onChange={handleNewPasswordChange}
                  required
                  type={'password'}
                  value={newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Repeat New Password"
                  name="newPasswordRetyped"
                  onChange={handleNewPasswordRetypedChange}
                  required
                  type={'password'}
                  value={newPasswordRetyped}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            </CardContent>
            <Divider />
            <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
              <Button onClick={handleChangePassword} color="secondary" variant="contained">Change</Button>
            </Box>
        </Card>
        <Card mt={3}>
          <CardHeader
            title="Delete Account"
          />
          <CardContent>
            <Grid container spacing={3}>
            {deleteUserError && (
              <Grid item md={12} xs={12}>
                <Alert mt={2} mb={3} severity="warning">
                  {deleteUserError}
                </Alert>
              </Grid>
            )}
            {userDeleted && (
              <Grid item md={12} xs={12}>
                <Alert mt={2} mb={3} severity="info">
                  Acount has beed deleted, we are loggin you out.
                </Alert>
              </Grid>
            )}
              <Grid item md={6} xs={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="deleteUserPwd"
                  onChange={handleDeleteUserPasswordChange}
                  required
                  type={'password'}
                  value={deleteUserPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                  <Button onClick={handleDeleteUser} color="error" variant="contained">Delete</Button>
                </Box>
              </Grid>
            </Grid>
            </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
