
import React, {Component, Dispatch, useEffect} from 'react';
import {styled} from "@mui/material/styles";
import { DragDropContext } from 'react-beautiful-dnd';
import type { DragStart, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import _ from 'lodash'
import {Download, Upload, PlusCircle} from "react-feather"

import {Column} from './Column';
import {IBoardState, ITaskMap, IGenericTask, IVacancy, IUser, IProfile, ICriteria, IEvaluatedCriteria} from '../../state/state';
import {green, orange} from "@mui/material/colors";
import {
  Box,
  Button,
  Grid,
  Link,
  Container,
  Drawer as MuiDrawer,
  InputLabel,
  Select,
  SelectChangeEvent, MenuItem, FormControl
} from "@mui/material";
import Modal from "@mui/material/Modal";
import {Breadcrumbs, Typography} from "./EvaluationBoard.sc";
import {NavLink} from "react-router-dom";
// import {TaskDetail} from "./TaskDetail";
// import './TaskDetail.css'
import {ITaskDomain} from "../../state/state";
import {Action, IAction} from "../../state/actions";
import {ROUTES} from "../../state/routes";
import { MAIN_CONTENT_HORIZONTAL_PADDING, SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_FULL } from '../../theme/sizes';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { EvaluationCandidate } from './EvaluationCandidate';
import { EvaluationProfile } from './EvaluationProfile';
import { fetchPromise } from 'hrFrontend/utils/fetch';


const ColumnContainer = styled(Grid)<{sidebarCollapsed: boolean}>`
  display: flex;
  user-select: none;
  flex-wrap: nowrap;
  overflow: scroll;
  column-gap: 12px;
  margin-left: 0;
  margin-top: 12px;
  width: calc(100% + 20px);
  padding-right: 20px;
  
  ${({theme}) => theme.breakpoints.up("md")} { {
    width: calc(100vw - ${({sidebarCollapsed}) => sidebarCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH_FULL}px - 20px);
    padding-right: 24px;
  }

  ${({theme, sidebarCollapsed}) => theme.breakpoints.up("lg")} {
    width: calc(100vw - ${({sidebarCollapsed}) => (sidebarCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH_FULL) + MAIN_CONTENT_HORIZONTAL_PADDING}px);
    padding-right: ${MAIN_CONTENT_HORIZONTAL_PADDING}px;
  }
`;


const TaskBoardModal = styled(Modal)`
  modal:{
  "&:focus":{
    outline: none;
    border: none;
   }
 }
`;

export type upsertTaskCallback = (newTask: IGenericTask, taskStateId: string, editedTask?: boolean) => void
export type deleteTaskCallback = (taskId: string, taskStateId: string) => void

interface ITaskBoardProps {
  vacancyId: string;
  // boardState: IBoardState;
  // domains: ITaskDomain[];
  // updateBoardCallback: (payload: IBoardState) => void;
  // currentTaskBoardId: string;
  // onBoardChange: (boardId: string) => void;
  // boards: IBoardState[];
  // domainKey?: string;
  // domainStyle?: string;
  // addTaskLabel?: string;
}


export const EvaluationBoard = (props: ITaskBoardProps) => {
  const {vacancyId} = props;
  const {state, dispatch, hasRole} = React.useContext(GlobalContext);
  const {candidates, vacancies, profiles, profilesMap, user, sidebarCollapsed, settings} = state;
  const userId = (user as IUser).id

  useEffect(() => {
    // fetchPromise
    fetch(`/api/v1/recruitment/vacancies/${vacancyId}`, {
      method: 'GET',
    }).then(async (response) => {
      const { vacancy } = await response.json();
      dispatch({
        type: Action.UPSERT_VACANCY,
        payload: vacancy
      })

      // TODO: later candidates just for the vacancy
      fetch(`/api/v1/recruitment/candidates`, {
        method: 'GET',
      }).then(async (response) => {
        const { candidates } = await response.json();
        dispatch({
          type: Action.UPSERT_CANDIDATES,
          payload: candidates
        })

        // candidate evaluations
        fetch(`/api/v1/recruitment/candidates/evaluations/vacancy/${vacancy.id}`, {
          method: 'GET',
        }).then(async (response) => {
          const { candidateEvaluations } = await response.json();
          dispatch({
            type: Action.UPSERT_CANDIDATE_EVALUATIONS,
            payload: {
              vacancyId,
              candidateEvaluations,
            }
          })
        }).catch((err) => {console.error('Error fetching candidates in vacancy', err)
      })
      }).catch((err) => {console.error('Error fetching candidates in vacancy', err)})
  
      fetch(`/api/v1/org/profiles/${vacancy.profileId}`, {
        method: 'GET',
      }).then(async (response) => {
        const { profile } = await response.json();
        dispatch({
          type: Action.UPSERT_PROFILE,
          payload: {...profile}
        })
      }).catch((err) => {console.error('Error fetching porfiles in vacancy', err)})
    }).catch((err) => {console.error('Error fetching vacancy', err)})
  }, [])


  const currentVacancy = vacancies.find((v) => v.id === vacancyId);
  const currentCandidates = candidates.filter((a) => a.vacancyIds.indexOf(vacancyId) !== -1);
  const currentProfile = currentVacancy && currentVacancy.profileId ? profilesMap[currentVacancy.profileId] : undefined

  console.log('currentVacancy-currentVacancy-currentVacancy', currentVacancy);
  
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Grid justifyContent="space-between" container spacing={0}>
          <Grid item>
            <Typography sx={{ mb: 3 }} variant="h3">Evaluation: {currentVacancy?.name}</Typography>
          </Grid>
          <Grid item>
            <Box>
              <Button
                color="secondary"
                startIcon={(<Download fontSize="small" />)}
                sx={{ mr: 2 }}
                onClick={() => {
                }}
              >
                Export
              </Button>
              <Button
                color="secondary"
                startIcon={(<PlusCircle fontSize="small" />)}
                sx={{ mr: 2 }}
                onClick={() => {
                }}
              >
                Add Candidate
              </Button>
              {/* <Button color="secondary"
                      variant="contained"
                      onClick={(e) => {this.handleModalOpen()}}>
                {addTaskLabel ? addTaskLabel : 'Add Task'}
              </Button> */}
            </Box>
          </Grid>
        </Grid>

        {/* @ts-ignore */}
        <ColumnContainer container spacing={3} sidebarCollapsed={sidebarCollapsed}>
          {currentVacancy && currentProfile && (
            <>
            <Column
              name={currentProfile.name}
              columnsCount={currentCandidates.length + 1}
            >
              <EvaluationProfile
                profile={currentProfile}
                setProfile={(profile: IProfile) => {}}
                setModelAsTabs={(asTabs: boolean) => {}}
                enableMinimalPerformance={true}
                max={10}
              />
            </Column>
            {currentCandidates.map((candidate, index) => {
              return (
                <Column
                  // name={`${candidate.firstName} ${candidate.lastName}`}
                  columnsCount={currentCandidates.length + 1}
                >
                  <EvaluationCandidate
                    candidate={candidate}
                    vacancy={currentVacancy}
                    profile={currentProfile}
                    setCandidateEvaluation={(evaluatedCriteria: IEvaluatedCriteria, frontentOnly = true) => {
                      dispatch({
                        type: Action.UPSERT_CANDIDATE_EVALUATION,
                        payload: {
                          vacancyId,
                          userId,
                          candidateId: candidate.id,
                          evaluatedCriteria
                        }
                      })
                      if (!frontentOnly) {
                        fetchPromise(`/api/v1/recruitment/candidates/evaluations`, 'POST', {
                          candidateId: candidate.id,  vacancyId, evaluatedCriteria
                        }, 'Error during Profile creation')
                      }
                    }}
                    userId={userId}
                    settings={settings}
                    />
                </Column>
              )
            })}
            </>
          )
          }
        </ColumnContainer>
      </Grid>
    </Grid>
  );
}
