import {v4} from 'uuid';
import {green, orange, blue, red} from "@mui/material/colors";

import {Action} from "./actions";
import {THEMES} from "../theme/themes";
import { Mark } from '@mui/base';
import { IRole, TFeatures } from 'shared/types';

const InitDate = new Date('Wed Apr 27 2022 19:31:18 GMT+0200 (Central European Summer Time)');


export interface IBasicEntity {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface IEntity extends IBasicEntity {
  name: string;
}

export interface ITaskDomain extends IEntity {
  id: string;
  name: string;
  color?: string;
}

export interface IDepartment extends ITaskDomain {
  hiringManagerIds: string[];
}


// ------- RECRUITMENT ----------->
export enum EContractType {
  EMPLOYEE,
  CONTRACTOR
}

export enum EAllocationType {
  FULL_TIME,
  HALF_TIME,
  HOURLY_RATE
}

export enum EVacancyStatus {
  OPEN,
  CLOSED
}

export interface IVacancy extends ITaskDomain {
  status: EVacancyStatus;
  contractType: EContractType;
  allocationType: EAllocationType;
  // separate tables
  description: string | null;
  candidateIds: string[];
  departmentId: string | null;
  teamId: string | null;
  profileId: string | null;
  // settings: IDecisionSettings; // <- TODO | null
  // ------ migrate this to the settings ---------->
  // modelAsTabs: boolean | null;
  // optionsAsTabs: boolean | null;
  // <------ migrate this to the settings ----------
  // TODO
  // interviewMeetingIds: string[] | null;
  // FE TYPES
  rounds: IVacancyInterviewRound[],
  evaluationsMap: IEvaluationsMap,
  candidatesCount: number | null;
}

type IEValuatedCandidateMap = {
  [candidateId: string]: IEvaluatedCriteria
}
type IEvaluationsMap = {
  [userId: string]: IEValuatedCandidateMap
}

export const getDefaultEvaluatedCriteria = (profile: IProfile): IEvaluatedCriteria => {
  const now = new Date();
  const mustHaveCriteria: IEvaluatedMustHave = {}
  profile.criteria.mustHaveCriteria.forEach(({id}) => {
    mustHaveCriteria[id] = {
      current: {
        value: false,
        updatedAt: now
      },
      history: []
    }
  })

  const scaledCriteria: IEvaluatedScaled = {}
  profile.criteria.scaledCriteria.forEach(({id}) => {
    scaledCriteria[id] = {
      current: {
        value: 1,
        updatedAt: now
      },
      history: []
    }
  })

  return {
    mustHaveCriteria,
    scaledCriteria,
  }
}

export const mutatePatchEvaluatedCriteria = (evCriteria: IEvaluatedCriteria, profile: IProfile): IEvaluatedCriteria => {
  const now = new Date();
  profile.criteria.mustHaveCriteria.forEach(({id}) => {
    if (!evCriteria.mustHaveCriteria[id]) {
      evCriteria.mustHaveCriteria[id] = {
        current: {
          value: false,
          updatedAt: now
        },
        history: []
      }
    }
  })
  profile.criteria.scaledCriteria.forEach(({id}) => {
    if (!evCriteria.scaledCriteria[id]) {
      evCriteria.scaledCriteria[id] = {
        current: {
          value: 0,
          updatedAt: now
        },
        history: []
      }
    }
  })
  
  return evCriteria;
}

export interface IEvaluationBackend {
  userId: string;
  vacancyId: string;
  candidateId: string;
  evaluation: IEvaluatedCriteria;
}

export const getDefaultVacancy = (): IVacancy => {
  const dateNow = new Date();
  return {
    id: v4(),
    name: '',
    profileId: null,
    status: EVacancyStatus.OPEN,
    contractType: EContractType.EMPLOYEE,
    allocationType: EAllocationType.FULL_TIME,
    description: null,
    departmentId: null,
    teamId: null,
    candidateIds: [],
    createdAt: dateNow,
    updatedAt: dateNow,
    candidatesCount: null,
    evaluationsMap: {},
    rounds: [{
      id: v4(),
      name: 'First Round',
      assesorIds: [],
      index: 0,
      createdAt: dateNow,
      updatedAt: dateNow,
    }, {
      id: v4(),
      name: 'Second Round',
      assesorIds: [],
      index: 1,
      createdAt: dateNow,
      updatedAt: dateNow,
    }],
    // departmentId: user.view.currentDepartmentId,
  }
}

// __ Profile:Criteria:Evaluatio __
export interface ICriteria {
  mustHaveCriteria: IKoCriterion[];
  scaledCriteria: IScaledCriterion[];
}

export interface IHowToEvaluateGuide {
  score: string;
  description: string;
}

export interface IEvaluationGuideMap {
  [score: string]: string
}

export interface IScaledEvaluationGuideMap {
  '1': string;
  '3': string;
  '5': string;
  '7': string;
  '9': string;
}

export interface IMustHaveEvaluationGuideMap {
  '0': string;
  '1': string;
}

export interface ICriterionGeneric {
  id: string;
  name: string;
  type: CriterionTypeEnum;
  whyDescription?: string;
  whatDescription?: string;
}

export interface IScaledCriterion extends ICriterionGeneric {
  weight: number;
  minPerformance: number;
  evaluationGuideMap?: IScaledEvaluationGuideMap;
}

export interface IKoCriterion extends ICriterionGeneric {
  type: CriterionTypeEnum.BinaryPositive | CriterionTypeEnum.BinaryNegative;
  evaluationGuideMap?: IMustHaveEvaluationGuideMap;
}

interface IEvaluationValue<T> {
  value: T,
  updatedAt: Date
}
interface IEvaluationContent<T> {
  current: IEvaluationValue<T>;
  history: IEvaluationValue<T>[]
}
export interface IEvaluatedScaled {
  [id: string]: IEvaluationContent<number>
}

export interface IEvaluatedMustHave {
  [id: string]: IEvaluationContent<boolean>
}

type VacanciesMap = {
  [uid in string]: IVacancy
}

export interface IEvaluatedCriteria {
  mustHaveCriteria: IEvaluatedMustHave;
  scaledCriteria: IEvaluatedScaled;
}

export interface IProfile extends IEntity {
  criteria: ICriteria;
  departmentId?: string;
  parentId?: string;
  isTemplate?: boolean;
}

export interface IProfilesMap {
  [id: string]: IProfile;
}

export enum CriterionTypeEnum {
  Positive,
  Negative,
  BinaryPositive,
  BinaryNegative,
  // ?RangePositive,
  // ?RangeNegative
}

export const getDefaultKoCriterionEvaluationMap = () => {
  return {
    '0': '',
    '1': '',
  }
}

export const getDefaultScaledCriterionEvaluationMap = () => {
  return {
    '1': '',
    '3': '',
    '5': '',
    '7': '',
    '9': '',
  }
}

export  const getDefaultKoCriterion = (negative?: boolean): IKoCriterion => {
  return {
    id: v4(),
    name: '',
    type: negative ? CriterionTypeEnum.BinaryNegative : CriterionTypeEnum.BinaryPositive,
    whyDescription: '',
    evaluationGuideMap: getDefaultKoCriterionEvaluationMap()
  }
}

export  const getDefaultScaledCriterion = (negative?: boolean): IScaledCriterion => {
  return {
    id: v4(),
    name: '',
    type: negative ? CriterionTypeEnum.Negative : CriterionTypeEnum.Positive,
    weight: 1,
    minPerformance: 0,
    whyDescription: '',
    whatDescription: '',
    evaluationGuideMap: getDefaultScaledCriterionEvaluationMap()
  }
}

export const defaultCriterionPositive = {
  name: '',
  weight: 1,
  minPerformance: 0,
  type: CriterionTypeEnum.Positive,
  // TBD: evaluationGuideMap: {...defaultHowToEvaluateGuide}
}

export const defaultCriterionNegative = {
  name: '',
  weight: 1,
  minPerformance: 0,
  type: CriterionTypeEnum.Negative,
  // TBD: evaluationGuideMap: {...defaultHowToEvaluateGuide}
}

export const getDefaultProfile = (departmentId?: string): IProfile => {
  return {
    id: v4(),
    name: '',
    criteria: {
      mustHaveCriteria: [],
      scaledCriteria: [],
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    departmentId
  }
}


enum DecisionSettingsModelSortBy {
  WeightAscending,
  WeightDescending,
  AlphabeticallyAscending,
  AlphabeticallyDescending,
  Removed,
}

// 
// Inspiration:
// - squared
// - https://oeis.org/wiki/Welcome
enum DecisionScaleStepType {
  Linear,
  Fibonacci
}

let getScaleMarks: { [key in DecisionScaleStepType]?: ( ) => Mark[]} =
 { [DecisionScaleStepType.Fibonacci]: () => [] };


export enum DecisionScaleType {
  ZeroTen,
  ZeroHundred,
  Custom
}

export enum EvaluationWeightEnum {
  Base, // x E [0, 100]
  Squared   // x E [1,2]^2
}

type IScale = [number, number]
export interface IDecisionSettings {
  model: {
    enableMinimalPerformance: boolean;
    sortBy?: DecisionSettingsModelSortBy;
  };
  evaluation: {
    weightType: EvaluationWeightEnum;
  };
  visual: {
    isModelVisible: boolean;
    isOptionsVisible: boolean;
    isEvaluationVisible: boolean;
  };
  type: DecisionType;
  scaleType: DecisionScaleType;
  scale: IScale;
  scaleStep: number;
  // TBD: scaleStepType?: DecisionScaleStepType;
  optionsName?: string;
}

// indended to just positive integers, [0, x] interval
export const DecisionScales: IScale[] = [
  [0, 10],
  [0, 100],
  // TBD:
  // Custom: [],
]


export enum DecisionType {
  ChoosingOne,
  ChoosingProportion
}

// interface IDecisionGeneric extends IEntity {
//   model: IDecisionModel;
//   options: ICandidateEvaluation[];
//   settings: IDecisionSettings;
//   // ------ migrate this to the settings ---------->
//   modelAsTabs?: boolean;
//   optionsAsTabs?: boolean;
//   // <------ migrate this to the settings ----------
//   description?: string; // shall be extended into something like "PublicDescription" or "PublicAd"
// }

// interface IDecision extends IDecisionGeneric {
//   fromTemplateID?: string;
// }

// export enum IDecisionTemplateType {
//   SharedTemplate,
//   CustomTemplate
// }

// export interface IDecisionTemplate extends IDecisionGeneric {
//   templateType: IDecisionTemplateType;
// }

// // export interface IScaledCriterionMap {
// //   [id: string]: IScaledCriterion;
// // }

// interface IDecisionModel {
//   id: string;
//   criteria: IScaledCriterion[];
//   categoryId?: string; // departmentId
// }

// // export interface IHowToEvaluateGuide {
// //   '25': string;
// //   '50': string;
// //   '75': string;
// //   '100': string;
// // }
export interface ICandidate extends IBasicEntity {
  email: string;
  firstName: string;
  lastName: string;
  vacancyIds: string[];
  // rest
  phone?: string;
  linkedIn?: string;
  github?: string;
}

// <------- RECRUITMENT -----------

interface IUser extends IEntity {
  email: string;
  firstName: string;
  lastName: string;
  // rest
  phone?: string;
  country?: string;
  state?: string;
  subtitle?: string;
  avatar?: string;
}


// type SomeType = {
//   [uid in string]: ISomeInterface
// }

interface DevCommandHistoryItem {
  action: Action;
  command: string;
  description: string;
}


export interface IUserAdditionalSettingsState {
  lang: string;
  theme: string;
  sidebarCollapsed: boolean;
}

export const initialUserAdditionalSettingsState: IUserAdditionalSettingsState = {
  lang: 'en',
  theme: THEMES.DEFAULT,
  sidebarCollapsed: false
}

// ---------------------------------->


const templateDate = new Date(1676278337093);

interface ITemplateCriterion {
  name: string;
  // type?: CriterionTypeEnum;
}
interface ITemplateOption {
  name: string;
}

interface IGetDefaultDecisionProps {
  type?: DecisionType;
  id?: string;
  name?: string;
  date?: Date;
  criteria?: ITemplateCriterion[];
  options?: ITemplateOption[];
  fromTemplateID?: string;
  optionsName?: string;
}


export const getDefaultDepartment = (): IDepartment => {
  const date = new Date();
  return {
    id: v4(),
    name: '',
    createdAt: date,
    updatedAt: date,
    color: '#333333',
    hiringManagerIds: []
  }
}

export interface ITeam extends IEntity {
  departmentId: string;
}

export const getDefaultTeam = (departmentId: string): ITeam => {
  const date = new Date();
  return {
    id: v4(),
    name: '',
    departmentId,
    createdAt: date,
    updatedAt: date,
  }
}

export interface IGenericTask extends IEntity {
  domainId?: string;
  notifications?: number;
  avatars?: number[];
}
export interface ITaskMap {
  [id: string]: IGenericTask; // TODO: change this to ITask
}

export interface ITaskState {
  id: string
  name: string;
  taskIds: string[];
  description?: string;
}

export interface ITaskStateMap {
  [id: string]: ITaskState;
}

export interface IBoardState extends IEntity {
  columnOrder: string[];
  columns: ITaskStateMap;
  tasks: ITaskMap;
  departmentId?: string;
};

// @ts-ignore
export interface ICandidateBoardState extends IBoardState {
  tasks: ICandidateMap;
}

export interface ICandidateMap {
  [id: string]: ICandidate;
}

export interface IInterviewRound extends IEntity {
  index: number;
  description?: string;
  departmentId?: string;
}

export interface IVacancyInterviewRound extends IInterviewRound {
  assesorIds: string[];
}

export interface ITenantSettings {
  scaleMin: number;
  scaleMax: number;
  scaleStep: number;
  guideStep: number;
}

interface IGlobalState extends IUserAdditionalSettingsState {
  isLoggedIn: boolean;
  // dynamic always fetch
  user?: IUser;
  users: IUser[];
  // static from token
  tenantId: string;
  features: TFeatures;
  role: IRole;

  // Organization
  profiles: string[];
  profilesMap: IProfilesMap;
  departments: IDepartment[];
  // divisions?: []; // TODO
  teams: ITeam[];

  // Recruitment
  vacancies: IVacancy[];
  candidates: ICandidate[];
  // candidatesMap: ICandidatesMap;
  interviewRounds: IInterviewRound[];
  settings: ITenantSettings;
  // dev
  devActionHistory: DevCommandHistoryItem[];
}

const initialGlobalState: IGlobalState = {
  user: undefined,
  isLoggedIn: false,
  tenantId: '',
  role: {} as IRole,
  features: [],
  // org
  profiles: [],
  profilesMap: {},
  users: [],
  departments: [],
  teams: [],
  // recruitment
  vacancies: [],
  interviewRounds: [],
  candidates: [],
  // candidatesMap: {},
  // - debug
  devActionHistory: [],
  settings: {
    scaleMin: 1,
    scaleMax: 10,
    scaleStep: 1,
    guideStep: 2,
  },
  // - additional settings
  ...initialUserAdditionalSettingsState
}

export {initialGlobalState, IGlobalState, IUser}

