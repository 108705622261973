import React from 'react'
import {styled} from '@mui/material/styles';
import { Slider, TextField } from '@mui/material'; 

export const SliderAdjacentTextField = styled(TextField)`
  margin: 15px 10px 8px 10px;

  input {
    padding: 8px 9px;
  }
`;

export const SliderAdjacentTextFieldNegative = styled(TextField)`
  margin: 15px 10px 8px 10px;

  input {
    padding: 8px 7px;
  }
`;

export const SliderHollow = styled(Slider)({
  width: '96%',
  display: 'block',
  margin: '0 auto',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  }
});