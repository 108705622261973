export const ROUTES = {
  // public
  LANDING: {
    path: '/',
    desc: 'QuantiVision'
  },
  ABOUT: {
    path: '/about',
    desc: 'About QuantiVision'
  },
  CONTACT: {
    path: '/contact',
    desc: 'Contact'
  },
  REQUEST_ACCOUNT: {
    path: '/request-account',
    desc: 'Register Account'
  },
  VERIFY_ACCOUNT_EMAIL: {
    path: '/verify-account-email',
    desc: 'Verify your account email'
  },
  FINISH_REGISTRATION_EMAIL: {
    path: '/finish-registration',
    desc: 'Finish registration'
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    desc: 'Reset password'
  },
  LOGIN: {
    path: '/login',
    desc: 'Log In'
  },
  // authenticated
  // Generic
  // Organization
  ORGANIZATION: {
    path: '/organization',
    desc: 'Organization Dashboard'
  },
  STAFF: {
    path: '/organization/staff',
    desc: 'Staff'
  },
  DEPARTMENT_NEW: {
    path: '/organization/department/new',
    desc: 'New Department'
  },
  DEPARTMENT_EDIT: {
    path: '/organization/department/:id',
    getPath: (id: string) => `/organization/department/${id}`,
    desc: 'Edit Department'
  },
  ORGANIZATION_SETTINGS: {
    path: '/organization/settings',
    desc: 'Organization Settings'
  },
  // User
  SETTINGS: {
    path: '/user/settings',
    desc: 'User Settings'
  },
  // feature dependant
  // Recruitment
  RECRUITMENT_DASHBOARD: {
    path: '/recruitment/dashboard',
    desc: 'Recruitment Dashboard'
  },
  CANDIDATES: {
    path: '/recruitment/candidates',
    desc: 'Candidates'
  },
  VACANCIES: {
    path: '/recruitment/vacancies',
    desc: 'Vacancies'
  },
  VACANCY_NEW: {
    path: '/recruitment/vacancies/new',
    desc: 'New Vacancy'
  },
  VACANCY_EDIT: {
    path: '/recruitment/vacancies/:id',
    getPath: (id: string) => `/recruitment/vacancies/${id}`,
    desc: 'Edit Vacancy'
  },
  VACANCY_EVALUATION: {
    path: '/recruitment/vacancies/:id/evaluation',
    getPath: (id: string) => `/recruitment/vacancies/${id}/evaluation`,
    desc: 'Vacancy Evaluation'
  },
  VACANCY_RESULTS: {
    path: '/recruitment/vacancies/:id/results',
    getPath: (id: string) => `/recruitment/vacancies/${id}/results`,
    desc: 'Vacancy Results'
  },
  PROFILES: {
    path: '/recruitment/profiles',
    desc: 'Profiles'
  },
  PROFILE_NEW: {
    path: '/recruitment/profiles/:departmentId',
    getPath: (departmentId?: string) => `/recruitment/profiles/${departmentId ? `${departmentId}` : 'null'}`,
    desc: 'New Profile'
  },
  PROFILE_EDIT: {
    path: '/recruitment/profiles/:departmentId/:id',
    getPath: (id: string, departmentId?: string) => `/recruitment/profiles/${departmentId ? `${departmentId}` : 'null'}/${id}`,
    desc: 'Edit Profile'
  },
  // TODO: Growth
  // GROWTH: {
  //   path: '/growth',
  //   desc: 'Growth'
  // }
}
