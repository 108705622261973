import React from "react";
import { MessageCircle } from "react-feather";

import {
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import {styled} from "@mui/material/styles";

export const Card = styled(MuiCard)(spacing);

export const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;
  min-height: 99px;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

export const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

export const TaskAvatars = styled('div')`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

export const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

export const TaskBadge = styled('div')`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

export const TaskNotifications = styled('div')`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

export const TaskNotificationsAmount = styled('div')`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

export const Typography = styled(MuiTypography)(spacing);

export const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;
