
import React, { Component, PropsWithChildren } from 'react';

import memoizeOne from 'memoize-one';
import { Droppable } from 'react-beautiful-dnd';
import { grid, borderRadius } from './constants';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import type {ITaskState, IGenericTask, ITaskDomain, ICandidate, IProfile} from '../../state/state';
import {styled} from "@mui/material/styles";
import {Card, CardContent, Typography} from "./EvaluationBoard.sc";
import { EvaluationCandidate } from './EvaluationCandidate';

// $ExpectError - not sure why
const Container = styled(Card)`
  width: 300px;
  margin: ${grid}px;
  border-radius: ${borderRadius}px;
  border: 1px solid yellow;
  background-color: green;

  /* we want the column to take up its full height */
  display: flex;
  flex-direction: column;
`;

const Title = styled('h3')`
  font-weight: bold;
  padding: ${grid}px;
`;

interface ITaskListProps {
  isDraggingOver: boolean;
}

const TaskList = styled('div')<ITaskListProps>`
  padding: ${grid}px;
  min-height: 200px;
  flex-grow: 1;
  transition: background-color 0.2s ease;
  ${(props) =>
    props.isDraggingOver ? `background-color: yellowgreen` : ''};
`;

type TaskIdMap = {
  [taskId: string]: true,
};

const getSelectedMap = memoizeOne((selectedTaskIds: string[]) =>
  selectedTaskIds.reduce((previous: TaskIdMap, current: string): TaskIdMap => {
    previous[current] = true;
    return previous;
  }, {}),
);

interface ITaskStateProps extends PropsWithChildren<any> {
  columnsCount: number;
  name?: string;
};

export const Column = ({name, columnsCount, children}: ITaskStateProps) => {
  return (
    <Card mb={6} style={{display: 'flex', flexDirection: 'column', minWidth: '320px', maxWidth: "100%", width: `calc(100% / ${columnsCount})`}}>
      {/* @ts-ignore */}
      <CardContent pb={0} style={{height: '100%'}}>
        {name && <Typography variant='h6' gutterBottom>{name}</Typography>}
        {/* <Typography variant='body2' mb={4}>{description}</Typography> */}
        {children}
      </CardContent>
    </Card>
  );
}
