import "@mui/lab/themeAugmentation";

import { createTheme } from "@mui/material/styles";
import variants from "./variants";
import {typography} from "./typography";
import {components} from "./components";
import {shadows} from "./shadows";
import { Typography, Theme} from "@mui/material";
import {Shadows} from "@mui/material/styles/shadows";


// declare module '@mui/material/styles' {
//   interface Theme {
//     shadows: Shadows;
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//       shadows?: Shadows;
//   }
// }

const defaultTheme = createTheme()



const createCustomTheme = (name: string): Theme => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createTheme(
    {
      spacing: 4,
      breakpoints: {
        values: {
          xs: 0,
          sm: 700,
          md: 960,
          lg: 1280,
          xl: 1800,
        }
      },
      components,
      typography,
      shadows,
      // @ts-ignore
      palette: themeConfig.palette
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

export default createCustomTheme;
