import {styled} from "@mui/material/styles";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import {Typography} from "@mui/material";

export const SidebarMenuScrollbar = styled('div')`
  background-color: ${(props: any) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

export const SidebarMenuPerfectScrollbar = styled(ReactPerfectScrollbar)`
  background-color: ${(props: any) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

export const SidebarMenuItems = styled('div')`
  // padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

export const SidebarMenuSectionTitle = styled(Typography)`
  color: ${(props: any) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;
