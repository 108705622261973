import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Chip, Grid, TableCell, TableRow, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {TableSortable} from "sharedFrontend/organisms/TableSortable";

import {ROUTES} from "../state/routes";
import {DecisionType, IDepartment, IProfile, IProfilesMap, ITeam, IVacancy} from "../state/state";

import {v4} from "uuid";
import {ContentCopy, Edit as MuiEdit, Group} from "@mui/icons-material";
import {Action} from '../state/actions';
import {Download, Edit3, Edit, List, PlusCircle, Trash2} from 'react-feather';
import theme from '../theme';
import {styled} from '@mui/material/styles';
import { GlobalContext } from 'hrFrontend/state/contexts/GlobalContext';
import { TRole } from 'shared/types';
import { Team, TeamModal } from './Team';
import { Profile, ProfileModal } from './Profile';

interface IExpandState {
  show: boolean;
  force: boolean;
}

const DeleteButton = styled(Trash2)`
  color: ${(props) => props.theme.palette.error.main};
`

const DuplicateButton = styled(ContentCopy)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const EditButton = styled(Edit)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const ListButton = styled(List)`
  color: ${(props) => props.theme.palette.secondary.main};
`

interface IVacanciesTableSortable {
  vacancies: IVacancy[];
  deleteVacancy: (id: string) => void;
  duplicateDecision: (id: string) => void;
  departments: IDepartment[];
  teams: ITeam[];
  profilesMap: IProfilesMap;
  hasRole: (roleId: TRole) => boolean;
}


export const VacanciesTableSortable = (props: IVacanciesTableSortable) => {
  const {vacancies, departments, teams, profilesMap, deleteVacancy, duplicateDecision, hasRole} = props;
  const navigate = useNavigate();
  const isAdmin = hasRole('admin');


  // const [vacancyRows, setVacancyRows] = useState<any[]>(vacancies)

  // useEffect(() => {
  //   // fetch user vacancies data
  //   fetch(`/api/v1/recruitment/vacancies-table`, {
  //     method: 'GET',
  //   }).then(async (response) => {
  //     const { rows } = await response.json();
  //     console.log('response', response, rows);
  //     // @ts-ignore
  //     setVacancyRows([...rows].filter((v) => !!v.id))
      
  //   }).catch((err) => {
  //     console.error('Error fetching vacancies table', err)
  //   })
  // }, [])

  const [shownEdit, setShownEdit] = useState(vacancies.map(() => false))

  const columns = [
    {id: 'name', label: 'Name', sortable: true},
    {id: 'department', label: 'Department / Team'},
    {id: 'profile', label: 'Role', sortable: true},
    {id: 'candidates', label: 'Candidates', sortable: true},
    {id: 'evaluateAll', label: 'Evaluate All', sortable: false},
    {id: 'results', label: 'Results', sortable: false},
    {id: 'updatedAt', label: 'Updated', sortable: true},
    {id: 'edit', label: 'Edit'},
    // {id: 'expand', label: (<><ExpandMoreIcon /></>), sortable: true},
  ]

  const emptyTableRow = (
    <TableRow
      key={'emptyTableRow'}
      hover
      tabIndex={-1}
    >
      <TableCell align="center" colSpan={5}>
        Empty
      </TableCell>
    </TableRow>
  )

  const [modalCurrentTeamOpen, setModalCurrentTeamOpen] = useState<boolean>(false)
  const [editedTeamId, setEditedTeamId] = useState<string | undefined>()

  const [modalCurrentProfileOpen, setModalCurrentProfileOpen] = useState<boolean>(false)
  const [editedProfileId, setEditedProfileId] = useState<string | undefined>()
  
  // TODO: useMemo here does not work for some reason
  const rowsData = vacancies.map((vacancy, vacancyIndex) => {
  //   const {vacancyId,
  //   vacancyName,
  //   vacancyDepartmentId,
  //   vacancyTeamId,
  //   vacancyUpdatedAt,
  //   departmentId,
  //   profileId,
  //   profileName,
  //   candidatesCount
  // } = vacancy;
  const {id, name, departmentId, teamId, profileId, updatedAt, candidatesCount} = vacancy;
    // const {id, name, updatedAt, candidateIds, settings} = vacancy

    const profileName = profilesMap[`${profileId}`]?.name;
    const departmentName = departments.find((d) => d.id === departmentId)?.name;
    const teamName = teams.find((t) => t.id === teamId)?.name;
    const currentCandidateCount = candidatesCount ? Number(candidatesCount) : 0;

    const evalResDisabled = !(profileId && currentCandidateCount);
    console.log('evalResDisabled', vacancy, evalResDisabled, candidatesCount);
    

    // const profileName = 'aaa' // fromTemplateID ? profiles.find((t) => t.id === fromTemplateID)?.name : `Custom: ${settings.type === DecisionType.ChoosingOne ? 'Choose the best' : 'Choose proportions'}`
    return {
      id: {
        value: id
      },
      name: {
        value: name,
        width: '21%',
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            onClick={() => navigate(ROUTES.VACANCY_EDIT.getPath(id))}
            // style={{width: '150px'}}
            // onMouseEnter={() => {
            //   shownEdit[i] = !shownEdit[i]
            //   setShownEdit([...shownEdit])
            // }}
            // onMouseLeave={() => {
            //   shownEdit[i] = !shownEdit[i]
            //   setShownEdit([...shownEdit])
            // }}
          >
            <span>{name}</span>
            {/*<MuiLink component={Link} to={`${ROUTES.DECISION_OMNIVIEW.getPath(id)}`}>{name}</MuiLink>*/}
            {/*<EditIcon style={{verticalAlign: 'bottom', cursor: 'pointer', visibility: shownEdit[i] ? 'visible' : 'hidden'}} />*/}
          </Grid>
        )
      },
      department: {
        value: departmentId,
        width: '20%',
        jsx: (
          <Grid
            container
            spacing={0}
            justifyContent="center"
          >
            <span>
            {departmentName ? (
              <span
                style={{ cursor: 'pointer'}}
                onClick={() => {
                  if (departmentId) {
                    navigate(ROUTES.DEPARTMENT_EDIT.getPath(departmentId))
                  }
                }}
              >
                {departmentName}
              </span>
            ) : (
              '-'
            )} / {teamName ? (
              <span
                style={{ cursor: 'pointer'}}
                onClick={() => {
                  setEditedTeamId(teamId as string)
                  setModalCurrentTeamOpen(true)
                }}
              >
                {teamName}
              </span>
            ) : '-'}
            </span>
          </Grid>
        )
      },
      profile: {
        width: '15%',
        value: profileName,
        jsx: (
          <Grid
            container
            spacing={0}
            style={{ cursor: 'pointer'}}
            justifyContent="space-between"
          >
            {profileName && profileId ? (
              <Chip
                style={{cursor: 'pointer'}}
                key={profileId}
                label={profileName}
                onClick={() => {
                  navigate(ROUTES.PROFILE_EDIT.getPath(profileId))
                  // setEditedProfileId(profileId)
                  // setModalCurrentProfileOpen(true)
                }}
                variant={'outlined'}
                sx={{mr:1 ,mb: 1, mt: 1}}
                />
            ) : '-'}
          </Grid>
        )
      },
      candidates: {
        value: currentCandidateCount,
        width: isAdmin ? '5%' : '5%',
        jsx: (
          <Grid container spacing={0} style={{width: '100%'}} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{alignItems: 'center', display: 'flex'}}>
              <span>
                <strong>{currentCandidateCount}</strong> <Group fontSize={'small'} style={{marginLeft: '6px', verticalAlign: 'bottom'}} />
              </span>
            </Grid>
            {false && isAdmin && (<Grid item sx={{alignItems: 'right', display: 'flex'}} onClick={() => navigate('/'/*ROUTES.CANDIDATE_NEW.getPath(id)*/)} style={{ cursor: 'pointer'}}>
              <AddCircleOutlineIcon color="secondary" style={{verticalAlign: 'bottom'}} />
              <Typography color="textSecondary" display="inline" sx={{ pl: 1 }} variant="body2">
                candidate
              </Typography>
            </Grid>)}
          </Grid>
        )
      },
      evaluateAll: {
        value: id,
        width: '13%',
        jsx: (
          <Button size={"small"} color="secondary" disabled={!(profileId && currentCandidateCount)} variant="contained" onClick={() => navigate(ROUTES.VACANCY_EVALUATION.getPath(id))}>Evaluate All</Button>
        )
      },
      results: {
        value: id,
        width: '8%',
        jsx: (
          <Button size={"small"} color="success" disabled={true /*!(profileId && currentCandidateCount)*/} variant="contained" onClick={() => navigate(ROUTES.VACANCY_RESULTS.getPath(id))}>Results</Button>
        )
      },
      edit: {
        value: id,
        // width: '10%',
        jsx: (
          <EditButton
            style={{ cursor: 'pointer'}}
            onClick={() => {
              navigate(ROUTES.VACANCY_EDIT.getPath(id))
            }} />
        )
      },
      updatedAt: {
        width: '8%',
        value: updatedAt,
        jsx: (
          <span>
            {new Date(updatedAt).toLocaleDateString('en-US')}
          </span>
        )
      },
    }
  })
  // }, [
  //   vacancies,
  //   // dispatch,
  //   v4,
  //   shownEdit,
  //   setShownEdit
  // ])

  return (
    <>
      <TableSortable columns={columns} rowsData={rowsData} defaultOrderBy={'updatedAt'} emptyTableRow={emptyTableRow} minWidth={'auto'} />
      <TeamModal modalOpen={modalCurrentTeamOpen} setModalOpen={setModalCurrentTeamOpen}>
        <Team
          setModalOpen={setModalCurrentTeamOpen}
          editedTeamId={editedTeamId}
          />
      </TeamModal>

      <ProfileModal modalOpen={modalCurrentProfileOpen} setModalOpen={setModalCurrentProfileOpen}>
        <Profile
          editedProfileId={editedProfileId}
          setModalOpen={setModalCurrentProfileOpen}
          />
      </ProfileModal>
    </>
  )
}

